import React, { Component } from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { deleteUser, getUsers } from '../../../lib/client';
import { AboveTableDiv, dropdownStyles } from './styles';
import { Table } from '../styles';
import UserTableRow from './UserTableRow';

const sortOpts = [
  { value: 'A-Z', label: 'A-Z (email)' },
  { value: 'Z-A', label: 'Z-A (email)' },
  { value: 'Role', label: 'Role' },
];

class UserTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      headers: [
        'First Name',
        'Last Name',
        'Email',
        'Role',
        'Admin?',
        'Delete',
        'Update',
      ],
    };
  }

  componentWillMount() {
    this.fetchUsers();
  }

  handleDeleteUser = async (e, id) => {
    const deletedUserResponse = await deleteUser(id);
    console.log('DELETED USER', deletedUserResponse);
    const users = [...this.state.allUsers];
    const filteredUsers = users.filter((user) => user.id !== id);
    this.setState({
      allUsers: filteredUsers,
    });
  };

  handleTextFieldUpdate = (id, key, value) => {
    const updatedUser = _.map(this.state.allUsers, (user) => {
      if (user.id !== id) {
        return user;
      }

      return { ...user, [key]: value };
    });
    this.setState({
      allUsers: updatedUser,
    });
  };

  handleAdminSelectUpdate = (id, isAdmin) => {
    const updatedUser = _.map(this.state.allUsers, (user) => {
      if (user.id !== id) {
        return user;
      }

      return { ...user, is_admin: isAdmin.value };
    });
    this.setState({
      allUsers: updatedUser,
    });
  };

  /* eslint-disable object-shorthand */
  handleRoleSelectUpdate = (id, role) => {
    console.log('ID', id);
    console.log('ROLE', role);
    const updatedUser = _.map(this.state.allUsers, (user) => {
      if (user.id !== id) {
        return user;
      }

      return { ...user, role: role.value };
    });
    this.setState({
      allUsers: updatedUser,
    });
  };

  /* eslint-disable default-case */
  /* eslint-disable no-case-declarations */
  handleSort = (value) => {
    const { allUsers } = this.state;
    switch (value) {
      case 'A-Z':
        const aZ = allUsers.sort((user1, user2) =>
          user1.email > user2.email ? 1 : -1
        );
        this.setState({ allUsers: aZ });
        break;
      case 'Z-A':
        const zA = allUsers.sort((user1, user2) =>
          user1.email < user2.email ? 1 : -1
        );
        this.setState({ allUsers: zA });
        break;
      case 'Role':
        const byRole = allUsers.sort((user1, user2) =>
          user1.role > user2.role ? 1 : -1
        );
        this.setState({ allUsers: byRole });
        break;
    }
  };

  /* eslint-disable camelcase */
  fetchUsers = async () => {
    let users = await getUsers();
    users = users.sort((a, b) => (a.email > b.email ? 1 : -1));
    this.setState({
      allUsers: users,
    });
    return users;
  };

  renderTableData = () =>
    this.state.allUsers.map((user) => {
      const { first_name, id, last_name, email, role, is_admin } = user;

      return (
        <UserTableRow
          first_name={first_name}
          last_name={last_name}
          email={email}
          role={role}
          is_admin={is_admin}
          id={id}
          key={id}
          remove={(event) => this.handleDeleteUser(event, id)}
          roleOptions={this.state.rolesOptions}
          isAdminOptions={this.state.isAdminOptions}
          handleTextInput={this.handleTextFieldUpdate}
          handleRoleSelect={this.handleRoleSelectUpdate}
          handleAdminSelect={this.handleAdminSelectUpdate}
        />
      );
    });

  /* eslint-disable arrow-body-style */
  /* eslint-disable react/no-array-index-key */
  renderTableHeader() {
    const header = this.state.headers;
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  }

  render() {
    const { allUsers } = this.state;
    return (
      <div>
        <h1 className="title">Users Table</h1>
        <AboveTableDiv>
          <Select
            options={sortOpts}
            onChange={(e) => this.handleSort(e.value)}
            placeholder="A-Z (email)"
            styles={dropdownStyles}
            className="select"
          />
        </AboveTableDiv>
        <Table data={allUsers}>
          <tbody>
            <tr>{this.renderTableHeader()}</tr>
            {this.renderTableData()}
          </tbody>
        </Table>
        {`Total Users: ${allUsers.length}`}
      </div>
    );
  }
}

export default UserTable;
