import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { createStrikezone, getDropDowns } from '../../../lib/client';
import {
  ExampleText,
  LoadingSpinner,
  StrikezoneButton,
  StrikezoneDiv,
  StrikezoneInfoRow,
  StrikezoneInput,
  StatusScreen,
  Tag,
  TagList,
  TheInputDiv
} from './styles';
import {
  Section,
  SectionContents,
  VerticalSelect,
  SubverticalSelect
} from '../../Packaged/styles';
import {
  dropdownStyles,
  ProspectorSectionTitle,
  SuccessCheckMark
} from '../../Prospector/styles';

const DEFAULT_VERTICAL = { label: 'Retail', value: 'retail', id: 6 };
const DEFAULT_SUBVERTICAL = { label: 'Appliance', value: 'Appliance' };
const VERTICAL_ENDPOINT = '/fetch-verticals';
const SUBVERTICAL_ENDPOINT = '/fetch-sub-verticals';
const SET_DEFAULT_OPTIONS = {
  retail: [
    {
      label: 'Retail',
      value: 'retail'
    }
  ]
};
const DEFAULT_SUB_OPTIONS = {
  retail: [
    {
      label: 'Appliance',
      value: 'Appliance'
    }
  ]
};

export default class AddStrikezone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addedStrikezone: false,
      loading: false,
      newSzName: '',
      newSzSearchStrings: [],
      newSzFalsePositives: [],
      newSzRequiredTypes: [],
      newSzOptionalTypes: ["establishment", "point_of_interest"],
      newSzMinRevenue: 500000,
      newSzMaxRevenue: 1000000000,
      newSzMinEmployees: 1,
      newSzMaxEmployees: 500,
      selectedVertical:
        JSON.parse(localStorage.getItem('chosenVertical')) || DEFAULT_VERTICAL,
      selectedVerticalId:
        parseInt(localStorage.getItem('chosenVerticalId'), 10) ||
        DEFAULT_VERTICAL.id,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        DEFAULT_SUBVERTICAL,
      selectedSubverticalOptions: DEFAULT_SUB_OPTIONS.retail,
      selectedVerticalOptions: SET_DEFAULT_OPTIONS.retail
    };
  }

  componentDidMount() {
    getDropDowns(VERTICAL_ENDPOINT).then(res => {
      return this.processVerticals(res);
    });

    getDropDowns(SUBVERTICAL_ENDPOINT).then(res => {
      return this.processSubverticals(res);
    });
  }

  processVerticals = res => {
    const selectedVerticalOptions = res.map(v => {
      return { label: v.name, value: v.name, id: v.id };
    });

    this.setState({ selectedVerticalOptions });
  };
  /* eslint-disable array-callback-return */
  processSubverticals = res => {
    const validSubVerticals = [];
    const { selectedVerticalId } = this.state;

    res.map(sub => {
      const formattedSubVertical = {
        label: sub.name,
        value: sub.name,
        vertical_id: sub.vertical_id
      };

      if (!_.some(validSubVerticals, formattedSubVertical)) {
        validSubVerticals.push(formattedSubVertical);
      }
    });

    const selectedSubverticalOptions = _.filter(
      validSubVerticals,
      sub => sub.vertical_id === selectedVerticalId
    );

    this.setState({
      validSubVerticals,
      selectedSubverticalOptions,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        selectedSubverticalOptions[0]
    });
  };

  numberFormatter = (number) => {
    if (number > 2147483647) {
      return 2147483647;
    } else if (number <= 0) {
      return 1;
    }
    return number;
  };

  // handles
  handleVerticalChange = option => {
    localStorage.setItem('chosenVertical', JSON.stringify(option));
    const { validSubVerticals, selectedVerticalOptions } = this.state;

    const selectedOption = _.find(
      selectedVerticalOptions,
      optn => optn.value === option.value
    );
    localStorage.setItem('chosenVerticalId', selectedOption.id);

    const selectedSubverticalOptions = _.filter(
      validSubVerticals,
      sub => sub.vertical_id === selectedOption.id
    );

    const hasOneSubvertical = selectedSubverticalOptions.length > 0;

    this.setState({
      selectedVertical: option,
      selectedSubverticalOptions,
      selectedSubvertical: hasOneSubvertical
        ? selectedSubverticalOptions[0]
        : ''
    });
    if (hasOneSubvertical) {
      this.handleSubverticalChange(selectedSubverticalOptions[0]);
    }
  };

  handleSubverticalChange = option => {
    localStorage.setItem('chosenSubvertical', JSON.stringify(option));
    this.setState({
      selectedSubvertical: option
    });
  };

  handleSubmitNewSz = async () => {
    const {
      newSzName,
      newSzSearchStrings,
      newSzFalsePositives,
      newSzRequiredTypes,
      newSzOptionalTypes,
      newSzMinRevenue,
      newSzMaxRevenue,
      newSzMinEmployees,
      newSzMaxEmployees,
      selectedSubvertical
    } = this.state;
    this.setState({ loading: true })

    const newSz = {
      name: newSzName,
      sub_vertical_name: selectedSubvertical.value,
      search_strings: newSzSearchStrings,
      false_positive_company_names: newSzFalsePositives,
      required_google_place_types: newSzRequiredTypes,
      optional_google_place_types: newSzOptionalTypes,
      min_revenue: this.numberFormatter(newSzMinRevenue),
      max_revenue: this.numberFormatter(newSzMaxRevenue),
      min_employee_count: this.numberFormatter(newSzMinEmployees),
      max_employee_count: this.numberFormatter(newSzMaxEmployees)
    };

    console.log("NEWSZ", newSz)

    await createStrikezone(newSz);
    this.setState({ loading: false, addedStrikezone: true });
  };

  handleSzNameInput = event =>
    this.setState({
      newSzName: event.target.value
    });

  /* eslint-disable no-param-reassign */
  /* eslint-disable react/no-array-index-key */
  /* eslint-disable react/jsx-curly-brace-presence */
  handleAddSearchStrings = event => {
    const { newSzSearchStrings } = this.state;
    const val = event.target.value;
    if (event.key === 'Enter' && val) {
      if (
        newSzSearchStrings.find(tag => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({
        newSzSearchStrings: [...newSzSearchStrings, val]
      });
      event.target.value = '';
    }
  };

  handleRemoveSearchStrings = indexToRemove => {
    const { newSzSearchStrings } = this.state;
    const updated = newSzSearchStrings.filter(
      (x, index) => index !== indexToRemove
    );

    this.setState({
      newSzSearchStrings: updated
    });
  };

  handleAddFalsePositives = event => {
    const { newSzFalsePositives } = this.state;
    const val = event.target.value;
    if (event.key === 'Enter' && val) {
      if (
        newSzFalsePositives.find(tag => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({
        newSzFalsePositives: [...newSzFalsePositives, val]
      });
      event.target.value = '';
    }
  };

  handleRemoveFalsePositives = indexToRemove => {
    const { newSzFalsePositives } = this.state;
    const updated = newSzFalsePositives.filter(
      (x, index) => index !== indexToRemove
    );

    this.setState({
      newSzFalsePositives: updated
    });
  };

  handleAddRequiredTypes = event => {
    const { newSzRequiredTypes } = this.state;
    const val = event.target.value;
    if (event.key === 'Enter' && val) {
      if (
        newSzRequiredTypes.find(tag => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({
        newSzRequiredTypes: [...newSzRequiredTypes, val]
      });
      event.target.value = '';
    }
  };

  handleRemoveRequiredTypes = indexToRemove => {
    const { newSzRequiredTypes } = this.state;
    const updated = newSzRequiredTypes.filter(
      (x, index) => index !== indexToRemove
    );

    this.setState({
      newSzRequiredTypes: updated
    });
  };

  handleAddOptionalTypes = event => {
    const { newSzOptionalTypes } = this.state;
    const val = event.target.value;
    if (event.key === 'Enter' && val) {
      if (
        newSzOptionalTypes.find(tag => tag.toLowerCase() === val.toLowerCase())
      ) {
        return;
      }
      this.setState({
        newSzOptionalTypes: [...newSzOptionalTypes, val]
      });
      event.target.value = '';
    }
  };

  handleRemoveOptionalTypes = indexToRemove => {
    const { newSzOptionalTypes } = this.state;
    const updated = newSzOptionalTypes.filter(
      (x, index) => index !== indexToRemove
    );

    this.setState({
      newSzOptionalTypes: updated
    });
  };

  handleMinRevenueInput = event =>
    this.setState({
      newSzMinRevenue: event.target.value
    });

  handleMaxRevenueInput = event =>
    this.setState({
      newSzMaxRevenue: event.target.value
    });

  handleMinEmployeeInput = event =>
    this.setState({
      newSzMinEmployees: event.target.value
    });

  handleMaxEmployeeInput = event =>
    this.setState({
      newSzMaxEmployees: event.target.value
    });

  handleAddAnother = () =>
    this.setState({
      addedStrikezone: false,
      newSzName: '',
      newSzSearchStrings: [],
      newSzFalsePositives: [],
      newSzRequiredTypes: [],
      newSzOptionalTypes: ["establishment", "point_of_interest"],
      newSzMinRevenue: 500000,
      newSzMaxRevenue: 1000000000,
      newSzMinEmployees: 1,
      newSzMaxEmployees: 500,
      selectedVertical:
        JSON.parse(localStorage.getItem('chosenVertical')) || DEFAULT_VERTICAL,
      selectedVerticalId:
        parseInt(localStorage.getItem('chosenVerticalId'), 10) ||
        DEFAULT_VERTICAL.id,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        DEFAULT_SUBVERTICAL
  })

  /* eslint-disable no-return-assign */
  handleGoToProspector = () => window.location = '/prospector';

  render() {
    const {
      addedStrikezone,
      loading,
      newSzMinRevenue,
      newSzMaxRevenue,
      newSzMinEmployees,
      newSzMaxEmployees,
      newSzSearchStrings,
      newSzFalsePositives,
      newSzRequiredTypes,
      newSzOptionalTypes,
      selectedSubverticalOptions,
      selectedSubvertical,
      selectedVertical,
      selectedVerticalOptions
    } = this.state;

    return (
      <React.Fragment>
        {addedStrikezone === false &&
          <React.Fragment>
            <Section>
              <ProspectorSectionTitle>
                <div>VERTICAL</div>
              </ProspectorSectionTitle>
              <SectionContents>
                <VerticalSelect data-tip>
                  <Select
                    isSearchable
                    options={selectedVerticalOptions}
                    styles={dropdownStyles}
                    className={selectedVertical}
                    value={selectedVertical}
                    onChange={option => this.handleVerticalChange(option)}
                  />
                </VerticalSelect>
              </SectionContents>
            </Section>
            <Section>
              <ProspectorSectionTitle>
                <div>SUBVERTICAL</div>
              </ProspectorSectionTitle>
              <SectionContents>
                <SubverticalSelect>
                  <Select
                    isSearchable
                    styles={dropdownStyles}
                    options={selectedSubverticalOptions}
                    value={selectedSubvertical}
                    className={selectedSubvertical}
                    onChange={option => this.handleSubverticalChange(option)}
                  />
                </SubverticalSelect>
              </SectionContents>
            </Section>
          </React.Fragment>
        }
        {selectedSubvertical && (
          <Section>
            <StrikezoneDiv>
              {addedStrikezone === false &&
                <React.Fragment>
                  <h1>Build Your New Strikezone</h1>
                  <div id="rows">
                    <StrikezoneInfoRow>
                      <div id="text">{`Strikezone Name: `}</div>
                      <TheInputDiv>
                        <StrikezoneInput
                          type="text"
                          onKeyDown={this.handleSzNameInput}
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <StrikezoneInfoRow>
                      <div id="text">{`Search terms: `}</div>
                      <TheInputDiv>
                        <TagList>
                          {newSzSearchStrings.map((tag, index) => (
                            <Tag key={index}>
                              <span className="tag-title">{tag}</span>
                              <span
                                role="button"
                                tabIndex={index}
                                className="tag-close-icon"
                                onClick={() =>
                                  this.handleRemoveSearchStrings(index)
                                }
                              >
                                x
                              </span>
                            </Tag>
                          ))}
                        </TagList>
                        <StrikezoneInput
                          type="text"
                          onKeyDown={this.handleAddSearchStrings}
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Fill in the blank: "_____ near 84065" (We'll add "near zip code" for you!)`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Companies we avoid: `}</div>
                      <TheInputDiv>
                        <TagList>
                          {newSzFalsePositives.map((tag, index) => (
                            <Tag key={index}>
                              <span className="tag-title">{tag}</span>
                              <span
                                role="button"
                                tabIndex={index}
                                className="tag-close-icon"
                                onClick={() =>
                                  this.handleRemoveFalsePositives(index)
                                }
                              >
                                x
                              </span>
                            </Tag>
                          ))}
                        </TagList>
                        <StrikezoneInput
                          type="text"
                          onKeyDown={this.handleAddFalsePositives}
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Ex: Amazon, Jiffy Lube, Walmart`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Required Google Place Types: `}</div>
                      <TheInputDiv>
                        <TagList>
                          {newSzRequiredTypes.map((tag, index) => (
                            <Tag key={index}>
                              <span className="tag-title">{tag}</span>
                              <span
                                role="button"
                                tabIndex={index}
                                className="tag-close-icon"
                                onClick={() =>
                                  this.handleRemoveRequiredTypes(index)
                                }
                              >
                                x
                              </span>
                            </Tag>
                          ))}
                        </TagList>
                        <StrikezoneInput
                          type="text"
                          onKeyDown={this.handleAddRequiredTypes}
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Choose a Google Place Type that should always appear no matter what.`}
                      <br />
                      {`Ex: "plumber" is a required Google Place Type for the Plumbing strikezone.`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Optional Google Place Types: `}</div>
                      <TheInputDiv>
                        <TagList>
                          {newSzOptionalTypes.map((tag, index) => (
                            <Tag key={index}>
                              <span className="tag-title">{tag}</span>
                              <span
                                role="button"
                                tabIndex={index}
                                className="tag-close-icon"
                                onClick={() =>
                                  this.handleRemoveOptionalTypes(index)
                                }
                              >
                                x
                              </span>
                            </Tag>
                          ))}
                        </TagList>
                        <StrikezoneInput
                          type="text"
                          onKeyDown={this.handleAddOptionalTypes}
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Choose some Google Place Types that are allowed to show up during a search. We automatically include "establishment" & "point_of_interest" for you. `}
                      {`Ex: "store" is an optional Google Place Type for the Fine Jewelry strikezone because it usually shows up for Jewelers.`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Minimum Revenue: `}</div>
                      <TheInputDiv>
                        <StrikezoneInput
                          type="text"
                          onChange={this.handleMinRevenueInput}
                          value={newSzMinRevenue}
                          maxlength="10"
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Based on if Zoominfo data exists for a business.`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Maximum Revenue: `}</div>
                      <TheInputDiv>
                        <StrikezoneInput
                          type="text"
                          onChange={this.handleMaxRevenueInput}
                          value={newSzMaxRevenue}
                          maxlength="10"
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Based on if Zoominfo data exists for a business.`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Minimum Employee Count: `}</div>
                      <TheInputDiv>
                        <StrikezoneInput
                          type="text"
                          onChange={this.handleMinEmployeeInput}
                          value={newSzMinEmployees}
                          maxlength="10"
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Based on if Zoominfo data exists for a business.`}
                    </ExampleText>
                    <StrikezoneInfoRow>
                      <div id="text">{`Maximum Employee Count: `}</div>
                      <TheInputDiv>
                        <StrikezoneInput
                          type="text"
                          onChange={this.handleMaxEmployeeInput}
                          value={newSzMaxEmployees}
                          maxlength="10"
                        />
                      </TheInputDiv>
                    </StrikezoneInfoRow>
                    <ExampleText>
                      {`Based on if Zoominfo data exists for a business.`}
                    </ExampleText>
                  </div>
                  <React.Fragment>
                    <StrikezoneButton onClick={this.handleSubmitNewSz}>
                      Submit
                      {loading &&
                        <LoadingSpinner width={25} height={25} paddingLeft={10} color="white" />
                      }
                    </StrikezoneButton>
                  </React.Fragment>
                </React.Fragment>
              }
              {addedStrikezone &&
                <StatusScreen>
                  <SuccessCheckMark>✔</SuccessCheckMark>
                  <p>Strikezone successfully created.</p>
                  <div id="buttonDiv">
                    <button onClick={this.handleAddAnother}>
                      Add another?
                    </button>
                    <button onClick={this.handleGoToProspector}>
                      Go To Prospector
                    </button>
                  </div>
                </StatusScreen>
              }
            </StrikezoneDiv>
          </Section>
        )}
      </React.Fragment>
    );
  }
}
