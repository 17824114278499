/* eslint-disable dot-notation */
import React, { Component } from 'react';
import { Checkbox } from '@podiumhq/podium-ui';
import { getQuarterData } from '../greenClient';
import StageChart from './StageChart';
import {
  CheckDiv,
  LoadingSpinner,
  StageContainer
} from './styles';

// eslint-disable-next-line react/prefer-stateless-function
export default class QuarterCharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adjustedData: null,
      data: null,
      hideUnknown: false
    };
  }
  
  componentDidMount() {
    getQuarterData().then(res => {
      this.setState({
        data: res,
        adjustedData: this.removeUnknown(res)
      })
    });
  }

  handleHideUnknown = () => {
    this.setState({
      hideUnknown: !this.state.hideUnknown    
    });
  };

  removeUnknown = (data) => {
    if (!data) {
      return null;
    }
    const adjusted = JSON.parse(JSON.stringify(data)); // allows a deep copy

    const emptyUnknown = {
      "Phone Interview": 0,
      "Onsite Interview": 0,
      "Offer": 0,
      "Hired": 0,
      "Application Review": 0
    };

    adjusted["q1"].total_eeocs = data["q1"].total_eeocs - data["q1"].unknown["Application Review"];
    adjusted["q1"].total_phone = data["q1"].total_phone - data["q1"].unknown["Phone Interview"];
    adjusted["q1"].total_onsite = data["q1"].total_onsite - data["q1"].unknown["Onsite Interview"];
    adjusted["q1"].total_offer = data["q1"].total_offer - data["q1"].unknown["Offer"];
    adjusted["q1"].total_hired = data["q1"].total_hired - data["q1"].unknown["Hired"];
    adjusted["q2"].total_eeocs = data["q2"].total_eeocs - data["q2"].unknown["Application Review"];
    adjusted["q2"].total_phone = data["q2"].total_phone - data["q2"].unknown["Phone Interview"];
    adjusted["q2"].total_onsite = data["q2"].total_onsite - data["q2"].unknown["Onsite Interview"];
    adjusted["q2"].total_offer = data["q2"].total_offer - data["q2"].unknown["Offer"];
    adjusted["q2"].total_hired = data["q2"].total_hired - data["q2"].unknown["Hired"];
    adjusted["q3"].total_eeocs = data["q3"].total_eeocs - data["q3"].unknown["Application Review"];
    adjusted["q3"].total_phone = data["q3"].total_phone - data["q3"].unknown["Phone Interview"];
    adjusted["q3"].total_onsite = data["q3"].total_onsite - data["q3"].unknown["Onsite Interview"];
    adjusted["q3"].total_offer = data["q3"].total_offer - data["q3"].unknown["Offer"];
    adjusted["q3"].total_hired = data["q3"].total_hired - data["q3"].unknown["Hired"];
    adjusted["q4"].total_eeocs = data["q4"].total_eeocs - data["q4"].unknown["Application Review"];
    adjusted["q4"].total_phone = data["q4"].total_phone - data["q4"].unknown["Phone Interview"];
    adjusted["q4"].total_onsite = data["q4"].total_onsite - data["q4"].unknown["Onsite Interview"];
    adjusted["q4"].total_offer = data["q4"].total_offer - data["q4"].unknown["Offer"];
    adjusted["q4"].total_hired = data["q4"].total_hired - data["q4"].unknown["Hired"];
    adjusted["q1"].unknown = emptyUnknown;
    adjusted["q2"].unknown = emptyUnknown;
    adjusted["q3"].unknown = emptyUnknown;
    adjusted["q4"].unknown = emptyUnknown;

    return adjusted;
  }
  
  render() {
    const {
      adjustedData,
      data,
      hideUnknown,
    } = this.state;
    /* eslint-disable camelcase */
    /* eslint-disable react/jsx-curly-brace-presence */
    /* eslint-disable prefer-template */

    return (
      <React.Fragment>
        {data &&
          <React.Fragment>
            {!hideUnknown &&
              <React.Fragment>
                <StageContainer>
                  <StageChart stage="Application Review" data={data} />
                  <StageChart stage="Phone Interview" data={data} />
                  <StageChart stage="Onsite Interview" data={data} />
                </StageContainer>
                <StageContainer>
                  <StageChart stage="Offer" data={data} />
                  <StageChart stage="Hired" data={data} />
                  <h4>Total Hires: {data["q1"].total_hired + data["q2"].total_hired + data["q3"].total_hired + data["q4"].total_hired}</h4>
                </StageContainer>
              </React.Fragment>
            }
            {hideUnknown &&
              <React.Fragment>
                <StageContainer>
                  <StageChart stage="Application Review" data={adjustedData} />
                  <StageChart stage="Phone Interview" data={adjustedData} />
                  <StageChart stage="Onsite Interview" data={adjustedData} />
                </StageContainer>
                <StageContainer>
                  <StageChart stage="Offer" data={adjustedData} />
                  <StageChart stage="Hired" data={adjustedData} />
                  <h4>Total Hires: {data["q1"].total_hired + data["q2"].total_hired + data["q3"].total_hired + data["q4"].total_hired}</h4>
                </StageContainer>
              </React.Fragment>
            }

            <CheckDiv>
              <Checkbox
                checked={hideUnknown}
                onChange={this.handleHideUnknown}
              />
            </CheckDiv>
            <CheckDiv>
              Remove unknown responses and adjust % for given answers.
            </CheckDiv>
          </React.Fragment>
        }
        {!data &&
          <LoadingSpinner width={50} height={50} color="#4B76E0" />
        }
      </React.Fragment>
    );
  }
}
