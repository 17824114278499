import React from 'react';
import _ from 'lodash';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { toolTips } from '../Prospector/optionData';
import LocationSearchInput from './LocationSearchInput/LocationSearchInput';
import ScoreResult from './ScoreResult/ScoreResult';
import SalesforceDuplicates from './SalesforceDuplicates/SalesforceDuplicates';

import { dropdownStyles } from '../Prospector/styles';
import {
  LeadScoringContainer,
  ScoringButton,
  ScoreResultContainer,
  ButtonSpinner
} from './styles';
import analytics from '../../lib/analytics';
import { verticalOptions, subverticalOptions } from '../../lib/toolData';
import {
  fetchLeadScore,
  fetchSalesforceDuplicates,
  createSalesforceLead
} from '../../lib/client';
import {
  Divider,
  Section,
  SectionContents,
  SectionTitle,
  VerticalSelect,
  SubverticalSelect
} from '../Packaged/styles';
import { getCurrentUser } from '../../lib/auth';

const DEFAULT_VERTICAL = [{ label: 'Auto', value: 'Auto' }];
const DEFAULT_SUBVERTICAL = [{ label: 'OEM/New Car Dealer', value: 'OEM/New Car Dealer' }];

/* eslint-disable object-shorthand */
/* eslint-disable no-shadow */
/* eslint-disable dot-notation */
export default class LeadScoring extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVertical: DEFAULT_VERTICAL[0],
      selectedSubvertical: DEFAULT_SUBVERTICAL[0],
      placeId: null,
      businessName: null,
      sessionToken: null,
      fetchingResultData: {},
      duplicates: [],
      loading: false,
      createdLeadId: '',
      subverticalOptions: subverticalOptions['Auto'],
      verticalOptions: verticalOptions
    };
  }

  selectLocation = (businessName, placeId, sessionToken) => {
    this.setState({
      placeId,
      sessionToken,
      businessName
    });
  };

  fetchLocation = async () => {
    analytics.fireEvent({
      category: 'Moneyball',
      action: 'Scored Business'
    });

    const { placeId, sessionToken, selectedVertical } = this.state;
    if (placeId && sessionToken && selectedVertical) {
      const response = await fetchLeadScore(placeId, sessionToken, selectedVertical);
      return response;
    }
    return {};
  };

  findSalesforceDuplicates = async () => {
    const { placeId } = this.state;
    if (placeId) {
      const response = await fetchSalesforceDuplicates(placeId);
      return response;
    }
    return [];
  };

  fetchAnyway = async () => {
    analytics.fireEvent({
      category: 'Moneyball',
      action: 'Fetched Anyway'
    });

    this.setState({
      loading: true
    });
    const fetchResultData = await this.fetchLocation();

    this.setState({
      duplicates: [],
      fetchingResultData: fetchResultData,
      loading: false
    });
  };

  handleFetchButtonClick = async () => {
    this.setState({
      loading: true
    });
    const duplicates = await this.findSalesforceDuplicates();
    if (!_.isEmpty(duplicates)) {
      this.setState({
        duplicates,
        loading: false
      });
    } else {
      const fetchingResultData = await this.fetchLocation();
      this.setState({
        fetchingResultData,
        duplicates: [],
        loading: false
      });
    }
  };

  handleVerticalChange = option => (
    this.setState({
      selectedVertical: option,
      subverticalOptions: subverticalOptions[option.value],
      selectedSubvertical: subverticalOptions[option.value][0]
    })
  );

  handleSubverticalChange = option => (
    this.setState({
      selectedSubvertical: option
    })
  );

  uploadToSalesforce = async (data) => {
    analytics.fireEvent({
      category: 'Moneyball',
      action: 'Uploaded to Salesforce'
    });

    const user = getCurrentUser();
    const { selectedVertical, selectedSubvertical } = this.state;
    this.setState({ loading: true });
    const finalData = {
      ...data,
      vertical: selectedVertical.value,
      subvertical: selectedSubvertical.value,
      ownerEmail: user.email
    };

    const result = await createSalesforceLead(finalData);

    this.setState({
      loading: false,
      createdLeadId: result.leadId,
    });
  }

  render() {
    const {
      duplicates,
      businessName,
      fetchingResultData,
      loading,
      createdLeadId,
      subverticalOptions,
      selectedSubvertical,
      selectedVertical,
      verticalOptions
    } = this.state;
    const hasDuplicates = !_.isEmpty(duplicates);

    return (
      <LeadScoringContainer>
        <Section>
          <SectionTitle>
            <div>01</div>
            <div>VERTICAL</div>
          </SectionTitle>
          <SectionContents>
            <VerticalSelect>
              <Select
                placeholder={selectedVertical}
                isSearchable
                options={verticalOptions}
                styles={dropdownStyles}
                className={selectedVertical}
                value={selectedVertical}
                onChange={option => this.handleVerticalChange(option)}
              />
            </VerticalSelect>
          </SectionContents>
        </Section>
        <Section>
          <SectionTitle>
            <div>02</div>
            <div>SUBVERTICAL</div>
          </SectionTitle>
          <SectionContents>
            <SubverticalSelect>
              <Select
                placeholder={selectedSubvertical}
                isSearchable
                options={subverticalOptions}
                styles={dropdownStyles}
                className={selectedSubvertical}
                value={selectedSubvertical}
                onChange={option => this.handleSubverticalChange(option)}
              />
            </SubverticalSelect>
          </SectionContents>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>
            <div>03</div>
            <div>SEARCH</div>
          </SectionTitle>
          <SectionContents>
            <LocationSearchInput onSelect={this.selectLocation} />
            <ScoringButton onClick={this.handleFetchButtonClick} height={40}>
              <Tooltip
                title={toolTips.uploadTool}
                position="top"
                trigger="mouseenter"
                size="big"
                arrow
                distance={30}
                offset={-40}
                delay={200}
                hideDelay={0}
              >
                <span>Fetch Business</span>
              </Tooltip>
              {loading && <ButtonSpinner width={20} height={20} color="white" />}
            </ScoringButton>
          </SectionContents>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>
            <div>04</div>
            <div>RESULTS</div>
          </SectionTitle>
          <SectionContents>
            <ScoreResultContainer>
              { hasDuplicates ?
                <SalesforceDuplicates
                  businessName={businessName}
                  duplicates={duplicates}
                  onFetchAnyway={this.fetchAnyway}
                /> :
                <ScoreResult
                  selectedVertical={selectedVertical}
                  createdLeadId={createdLeadId}
                  loading={loading}
                  data={fetchingResultData}
                  onPrepareUpload={this.uploadToSalesforce}
                />
              }
            </ScoreResultContainer>
          </SectionContents>
        </Section>
      </LeadScoringContainer>
    );
  }
}
