import styled from 'styled-components';
import colors from '../../styles/colors';

export const PackagedContainer = styled.div`
  margin: 0 auto;
`;

export const Divider = styled.div`
  background-color: #E4E9F0;
  max-width: 992px;
  width: 100%;
  height: 2px;
`;

export const Section = styled.div`
  display: flex;
  margin: 56px 0;
`;

export const VerticalSelect = styled.div`
  width: 100%;
  max-width: 518px;
  font-weight: 600;
  color: #333333;
  li {
    transition: .25s;
    padding: 10px;
    &:hover, &:focus {
      background: ${colors.whiteSmoke};
      color: #000;
    }
  }
  div {
    span {
      div {
        height: 68px;
        padding: 13px;
      }
      .autoNew {
        &:after {
          content: " (New)";
          font-weight: 400;
        }
      }
      .autoUsed {
        &:after {
          content: " (Used)";
          font-weight: 400;
        }
      }
    }
  }
`;

export const SubverticalSelect = styled(VerticalSelect)`
  li {
    &:first-child {
      &:after {
        content: ""
      }
    }
    &:nth-child(2) {
      &:after {
        content: ""
      }
    }
  }
`;

export const StateSelect = styled(VerticalSelect)`
  li {
    &:first-child {
      &:after {
        content: ""
      }
    }
    &:nth-child(2) {
      &:after {
        content: ""
      }
    }
  }
`;

export const SectionContents = styled.div`
  width: 100%;
  position: relative;
`;

export const SectionTitle = styled.div`
  width: 150px;
  margin-right: 48px;

  div:first-child {
    font-weight: 700;
    font-size: 21px;
  }

  div:last-child {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }
`;
