import styled from 'styled-components';
import Spinner from '../../../components/Spinner/Spinner';

export const LoadingScreen = styled.div`
  background-color: #F9FAFC;
  height: 375px;
  text-align: center;
  padding-top: 90px;

  h3 {
    margin-bottom: 14px;
  }

  p {
    margin-top: 0;
  }

  .leadCountTextDiv {
    display: flex;
    flex-direction: column;
  }
`;

export const LoadingSpinner = styled(Spinner)`
  margin: auto;
`;

export const FilterTicker = styled.div`
  margin-top: 200px;
  align-content: center;
  height: 250px;
  overflow: hidden;
  position: relative;
`;

export const FilterFade = styled.div`
  position: absolute;
  bottom: 0px;
  display: block;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, 
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.9)
  100%);
`;

export const TheStatusScreen = styled.div`
  background-color: #F9FAFC;
  text-align: center;
  padding-top: 105px;
  height: 450px;

  .successCheckMark {
    height: 50px;
  }

  span {
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
  }

  .alertCircle {
    height: 75px;
    margin-bottom: 12px;
  }

  .restoreSessionButton {
    background-color: #00BD94;
    color: white;
    height: 40px;
    border: none;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 180px;
    font-weight: 600;
    text-decoration: none;
  }
`;

export const NoLeadScreen = styled(TheStatusScreen)`
  padding-top: 75px;
  height: 550px;
`;
