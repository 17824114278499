import React, { Component } from 'react';
import Select from 'react-select';
import {
  dropdownStyles,
  DeleteButton,
  SubmitButton,
  TableInput,
  UpdateButton
} from '../styles';
import { updateUser } from '../../../lib/client';
import { isAdminOpts, roleDropdownOpts } from '../optionData';

/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
/* eslint-disable no-tabs */
/* eslint-disable react/jsx-indent-props */
class UserTableRow extends Component {
 constructor(props) {
   super(props);
   this.state = {
     updatingUser: false
   };
 }

  handleEditButtonClick = () => {
    this.setState({
      updatingUser: !this.state.updatingUser
    });
	};

  handleSubmitUpdatedUser = async (user) => {
    await updateUser({
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      role: user.role,
      is_admin: user.is_admin,
      id: user.id
    });

    this.setState({
      updatingUser: false
    });
  };

  render() {
    const {
      email,
      first_name,
      id,
      is_admin,
      last_name,
      role,
    } = this.props;
    return (
      <tr key={id}>
        <td>
          <TableInput
            value={first_name}
            className="firstName"
            disabled={!this.state.updatingUser}
            onChange={e =>
              this.props.handleTextInput(id, 'first_name', e.target.value)
            }
          />
        </td>
        <td>
          <TableInput
            value={last_name}
            className="lastName"
            disabled={!this.state.updatingUser}
            onChange={e =>
              this.props.handleTextInput(id, 'last_name', e.target.value)
            }
          />
        </td>
        <td className="email">
          <TableInput
            value={email}
						className="email"
						disabled={!this.state.updatingUser}
            width="250px"
            onChange={e =>
              this.props.handleTextInput(id, 'email', e.target.value)
            }
          />
        </td>
        <td>
          <Select
            placeholder={role}
            value={role}
            options={roleDropdownOpts}
            className="role"
						isDisabled={!this.state.updatingUser}
						onChange={r =>
							this.props.handleRoleSelect(id, r)
            }
            styles={dropdownStyles}
          />
        </td>
        <td>
          <Select
            placeholder={is_admin.toString()}
            value={is_admin.toString()}
            options={isAdminOpts}
            className="isAdmin"
						isDisabled={!this.state.updatingUser}
						onChange={isAdmin =>
							this.props.handleAdminSelect(id, isAdmin)
            }
            styles={dropdownStyles}
          />
        </td>
        <td>
          <DeleteButton
            className="delete"
            onClick={this.props.remove}
          >
            Delete
          </DeleteButton>
        </td>
        <td>
          {!this.state.updatingUser && (
            <UpdateButton
              className="update"
              onClick={() =>
                this.handleEditButtonClick()
              }
            >
              Edit
            </UpdateButton>
          )}
          {this.state.updatingUser && (
            <SubmitButton
              className="submit"
              onClick={() =>
                this.handleSubmitUpdatedUser({
                  first_name,
                  last_name,
                  email,
                  role,
                  is_admin,
                  id
                })
              }
            >
              Update
            </SubmitButton>
          )}
        </td>
      </tr>
    );
  }
}

export default UserTableRow;
