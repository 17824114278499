import React from 'react';
import _ from 'lodash';
import {
  Divider,
} from '../../Packaged/styles';
import ProspectorCard from '../ProspectorCards/ProspectorCard';
import { BackButton } from '../styles';

const ProspectorList = ({
  additionalRejectionData,
  goBack,
  prospectingData,
  onAdditionalRejectionData,
  onCardTextFieldUpdate,
  onDecisionButtonClick,
  onInputError,
  onRejectSelectUpdate,
  status,
  toggleContactModal,
}) =>
  (
    <div>
      {prospectingData.length > 0 &&
      <React.Fragment>
        <BackButton onClick={goBack}>
          ← Back
        </BackButton>
        <br />
        <h4>Accept or Reject</h4>
        <Divider />
        <br />
        <br />
      </React.Fragment>}
      {_.map(prospectingData, prospect =>
        (<ProspectorCard
          additionalRejectionData={additionalRejectionData}
          key={prospect.id}
          data={prospect}
          status={status}
          onInputError={onInputError}
          onTextFieldUpdate={onCardTextFieldUpdate}
          onDecisionButtonClick={onDecisionButtonClick}
          onRejectSelectUpdate={onRejectSelectUpdate}
          onAdditionalRejectionData={onAdditionalRejectionData}
          toggleContactModal={toggleContactModal}
        />))
      }
    </div>
  );

export default ProspectorList;
