import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input } from '@podiumhq/podium-ui';

import Row from './styles';

const {
  func,
  string,
  number,
  shape
} = PropTypes;

class SetupSection extends Component {
  static propTypes = {
    updateEmployees: func.isRequired,
    currentPlatform: shape({
      title: string,
      price: number,
      type: string,
      users: number
    }).isRequired,
    onLocationsChange: func.isRequired
  }

  onEmployeesChange = (e) => {
    const employeeCount = _.parseInt(e.target.value) || 1;
    this.props.updateEmployees(employeeCount, this.props.currentPlatform);
  }

  render() {
    const { onLocationsChange } = this.props;
    return (
      <div>
        <Row>
          <Input
            type="number"
            name="locations"
            onChange={e => onLocationsChange(_.parseInt(e.target.value) || 1)}
            style={{ width: '65px' }}
          />
          <div>Locations</div>
        </Row>
        <Row>
          <Input
            type="number"
            name="users"
            onChange={e => this.onEmployeesChange(e)}
            style={{ width: '65px' }}
          />
          <div>Number of Users (per location)</div>
        </Row>
      </div>
    );
  }
}

export default SetupSection;
