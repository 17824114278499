const truncate = `
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const fontFamily = 'Avenir, Helvetica, sans-serif';

export default {
  truncate,
  fontFamily
};
