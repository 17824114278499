import styled from 'styled-components';
import Spinner from '../../components/Spinner/Spinner';
import { SectionTitle } from '../Packaged/styles';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

const getStatusColor = (props) => {
  if (props.status === 'yes') {
    return '#00e676';
  }
  if (props.status === 'no') {
    return '#ff1744';
  }
  if ((props.status && props.status.length > 0) || props.status > 0) {
    return '#00e676';
  }
  return '#bdbdbd';
};

const getStatusFontColor = (props) => {
  if (props.status === '') {
    return '#bdbdbd';
  }
  if (props.status === 0) {
    return '#bdbdbd';
  }
  if (!props.status) {
    return '#bdbdbd';
  }
  return '#333333';
};

const getStatusFontWeight = (props) => {
  if (props.status === '') {
    return '100';
  }
  return '500';
};

export const dropdownStyles = {
  option: provided => ({
    ...provided,
    padding: 20
  }),
  placeholder: styles => ({ ...styles, color: "black" }),
  control: styles => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderStyle: "solid",
    borderColor: "#E4E9F0",
    borderWidth: 2,
    color: "black",
    cursor: "pointer",
    maxWidth: 518,
    height: 68,
    paddingLeft: 15
  }),
  indicatorSeparator: () => null,
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity };
  }
};

export const DragonContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 518px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const ProspectingContainer = styled.div`
  margin-top: 54px;
  h1 {
    text-align: center;
  }
`;

export const BackButton = styled.button`
  background-color: #8996A0;
  color: white;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  width: 100px;
  font-weight: 600;
  text-decoration: none;

  &:hover, &:focus {
    background-color: #626562;
  }
`;

export const SeeLeadsButton = styled(BackButton)`
  background-color: #4C76E0;
  width: 150px;
  margin: 20px auto;

  &:hover, &:focus {
    background-color: #2353cc;
  }
`;

export const IconButton = styled.button`
  display: flex
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 0 16px;
  position: relative;
  font-weight: 600;
  line-height: 1;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  height: ${props => props.height}px;
  background-color: #4C76E0;
  color: white;

  &:hover, &:focus {
    background-color: #2353cc;
  }

  &:active {
    background-color: #1a3e97;
  }
`;

export const CountInput = styled.div` 
  max-width: 100px;
`;

export const ProspectorSectionTitle = styled(SectionTitle)`
  color: #778692;
  div {
    padding: 20% 0;
  }
`;

export const ProspectingButton = styled(IconButton)`
  /* position: absolute;
  top: 15px;
  right: 105px; */
`;

export const ButtonSpinner = styled(Spinner)`
  margin-left: 12px;
`;

export const CloseButton = styled.span`
  color: red;
`;

export const ApiError = styled.span`
  color: red;
`;

export const SuccessCheckMark = styled.div`
  border: 2px solid #00BD94;
  border-radius: 50%;
  font-size: 12px;
  height: 40px;
  width: 40px;
  stroke-width: 2px;
  background-color: #00BD94;
  color: white;
  margin: auto;
  padding-top: 10px;
  margin-bottom: 20px;
`;

export const ProspectTrackerContainer = styled.div`
  position: fixed;
  margin-left: 50px;
  font-family: Avenir;
  background-color: #FAFAFB;
  padding: 34px;
  width: 300px;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    color: #bdbdbd;
    margin-bottom: 24px;
  }
  span {
    line-height: 2.25em;
    display: block;
    font-size: 12px;
  }
`;

export const ProspectSummariesContainer = styled.div`
  position: fixed;
  width: 300px;
  padding: 34px;
  margin-top: 390px;
  margin-left: 50px;
  background-color: #FAFAFB;
  h4 {
    margin: 0 0 15px 0;
    padding: 0;
    font-size: 14px;
    font-weight: 700;
  }
  p {
    font-size: 14px;
    color: #bdbdbd;
    margin-bottom: 24px;
  }
  span {
    line-height: 2.25em;
    display: block;
    font-size: 12px;
    span {
      display: inline;
      font-weight: bold;
    }
  }
`;

export const StatusMarker = styled.div`
  display: inline-block;
  color: ${props => getStatusColor(props)};
  vertical-align: middle;
`;

export const CompanyNameTracker = styled.div`
  display: inline-block;
  font-weight: ${props => getStatusFontWeight(props)};
  color: ${props => getStatusFontColor(props)};
  vertical-align: middle;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
`;

export const MainBody = styled.div`
  display: inline-block;
  width: 70%;
  h5 {
    color: #778692;
  }
`;

export const RightColumn = styled.div`
  position: absolute;
  display: inline-block;
  width: 30%;
`;

export const SubmitButton = styled(IconButton)`
  margin-top: 36px;
  margin-bottom: 14px;
  width: 100%;
  background-color: #90DDCF;
  &:hover, &:focus {
    background-color: #74afa4;
  }
  span {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }
  ${props => (props.disabled ?
    `opacity: .5;
     filter: grayscale(50%);
    ` : '')
}`;

export const ZoomInfoSelectDiv = styled.div`
  background-color: #F5F5F7;
  padding: 25px;
  display: flex;
  max-width: 518px;
  font-size: 14px;
  border-radius: 3px;

  .checkbox {
    margin: 18px 15px 0 0;
  }

  Checkbox {
    fill: green;
  }
`;

export const FilterRow = styled.p`
  color: orange;
`;

export const ToggleSpan = styled.span`
  display: block;
  width: 200px;
  margin: 50px 275px 50px 275px;
  text-align: center;
  color: #778692;
  font-weight: bolder;

  &:hover {
    cursor: pointer;
    transition: 500ms;
    color: black;
  }

  .dropdown {
    margin-left: 8px;
    width: 20px;
    height: 20px;
  }
`;
