import React, { Component } from "react";
import PropTypes from "prop-types";
import numeral from "numeral";
import _ from "lodash";
import { Modal, IconArrow } from "@podiumhq/podium-ui";
import InputRange from "react-input-range";
import "../../styles/global";
import * as icon from "../../images/icon";
import {
  ProductRow,
  ProductBox,
  Price,
  DisplaySlider,
  SliderRange
} from "./styles";
import { products } from "../../lib/toolData";

const DISABLED_WITH_BASIC = ["chat", "reporting"];
const INPUT_MIN = 99;
const INPUT_MAX = 1770;
const WEBCHAT_DEFAULT = "chat";
const tiers = {
  tier1: 99,
  tier2: 200,
  tier3: 344,
  tier4: 470,
  tier5: 672,
  tier6: 869,
  tier7: 995,
  tier8: 1264,
  tier9: 1521,
  tier10: 1770
};

const { bool, func } = PropTypes;

class ProductSection extends Component {
  static propTypes = {
    isPlatformBasic: bool.isRequired,
    removeFromTotalPrice: func.isRequired,
    addToTotalPrice: func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      webchatOpen: false,
      value: INPUT_MIN,
      addWebchat: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isPlatformBasic !== nextProps.isPlatformBasic &&
      nextProps.isPlatformBasic === true
    ) {
      this.makeDisabledByBasic();
    }
  }

  onSliderChange = value => {
    this.setState({ value });
  };

  /*
     getNumberOfHits takes current slider value (between 79 and 1770) and
     for each of 10 different ranges, returns an arbitrary value (hits).
  */
  getNumberOfHits = value => {
    if (value <= tiers.tier1) return 5000;
    if (value <= tiers.tier2) return 20000;
    if (value <= tiers.tier3) return 75000;
    if (value <= tiers.tier4) return 175000;
    if (value <= tiers.tier5) return 500000;
    if (value <= tiers.tier6) return 1000000;
    if (value <= tiers.tier7) return 1500000;
    if (value <= tiers.tier8) return 2000000;
    if (value <= tiers.tier9) return 2750000;
    if (value <= tiers.tier10) return 3500000;
    return value;
  };

  /*
mapChatPrice takes the current value of slider (between 79 - 1770).
values have ranges and each range returns a specific number, price,
which is that range's price.
*/
  mapChatPrice = value => {
    if (value <= tiers.tier1) return tiers.tier1;
    if (value <= tiers.tier2) return tiers.tier2;
    if (value <= tiers.tier3) return tiers.tier3;
    if (value <= tiers.tier4) return tiers.tier4;
    if (value <= tiers.tier5) return tiers.tier5;
    if (value <= tiers.tier6) return tiers.tier6;
    if (value <= tiers.tier7) return tiers.tier7;
    if (value <= tiers.tier8) return tiers.tier8;
    if (value <= tiers.tier9) return tiers.tier9;
    if (value <= tiers.tier10) return tiers.tier10;
    return value;
  };

  /*
    formatValues takes a value arg and formats it to a string; either a number
    or curency returning the formatted value
  */
  formatValues = (value, fn, isCurrency = false) => {
    if (isCurrency) return numeral(fn(value)).format("$0,0");
    const hitsPerVisit = fn(value);

    if (hitsPerVisit >= 1000000) {
      return numeral(hitsPerVisit).format("0.00a");
    }

    return numeral(hitsPerVisit).format("0,0");
  };

  createPriceItem = (product, chatPrice) => ({
    title: product.title,
    price: typeof chatPrice !== "undefined" ? chatPrice : product.price,
    type: "product"
  });

  makeDisabledByBasic = () => {
    const { selected } = this.state;
    const priceItemsToRemove = _.reduce(
      products,
      (acc, product) =>
        _.includes(DISABLED_WITH_BASIC, product.key)
          ? [...acc, this.createPriceItem(product)]
          : acc,
      []
    );

    this.setState({ selected: _.filter(selected, item => item === "reviews") });
    this.props.removeFromTotalPrice(priceItemsToRemove);
  };

  /*
    displayProductPrice takes a product arg and conditionally displays price/month or
    'Find your price' if product is chat. It also displays the actual price once a
    webchat product has been added in modal.
  */
  displayProductPrice = product => {
    const { key, price } = product;
    const { addWebchat, value } = this.state;
    const chatSelectedPrice =
      addWebchat === "addWebchat" ? (
        <div>
          <span>{numeral(this.mapChatPrice(value)).format("$0,0")}</span>
          <span className="duration">/month</span>
        </div>
      ) : (
        <div>
          <span className="findPrice">Calculate price </span> <IconArrow />
        </div>
      );
    return (
      <Price>
        {key === "chat" ? (
          chatSelectedPrice
        ) : (
          <div>
            <span>${price}</span>
            <span className="duration">/month</span>
          </div>
        )}
      </Price>
    );
  };

  handleWebchatModalExit = e => {
    const addWebchat = e.target.value;
    this.setState(
      {
        webchatOpen: !this.state.webchatOpen,
        addWebchat
      },
      () => this.handleWebchatModalAddPrice(this.state)
    );
  };

  handleWebchatModalAddPrice = currentValues => {
    const { value, addWebchat, selected } = currentValues;
    const chatPrice = this.mapChatPrice(value);
    const product = _.get(products, WEBCHAT_DEFAULT);
    const priceItem = this.createPriceItem(product, chatPrice);

    if (addWebchat !== "cancel") {
      this.setState({ selected: [...selected, product.key] });
      this.props.addToTotalPrice(priceItem);
    } else {
      this.setState({
        selected:
          _.filter(selected, item => item !== product.key) || product.key
      });
      this.props.removeFromTotalPrice([priceItem]);
    }
  };

  toggleProduct = product => {
    const isWebchat = product.key === "chat";
    const { isPlatformBasic } = this.props;
    const { selected } = this.state;

    const priceItem = this.createPriceItem(product);

    const disabledByBasic = isPlatformBasic && product.key !== "reviews";

    if (disabledByBasic) {
      this.makeDisabledByBasic();
    } else if (isWebchat) {
      this.setState({ webchatOpen: true });
    } else if (_.includes(selected, product.key)) {
      this.setState({
        selected:
          _.filter(selected, item => item !== product.key) || product.key
      });
      this.props.removeFromTotalPrice([priceItem]);
    } else {
      this.setState({ selected: [...selected, product.key] });
      this.props.addToTotalPrice(priceItem);
    }
  };

  render() {
    const { isPlatformBasic } = this.props;
    const { selected } = this.state;
    return (
      <div>
        <ProductRow>
          {_.map(products, (product, index) => (
            <ProductBox
              selected={_.includes(selected, product.key)}
              key={index}
              onClick={() => this.toggleProduct(product)}
              disabled={isPlatformBasic && product.key !== "reviews"}>
              <div className="rect">
                <img src={_.get(icon, product.key)} alt="product icon" />
                <div className="content">
                  <div className="title">{product.title}</div>
                  {this.displayProductPrice(product)}
                </div>
              </div>
              <div className="product-description">{product.desc}</div>
            </ProductBox>
          ))}
          <div className="modalContainer">
            <Modal disableClose noPadding tall isOpen={this.state.webchatOpen}>
              <DisplaySlider>
                <div>
                  <img src={icon.chat} alt="Webchat icon" />
                  <h2>Webchat Price Finder</h2>
                  <p>
                    Move slider to reflect the amount of hits your site gets
                    each months.
                  </p>
                  <SliderRange>
                    <form className="form">
                      <InputRange
                        maxValue={INPUT_MAX}
                        minValue={INPUT_MIN}
                        value={this.state.value}
                        onChange={value => this.onSliderChange(value)}
                        formatLabel={value =>
                          this.formatValues(value, this.mapChatPrice, true)
                        }
                      />
                      <div className="hits">
                        <div
                          style={{
                            left: `${((this.state.value - 1) /
                              (INPUT_MAX - INPUT_MIN)) *
                              100}%`,
                            transition: "left 0.3s ease-out"
                          }}
                          className="hitsContainer">
                          <span className="hitCount">
                            {this.formatValues(
                              this.state.value,
                              this.getNumberOfHits
                            )}
                          </span>
                          <span className="perMonth">Hits per month</span>
                        </div>
                      </div>
                    </form>
                    <div className="btnContainer">
                      <button
                        className="cancel"
                        value="cancel"
                        onClick={e => this.handleWebchatModalExit(e)}>
                        Remove Webchat
                      </button>
                      <button
                        className="addWebchat"
                        value="addWebchat"
                        onClick={e => this.handleWebchatModalExit(e)}>
                        Add Webchat
                      </button>
                    </div>
                  </SliderRange>
                </div>
              </DisplaySlider>
            </Modal>
          </div>
        </ProductRow>
      </div>
    );
  }
}

export default ProductSection;
