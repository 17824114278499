import styled from 'styled-components';
import { Input } from '@podiumhq/podium-ui';
import Spinner from '../../../components/Spinner/Spinner';

const getTagBackgroundColor = (props) => {
  if (props.status === 'disabled') {
    return 'darkgrey';
  }
  return '#4C76E0';
};

export const TheInputDiv = styled.div`
display: flex;
align-items: flex-start;
flex-wrap: wrap;
min-height: 48px;
width: 480px;
padding: 0 8px;
border: 1px solid rgb(214, 216, 218);
border-radius: 6px;
background-color: white;
&:focus-within {
  border: 1px solid #0052cc;
}
`;

export const Tag = styled.li`
width: auto;
height: 32px;
display: flex;
align-items: center;
justify-content: center;
color: #fff;
padding: 0 8px;
font-size: 14px;
list-style: none;
border-radius: 6px;
margin: 0 8px 8px 0;
background: ${props => getTagBackgroundColor(props)};

.tag-title {
  font-weight: 500;
}

.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #0052cc;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}
`;

export const TagList = styled.ul`
display: flex;
flex-wrap: wrap;
padding: 0;
margin: 8px 0 0 0;
`;

export const StrikezoneInfoRow = styled.div`
display: inline-flex;
justify-content: space-evenly;
width: 100%;
margin-bottom: 15px;

#text {
  padding-top: 9px;
  width: 230px;
  color: #778692;
  font-weight: 500;
}
`;

export const StrikezoneInput = styled.input`
  // width: 360px;
  // height: 40px;
  background-color: white;
  // border: 1px solid #EDEFF4;
  // margin-left: 0;
  // font-size: 12px;
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
  &:focus {
    outline: transparent;
  }
`;

export const StrikezoneDiv = styled.div`
  background-color: #FAFAFA;
  width: 80%;

  #rows {
    display: flex;
    flex-direction: column;
    margin-left: 25px;
  }

  #buttonDiv {
    display: flex;
    width: 45%;
    margin: auto;
  }
`;

export const StatusScreen = styled.div`
  background-color: #FAFAFA;
  text-align: center;
  padding: 100px 0 100px 0;

  #buttonDiv {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    margin: auto;
  }

  p {
    margin: 25px 0 50px 0;
    font-weight: 500;
    font-size: larger;
  }

  button {
    border-radius: 3px;
    border: none;
    outline: none;
    cursor: pointer;
    height: 40px;
    width: 145px;
    color: white;
    background-color: #4C76E0;
  }

`;

export const ExampleText = styled.p`
  margin: -10px 0 15px 290px;
  font-size: small;
  font-style: italic;
  color: #778692;
  max-width: 450px;
`;

export const StrikezoneButton = styled.button`
  height: 40px;
  width: 150px;
  justify-content: space-between;
  padding-left: 50px;
  margin: 5px auto 20px auto;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  position: relative;
  font-weight: 600;
  line-height: 1;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  background-color: #4C76E0;
  color: white;

  &:hover, &:focus {
    background-color: #2353cc;
  }

  &:active {
    background-color: #1a3e97;
  }
`;

export const PlaceTypesSearchDiv = styled.div`
  display: inline-flex;
  margin-bottom: 10px;
`;

export const GooglePlaceTypesDiv = styled.div`
  background-color: #FAFAFA;
  width: 400px;
  text-align: center;
`; 

export const GooglePlaceTypesInput = styled(Input)`
  width: 300px;
  height: 40px;
  background-color: white;
  border: 1px solid #EDEFF4;
  margin: 0 10px 0 0;
  font-size: 12px;
`;

export const GooglePlaceTypesButton = styled.button`
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  height: 40px;
  width: 90px;
  color: white
  background-color: #4C76E0;

  &:hover {
    background-color: #0052cc;
  }
`;

export const GooglePlaceTypesRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 25px 10px 25px;

  #key {
    display: flex;
  }
`;

export const LoadingSpinner = styled(Spinner)`

`;

export const SpinnerContainer = styled.div`
  padding-left: 160px;
`;
