import React, { Component } from 'react';
import _ from 'lodash';
import 'sanitize.css/sanitize.css';
import '../../styles/global';
import SetupSection from '../../components/SetupSection/SetupSection';
import PlatformSection from '../../components/PlatformSection/PlatformSection';
import ProductSection from '../../components/ProductSection/ProductSection';
import OptimizerSection from '../../components/OptimizerSection/OptimizerSection';
import SummarySection from '../../components/SummarySection/SummarySection';

import { BuildContainer, Section } from './styles';

const BUNDLE_DISCOUNT = 0.05;
const UPFRONT_DISCOUNT = 0.1;
const LOCATION_DISCOUNTS = {
  sm: 0.1,
  md: 0.16,
  lg: 0.3,
  xl: 0.6
};
const PRICE_PER_USER = 5;

const defaultPriceItems = [
  {
    title: 'Standard',
    price: 239,
    type: 'platform',
    users: 10
  }
];

class PriceBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationCount: 1,
      employeeCount: 1,
      priceItems: defaultPriceItems,
      discounts: [],
      optimizerEnabled: false,
      upFrontPaid: false
    };
  }

  onLocationsChange = locationCount => this.setState({ locationCount }, () => this.calcDiscounts());
  onOptimizePriceSelected = optimizerEnabled => this.setState({ optimizerEnabled });
  onUpfrontPayment = () => {
    this.setState({ upFrontPaid: !this.state.upFrontPaid }, () => this.calcDiscounts());
  };

  getTotalBeforeDiscounts = () => (
    _.reduce(this.state.priceItems, (acc, item) => {
      if (!_.isNumber(item.price)) return acc;
      return item.price + acc;
    }, 0)
  );

  getTotalAfterDiscounts = () => {
    const { discounts, priceItems } = this.state;
    // Remove additional user price from prev price, apply discounts, then add to discounted price
    let userTotalPrice = 0;
    _.each(priceItems, (item) => {
      if (item.type === 'employeeCount') userTotalPrice = item.price;
    });
    const prevTotal = _.isNumber(userTotalPrice) ?
      this.getTotalBeforeDiscounts() - userTotalPrice :
      this.getTotalBeforeDiscounts();
    const totalDiscountPercentageAmount = _.reduce(discounts, (acc, disc) =>
      (disc.amount + acc), 0);
    return _.isNumber(userTotalPrice) ?
      _.round((prevTotal - totalDiscountPercentageAmount) + userTotalPrice) :
      _.round(prevTotal - totalDiscountPercentageAmount);
  }

  getlocationsDiscount = (total) => {
    const { locationCount } = this.state;
    let selectedDiscount;

    if (locationCount > 4) selectedDiscount = LOCATION_DISCOUNTS.sm;
    if (locationCount > 9) selectedDiscount = LOCATION_DISCOUNTS.md;
    if (locationCount > 24) selectedDiscount = LOCATION_DISCOUNTS.lg;
    if (locationCount > 49) selectedDiscount = LOCATION_DISCOUNTS.xl;

    return {
      title: 'Multi-Location Discount',
      key: 'locationsDiscount',
      percentage: selectedDiscount,
      amount: _.round(total * selectedDiscount)
    };
  }

  currentPlatform = () => this.state.priceItems.find(item => item.type === 'platform');
  isPlatformBasic = () => _.get(this.currentPlatform(), 'title') === 'Basic';
  updateEmployees = (employeeCount, prevPlatform) => {
    const currentPlatform = this.currentPlatform();
    const platformUsers = currentPlatform.users;
    const extraEmployees = employeeCount > platformUsers ? employeeCount - platformUsers : 0;
    const price = extraEmployees * PRICE_PER_USER;

    const employeePriceItem = {
      type: 'employeeCount',
      title: employeeCount <= currentPlatform.users ? `Users (${employeeCount})` : `Additional Users (${employeeCount - currentPlatform.users})`,
      price
    };
    this.setState({ employeeCount }, () => {
      this.addToTotalPrice(employeePriceItem, prevPlatform);
    });
  }

  addToTotalPrice = (priceItem, prevPlatform) => {
    const { priceItems } = this.state;
    const currentPlatform = this.currentPlatform();
    let newPriceItems = [];
    if (priceItem.type === 'platform') {
      newPriceItems = _.filter(priceItems, item => item.type !== 'platform');
      newPriceItems.push(priceItem);
    } else if (priceItem.type === 'product') {
      newPriceItems = _.filter(priceItems, item => item.title !== priceItem.title);
      newPriceItems.push(priceItem);
    } else if (priceItem.type === 'employeeCount') {
      newPriceItems = _.filter(this.state.priceItems, item => item.type !== 'employeeCount');
      newPriceItems.push(priceItem);
    }

    this.setState({ priceItems: newPriceItems }, () => {
      this.calcDiscounts();
      // Trigger a user price item update if the platform changes
      // because the included users are different with each platform
      if (_.get(prevPlatform, 'title') !== currentPlatform.title) {
        this.updateEmployees(this.state.employeeCount, currentPlatform);
      }
    });
  }

  removeFromTotalPrice = (priceItemsToRemove) => {
    const { priceItems } = this.state;

    let newPriceItems = [];
    newPriceItems = _.differenceWith(
      priceItems,
      priceItemsToRemove,
      (one, two) => one.title === two.title
    );
    this.setState({ priceItems: newPriceItems }, () => this.calcDiscounts());
  }

  calcDiscounts = () => {
    const { priceItems, locationCount, upFrontPaid } = this.state;
    const discounts = [];
    const productCount = _.filter(priceItems, item => item.type === 'product').length;
    const total = this.getTotalBeforeDiscounts();

    const productsDiscountTotal = _.round(total * (BUNDLE_DISCOUNT));
    const locationsDiscountTotal = productCount >= 2 ? total - productsDiscountTotal : total;
    const locationDiscountAmount = _.get(this.getlocationsDiscount(locationsDiscountTotal), 'amount');
    const annualPaymentDiscount = locationCount > 4 ?
      _.round((locationsDiscountTotal - locationDiscountAmount) * UPFRONT_DISCOUNT) :
      _.round(locationsDiscountTotal * (UPFRONT_DISCOUNT));

    if (_.filter(priceItems, item => item.type === 'product').length > 1) {
      discounts.push({
        title: 'Product Bundle Discount',
        percentage: BUNDLE_DISCOUNT,
        amount: productsDiscountTotal,
        key: 'bundleDiscount'
      });
    }

    if (locationCount > 4) discounts.push(this.getlocationsDiscount(locationsDiscountTotal));

    if (upFrontPaid) {
      discounts.push({
        title: 'Annual Upfront Discount',
        percentage: UPFRONT_DISCOUNT,
        amount: annualPaymentDiscount,
        key: 'upfrontDiscount'
      });
    }

    this.setState({ discounts });
  }

  render() {
    const {
      priceItems,
      discounts,
      optimizerEnabled,
      upFrontPaid
    } = this.state;
    return (
      <BuildContainer>
        <Section>
          <div className="title-holder">
            <div className="number">01</div>
            <div className="title">Setup</div>
          </div>
          <div className="content-holder">
            <SetupSection
              addToTotalPrice={this.addToTotalPrice}
              onLocationsChange={this.onLocationsChange}
              updateEmployees={this.updateEmployees}
              currentPlatform={this.currentPlatform()}
            />
          </div>
        </Section>
        <Section>
          <div className="title-holder">
            <div className="number">02</div>
            <div className="title">Platform</div>
          </div>
          <div className="content-holder">
            <PlatformSection addToTotalPrice={this.addToTotalPrice} />
          </div>
        </Section>
        <Section>
          <div className="title-holder">
            <div className="number">03</div>
            <div className="title">Products</div>
          </div>
          <div className="content-holder">
            <ProductSection
              addToTotalPrice={this.addToTotalPrice}
              removeFromTotalPrice={this.removeFromTotalPrice}
              isPlatformBasic={this.isPlatformBasic()}
            />
          </div>
        </Section>
        <Section>
          <div className="title-holder">
            <div className="number">04</div>
            <div className="title">Integration</div>
          </div>
          <div className="content-holder">
            <OptimizerSection onOptimizePriceSelected={this.onOptimizePriceSelected} />
          </div>
        </Section>
        <Section>
          <div className="title-holder">
            <div className="number">05</div>
            <div className="title">Summary</div>
          </div>
          <div className="content-holder">
            <SummarySection
              priceItems={priceItems}
              discounts={discounts}
              optimizerEnabled={optimizerEnabled}
              onUpfrontPayment={this.onUpfrontPayment}
              totalBeforeDiscounts={this.getTotalBeforeDiscounts()}
              totalAfterDiscounts={this.getTotalAfterDiscounts()}
              isUpfrontPaid={upFrontPaid}
              showCreatePackage
            />
          </div>
        </Section>
      </BuildContainer>
    );
  }
}

export default PriceBuilder;
