import React, { Component } from 'react';
import { Checkbox } from '@podiumhq/podium-ui';
// import _ from 'lodash';
import Select from 'react-select';
import BarCharts from './BarCharts/BarCharts';
import QuarterCharts from './QuarterCharts/QuarterCharts';
import {
  CheckDiv,
  EngRecruitingContainer,
  FetchButton,
  LoadingSpinner,
  Section,
  SectionContents,
  RightSectionContents,
} from './styles';
import { getHealth } from './greenClient';
import { getGreenalyzerChannel } from './greenSocket';
import { getCurrentUser } from '../../lib/auth';

export default class EngRecruiting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // error: false,
      activeView: "quarter",
      adjustedData: this.removeUnknownsFromData(JSON.parse(localStorage.getItem('lastResults'))),
      graphOptions: [{label: "Quarter over Quarter", value: "quarter"}, {label: "Overall view", value: "overall"}],
      greenalyzerData: JSON.parse(localStorage.getItem('lastResults')),
      hideUnkown: false,
      loading: false,
    };
  }

  /* eslint-disable no-console, camelcase, no-param-reassign */
  componentDidMount() {
    const greenalyzerChannel = getGreenalyzerChannel();
    this.greenalyzerChannel = greenalyzerChannel;
    if (this.today() !== localStorage.getItem('updatedOn')) {
      localStorage.removeItem('lastResults');
      localStorage.removeItem('updatedOn');
    }

    greenalyzerChannel.onError(() => {
      if (!this.state.greenalyzerData) {
        this.setState({
          // error: true,
          loading: false,
        });
      };
    });

    getHealth().then(res => {
      console.log("health check", res);
    })

    greenalyzerChannel.on('fetch-eng-eeoc', (params) => {
      localStorage.setItem('lastResults', JSON.stringify(params))
      localStorage.setItem('updatedOn', this.today());
      console.log("response from channel", params);

      this.setState({
        adjustedData: this.removeUnknownsFromData(params),
        greenalyzerData: params,
        loading: false,
      })
    });
  }

  handleGraphChange = (option) => {
    this.setState({activeView: option.value})
  }

  today = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();

    return `${mm}/${dd}/${yyyy}`;
  }

  fetchEEOCData = () => {
    const { email } = getCurrentUser();
    this.setState({ loading: true })
    this.greenalyzerChannel.push('fetch-eng-eeoc', {
      user: email
    })
      .receive('error', (e) => console.log(e));
  }

  handleHideUnknown = () => {
    this.setState({
      hideUnkown: !this.state.hideUnkown,
    });
  };

  removeUnknownsFromData = (data) => {
    if (!data) {
      return null;
    }
    const adjustedData = 
      { 
        "Simplified": { 
          "Application Review": { 
            "non_diverse": data.Simplified["Application Review"].non_diverse, 
            "total_eeocs": data.Simplified["Application Review"].total_eeocs - data.Simplified["Application Review"].unknown, 
            "unknown": 0, 
            "urms": data.Simplified["Application Review"].urms, 
            "women": data.Simplified["Application Review"].women 
          }, 
          "Hired": { 
            "non_diverse": data.Simplified.Hired.non_diverse, 
            "total_eeocs": data.Simplified.Hired.total_eeocs - data.Simplified.Hired.unknown, 
            "unknown": 0, 
            "urms": data.Simplified.Hired.urms, 
            "women": data.Simplified.Hired.women 
          }, 
          "Offer": { 
            "non_diverse": data.Simplified.Offer.non_diverse, 
            "total_eeocs": data.Simplified.Offer.total_eeocs - data.Simplified.Offer.unknown, 
            "unknown": 0, 
            "urms": data.Simplified.Offer.urms, 
            "women": data.Simplified.Offer.women 
          }, 
          "Onsite Interview": { 
            "non_diverse": data.Simplified["Onsite Interview"].non_diverse, 
            "total_eeocs": data.Simplified["Onsite Interview"].total_eeocs - data.Simplified["Onsite Interview"].unknown, 
            "unknown": 0, 
            "urms": data.Simplified["Onsite Interview"].urms, 
            "women": data.Simplified["Onsite Interview"].women 
          }, 
          "Phone Interview": { 
            "non_diverse": data.Simplified["Phone Interview"].non_diverse, 
            "total_eeocs": data.Simplified["Phone Interview"].total_eeocs - data.Simplified["Phone Interview"].unknown, 
            "unknown": 0, 
            "urms": data.Simplified["Phone Interview"].urms, 
            "women": data.Simplified["Phone Interview"].women 
          } 
        }, 
        "Total Applications": data["Total Applications"] - data.Simplified["Application Review"].unknown
      }
    return adjustedData;
  }

  render() {
    const {
      activeView,
      adjustedData,
      graphOptions,
      greenalyzerData,
      hideUnkown,
      loading,
    } = this.state;

    return (

      <EngRecruitingContainer>
        {loading &&
          <LoadingSpinner width={50} height={50} color="#4B76E0" />
        }
        {!loading &&
          <Section>
            <RightSectionContents>
              <Select
                options={graphOptions}
                onChange={option => this.handleGraphChange(option)}
                placeholder="Quarter over Quarter"
              />
            </RightSectionContents>
          </Section>
        }
        {!loading && activeView === "quarter" &&
          <Section>
            <SectionContents>
              <QuarterCharts />
            </SectionContents>
          </Section>
        }
        {!loading && greenalyzerData && activeView === "overall" &&
          <Section>
            <SectionContents>
              { !hideUnkown &&
                <BarCharts total={greenalyzerData["Total Applications"]} data={greenalyzerData.Simplified} />
              }
              { hideUnkown &&
                <BarCharts total={adjustedData["Total Applications"]} data={adjustedData.Simplified} />
              }
              <br />
              <CheckDiv>
                <Checkbox
                  checked={hideUnkown}
                  onChange={this.handleHideUnknown}
                />
              </CheckDiv>
              <CheckDiv>
                Remove unknown responses and adjust % for given answers.
              </CheckDiv>
            </SectionContents>
          </Section>
        }
        {!loading && !greenalyzerData && activeView === "overall" &&
          <Section>
            <FetchButton onClick={this.fetchEEOCData}>Fetch EEOC</FetchButton>
          </Section>
        }
      </EngRecruitingContainer>
    );
  }
}
