/* eslint react/jsx-filename-extension: 0 */
/* global document */

import React, { Component } from "react";
import _ from "lodash";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { getCurrentUser } from "./lib/auth";
import analytics from "./lib/analytics";
import Header from "./screens/Header/Header";
import withTracker from "./components/analytics/analytics";
import Login from "./screens/Login/Login";
// eslint-disable-next-line
import PriceBuilder from "./screens/PriceBuilder/PriceBuilder";
import LeadScoring from "./screens/LeadScoring/LeadScoring";
import Prospector from "./screens/Prospector/Prospector";
import PrivateGui from "./screens/PrivateGui/PrivateGui";
import Integrations from "./screens/Integrations/Integrations";
import Products from "./screens/Products/Products";
import NearbyCustomers from "./screens/NearbyCustomers/NearbyCustomers";
import "./index.css";
import { getAdmins, getUsers } from "./lib/client";
import { getGreenUsers } from "./screens/EngRecruiting/greenClient";
import NotFound from "./components/NotFound/NotFound";
import EngRecruiting from "./screens/EngRecruiting/EngRecruiting";

class Root extends Component {
  constructor(props) {
    super(props);
    const currentUser = getCurrentUser();

    if (!_.isNull(currentUser)) {
      analytics.initialize({ userId: currentUser.uid, ...currentUser });
      analytics.setProp({ userId: currentUser.uid, ...currentUser });
    }

    this.state = {
      loggedIn: !!currentUser || false,
      user: currentUser || {},
      showEngRecruiting: false,
      showPrivateGui: false,
      showProspector: false,
    };
  }

  componentWillMount() {
    this.showProspector(this.state.user);
    this.showPrivateGui(this.state.user);
    this.showEngRecruiting(this.state.user);
  }

  fetchUsers = async () => getUsers();

  fetchGreenUsers = async (email) => getGreenUsers(email);

  fetchAdmins = async () => getAdmins();

  showProspector = async (user) => {
    const validUsers = await this.fetchUsers();
    const validUserEmails = validUsers.map((u) => u.email);
    this.setState({ showProspector: _.includes(validUserEmails, user.email) });
  };

  showPrivateGui = async (user) => {
    const admins = await this.fetchAdmins();
    const adminEmails = admins.map((a) => a.email);
    this.setState({ showPrivateGui: _.includes(adminEmails, user.email) });
  };

  showEngRecruiting = async (user) => {
    const isGreenUser = await this.fetchGreenUsers(user.email);

    this.setState({ showEngRecruiting: isGreenUser === "Green User"});
  };

  handleUserAuthenticated = (user) => {
    this.setState({
      user,
      loggedIn: true,
    });
  };

  handleLogout = () => {
    this.setState({
      user: {},
      loggedIn: false,
    });
  };

  render() {
    const { loggedIn, showEngRecruiting, showPrivateGui, showProspector, user } = this.state;

    return (
      <Router>
        <div>
          {loggedIn && <Header user={user} onLogout={this.handleLogout} />}
          {loggedIn ? (
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to="/upload-tool" />}
              />
              <Route
                exact
                path="/login"
                render={() => <Redirect to="/upload-tool" />}
              />
              <Route
                exact
                path="/upload-tool"
                component={withTracker(LeadScoring)}
              />
              <Route
                exact
                path="/upload-tool"
                component={withTracker(LeadScoring)}
              />
              <Route
                exact
                path="/nearby-customers"
                component={withTracker(NearbyCustomers)}
              />
              <Route
                exact
                path="/integrations"
                component={withTracker(Integrations)}
              />
              <Route
                exact
                path="/integrations/:vertical"
                component={withTracker(Integrations)}
              />
              <Route
                exact
                path="/product-roadmap"
                component={withTracker(Products)}
              />
              {showPrivateGui && (
                <Route
                  exact
                  path="/PrivateGui"
                  component={withTracker(PrivateGui)}
                />
              )}
              {showProspector && (
                <Route
                  exact
                  path="/prospector"
                  component={withTracker(Prospector)}
                />
              )}
              {showEngRecruiting && (
                <Route
                  exact
                  path="/eng-recruiting"
                  component={withTracker(EngRecruiting)}
                />
              )}
              <Route path="/*" component={withTracker(NotFound)} />
            </Switch>
          ) : (
            <Route
              path="/*"
              component={(props) => (
                <Login
                  {...props}
                  onUserAuthenticated={this.handleUserAuthenticated}
                />
              )}
            />
          )}
        </div>
      </Router>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
