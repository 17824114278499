import styled from 'styled-components';

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;

  input {
    margin-right: 16px;
    font-weight: 700;
    padding: 0 0.3rem;
    text-align: center;
  }

  div {
    font-weight: 500;
  }
`;
export default Row;
