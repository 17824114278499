import { Socket } from 'phoenix-socket';
import { getCurrentUser } from './auth';

const GIMLI_WS_HOST = process.env.REACT_APP_GIMLI_WS_HOST;

const { email } = getCurrentUser() || '';

const socket = new Socket(`${GIMLI_WS_HOST}/socket`, {
  params: { token: email },
  logger: (kind, msg, data) => { console.log(`${kind}: ${msg}`, data); }
});

let prospectorChannel = null;

export const getProspectorChannel = () => {
  if(!prospectorChannel) {
    socket.connect();
    prospectorChannel = socket.channel(
      `prospector:${email}-${Math.random()}`
    );
    prospectorChannel
      .join()
      .receive('ok', (resp) =>
        console.log('joined the prospector channel', resp)
      )
      .receive('error', (reason) => console.log('join failed', reason));
  };
  return prospectorChannel;
};

// Second Socket, so we can sent messages to administer to a currently-running search

const adminSocket = new Socket(`${GIMLI_WS_HOST}/socket`, {
  params: { token: email },
  logger: (kind, msg, data) => { console.log(`${kind}: ${msg}`, data); }
});

let prospectorAdminChannel = null;

export const getProspectorAdminChannel = () => {
  if(!prospectorAdminChannel) {
    adminSocket.connect();
    prospectorAdminChannel = adminSocket.channel(
      `prospector:${email}-${Math.random()}`
    );
    prospectorAdminChannel
      .join()
      .receive('ok', (resp) =>
        console.log('joined the prospector channel', resp)
      )
      .receive('error', (reason) => console.log('join failed', reason));
  };
  return prospectorAdminChannel;
};

export default socket;
