import React from 'react';
import socket from '../../lib/socket';
import UserTable from './UserTable/UserTable';
import AddNewUser from './AddNewUser/AddNewUser';
import AddStrikezone from './Strikezones/AddStrikezone';
import UpdateStrikezone from './Strikezones/UpdateStrikezone';
import GooglePlaceTypesSearch from './Strikezones/GooglePlaceTypesSearch';
import {
  Divider,
  Section,
  SectionContents,
  SectionTitle
} from '../Packaged/styles';
import { addNewUser } from '../../lib/client';
import { getCurrentUser } from '../../lib/auth';
import {
  CancelButton,
  ProspectingContainer,
  StrikezoneButtonDiv
} from './styles';
import { isAdminOpts, roleDropdownOpts } from './optionData';

const DEFAULT_ADMIN_OPTION = false;

const adminEmails = [
  "stan.bakalov@podium.com",
  "wilson.parrish@podium.com",
  "justin.benfit@podium.com",
  "dave.buchanan@podium.com",
  "cory.thorson@podium.com"
];

const currentUser = getCurrentUser();

export default class PrivateGui extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addingStrikezone: false,
      editingStrikezone: false,
      isAdminOptions: isAdminOpts,
      isAdminSelected: DEFAULT_ADMIN_OPTION,
      roleOptions: roleDropdownOpts,
      roleSelected: 'ADM',
      uploadedNewUser: false
    };
  }

  componentDidMount() {
    socket.connect();

    const { email } = getCurrentUser();
    const prospectorChannel = socket.channel(`prospector:${email}`);

    prospectorChannel
      .join()
      .receive('ok', resp => console.log('joined the prospector channel', resp))
      .receive('error', reason => console.log('join failed', reason));

    this.socket = socket;
    this.prospectorChannel = prospectorChannel;
  }

  // Handles
  handleFirstNameChange = name =>
    this.setState({
      firstName: name
    });

  handleLastNameChange = name =>
    this.setState({
      lastName: name
    });

  /* eslint-disable object-shorthand */
  handleEmailChange = email =>
    this.setState({
      email: email
    });

  handleRoleChange = role =>
    this.setState({
      roleSelected: role
    });

  handleIsAdminChange = option => {
    this.setState({
      isAdminSelected: option
    });
    console.log('ADMIN', option);
  };

  handleSubmitNewUser = async () => {
    const {
      firstName,
      lastName,
      email,
      roleSelected,
      isAdminSelected
    } = this.state;
    const user = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      role: roleSelected.value,
      isAdmin: isAdminSelected.value
    };

    const newUser = await addNewUser(user);
    console.log('this is the new user', newUser);
    this.setState({ uploadedNewUser: true });
  };

  handleSubmitAnotherUser = () =>
    this.setState({
      uploadedNewUser: false
    });

  handleEditStrikezone = () =>
    this.setState({
      editingStrikezone: !this.state.editingStrikezone
    })

  handleAddStrikezone = () =>
    this.setState({
      addingStrikezone: !this.state.addingStrikezone
    })

  handleBackButtonClick = () => 
    this.setState({
      addingStrikezone: false,
      editingStrikezone: false
    })

  render() {
    const {
      addingStrikezone,
      editingStrikezone,
      email,
      firstName,
      isAdminOptions,
      isAdminSelected,
      lastName,
      roleOptions,
      roleSelected,
      uploadedNewUser
    } = this.state;

    return (
      <ProspectingContainer>
        <h4>Admin Page</h4>
        <Divider />
        {adminEmails.includes(currentUser.email) &&
          <React.Fragment>
            <Section>
              <SectionTitle>
                <div>STRIKEZONES</div>
                <div>Add/Edit a Strikezone</div>
              </SectionTitle>
              <SectionContents>
                {editingStrikezone === false && addingStrikezone === false &&
                  <StrikezoneButtonDiv>
                    <button id="addStrikezone" onClick={this.handleAddStrikezone}>
                      Add Strikezone
                    </button>
                    <button id="editStrikezone" onClick={this.handleEditStrikezone}>
                      Edit Strikezone
                    </button>
                  </StrikezoneButtonDiv>
                }
                <React.Fragment>
                  {editingStrikezone &&
                    <UpdateStrikezone />
                  }
                  {addingStrikezone &&
                    <AddStrikezone />
                  }
                  {(editingStrikezone === true || addingStrikezone === true) &&
                    <CancelButton onClick={this.handleBackButtonClick}>
                      Cancel
                    </CancelButton>
                  }
                </React.Fragment>
              </SectionContents>
            </Section>
            <Divider />
            <Section>
              <SectionTitle>
                <div>GOOGLE PLACE TYPES</div>
                <div>Search for Google Place Types</div>
              </SectionTitle>
              <SectionContents>
                <GooglePlaceTypesSearch />
              </SectionContents>
            </Section>
            <Divider />
          </React.Fragment>
        }
        <AddNewUser 
          email={email}
          firstName={firstName}
          isAdminOptions={isAdminOptions}
          isAdminSelected={isAdminSelected}
          lastName={lastName}
          roleOptions={roleOptions}
          roleSelected={roleSelected}
          uploadedNewUser={uploadedNewUser}
          handleFirstNameChange={this.handleFirstNameChange}
          handleLastNameChange={this.handleLastNameChange}
          handleEmailChange={this.handleEmailChange}
          handleRoleChange={this.handleRoleChange}
          handleIsAdminChange={this.handleIsAdminChange}
          handleSubmitNewUser={this.handleSubmitNewUser}
          handleSubmitAnotherUser={this.handleSubmitAnotherUser}
        />
        <React.Fragment>
          <Divider />
          <SectionTitle>
            <div>USERS</div>
            <div>Edit/Delete/View Users</div>
          </SectionTitle>
          <Section>
            <SectionContents>
              <UserTable />
            </SectionContents>
          </Section>
        </React.Fragment>
      </ProspectingContainer>
    );
  }
}
