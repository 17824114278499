import ReactGA from 'react-ga';

const isProduction = () => (process.env.REACT_APP_ENVIRONMENT === 'production');
const initialize = (user) => {
  if (isProduction()) {
    ReactGA.initialize('UA-69466178-8', {
      gaOptions: {
        ...user
      }
    });
  }
};

const setProp = (options) => {
  ReactGA.set({
    ...options
  });
};

const getPageView = page => ReactGA.pageview(page);

const fireRouteTracking = () => {
  if (isProduction()) {
    ReactGA.pageview(window.location.pathname);
  }
};

const fireEvent = (payload) => {
  if (isProduction()) {
    ReactGA.event({ ...payload });
  }
};

export default {
  initialize,
  fireRouteTracking,
  fireEvent,
  setProp,
  getPageView
};
