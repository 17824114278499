/* global localStorage, window */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Logo, StatusIcon, ButtonGoogle } from '@podiumhq/podium-ui';
import { firebaseAuth } from '../../lib/firebase-config';
import {
  login,
  setCurrentUser,
  getIsAuthenticating,
  setIsAuthenticating,
  unsetIsAuthenticating
} from '../../lib/auth';
import * as icon from '../../images/icon';
import LoginContainer from './styles';

const { func } = PropTypes;
const PODIUM_DOMAINS = ['podium.com', 'podium.co'];

class Login extends Component {
  static propTypes = {
    onUserAuthenticated: func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      hasPodiumEmail: true
    };
  }

  componentDidMount() {
    const { onUserAuthenticated } = this.props;

    firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        unsetIsAuthenticating();
        const userEmail = user.email;
        const domain = this.userEmailDomain(userEmail);
        if (_.includes(PODIUM_DOMAINS, domain)) {
          setCurrentUser(user);
          onUserAuthenticated(user);
        } else {
          this.setState({ hasPodiumEmail: false });
        }
      }
    });
  }

  userEmailDomain = (email) => {
    const [, domain] = email.split('@');
    return domain;
  };

  showSpinner = () => (
    <div className="status-icon">
      <StatusIcon />
    </div>
  );

  handleLogin = () => {
    setIsAuthenticating();
    login();
  }

  render() {
    const authenticating = getIsAuthenticating();
    const { hasPodiumEmail } = this.state;
    const promptText = authenticating ? 'Logging in...' : 'Log in using your Podium email and password';
    const notLoggedInMessage = 'Whoops! Only Podium employees are allowed to access this application. Try logging in again with a podium.com email address.';
    return (
      <LoginContainer>
        <div className="loginContainer">
          <div className="logo">
            <Logo hideText />
          </div>
          <div className="loginMessage">
            <span>Podium Sales Tools</span>
            <p>{promptText}</p>
          </div>
          {authenticating ? (
            <div>
              {this.showSpinner()}
            </div>
          ) : (
            <div className="google-login">
              <ButtonGoogle onClick={() => this.handleLogin()}>Sign in with Google</ButtonGoogle>
              {!hasPodiumEmail && (
                <div className="loginError">
                  <img src={icon.loginError} alt="Login error icon" />
                  <div>{notLoggedInMessage}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </LoginContainer>
    );
  }
}

export default Login;
