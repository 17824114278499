import styled from 'styled-components';

const LoginContainer = styled.div`
min-height: 100vh;
width: 100%;
margin: 0 auto;
display: flex;
align-items: center;
.loginContainer {
    min-height: 627px;
    width: 617px;
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 3px 8px 3px rgba(0,0,0,0.08);
    margin: 0 auto;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .loginMessage {
      color: #333333;
      text-align: center;
      span {
        font-size: 28px;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        margin: 16px auto;
        opacity: 0.8;
      }
    }

    .entryMessage {
      font-size: 16px;
    }
    .logo {
      height: auto;
      max-width: 78px;
      padding: 16px;
      width: 100%;
      margin-bottom: 74px;
    }
    .google-login {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        div {
          font-size: 12px;
          text-align: center;
          color: #E66A5C;
          max-width: 480px;

          margin-bottom: 16px;
        }
        .loginError {
          margin: 66px auto 18px;
          display: flex;
          justify-content: left;
          align-content: space-between
          min-width: 366px;
          flex-wrap: row wrap;
          div {
            width: 100%;
            text-align: left;
            padding-left: 14px;
          }
          img {
            width: 37px;
            height: 37px;
          }
        }
        button {
          cursor: pointer;
          span {
            font-weight: 600;
            opacity: 0.7;
          }
        }
    }
    .status-icon {
        width: 100px;
        height: 100px;
        margin-bottom: 16px;
    }
    .hide {
      display: none;
    }
}
`;
export default LoginContainer;
