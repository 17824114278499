import styled from 'styled-components';
import slidercss from 'react-input-range/lib/css/index.css';
import colors from '../../styles/colors';

export const ProductBox = styled.div`
  align-items: center;

  display: flex;
  flex-direction: column;
  width: 260px;
  margin: 0 32px 0 0;
  border-radius: 4px;
  transistion: all .2s ease-in-out;

  ${props => (props.disabled ?
    `opacity: .75;
     filter: grayscale(80%);
    ` : '')
}

  .rect {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;

    height: 153px;
    width: 260px;
    padding: 16px;
    border-radius: 4px;
    transistion: all .2s ease-in-out;

    img {
      margin-bottom: 8px;
    }

    ${props => (props.selected ? `
    background-color: ${colors.white};
    border: 2px solid ${colors.bearLake};
    ` : `
      background-color: ${colors.whiteSmoke};
      border: 2px solid ${colors.whiteSmoke};
    `)}
  }

  .product-description {
    font-size: 12px;
    text-align: center;
    padding: 16px 0 0;
    color: ${colors.mineShaft};
    width: 260px;
  }

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: ${colors.mineShaft};
  }

  .content {
    margin-top: 4px;
    text-align: center;
    .title {
      font-weight: 500;
      padding-bottom: 16px;
    }
  }
`;

export const ProductRow = styled.div`
  display: flex;

  .feature-holder {
    margin-left: 48px;
  }
`;

export const Price = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: ${colors.mineShaft};

  .findPrice {
    color: ${colors.bearLake};
    font-size: 14px;
    padding-right: 6px;
  }
  .duration {
    font-weight: normal;
    font-size: 15px;
  }
`;

export const DisplaySlider = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  width: 560px;
  img {
    padding: 25px 0 10px;
  }
  h2 {
    font-size: 16px;
    font-weight: 500;
  }
  p {
    color: ${colors.mineShaft};
    font-size: 12px;
  }
`;

export const SliderRange = styled.div`
.form {
  margin: 0 auto;
  padding: 90px 5px 0;
  width: 450px;
}

.hits {
  position: relative;
  min-height: 85px;
  .hitsContainer {
      display: flex;
      flex-flow: column wrap;
      width: 110px;
      text-align: center;
      position: absolute;
      margin-left: -70px;
      margin-right: -70x;
      .hitCount {
        color: ${colors.mineShaft};
        font-size: 14px;
        font-weight: 500;
      }
      .perMonth {
        color: #878787;
        font-size: 12px;
      }
  }
}

.input-range {
    margin-bottom: 10px;
  .input-range__label--min,
  .input-range__label--max {
      display: none;
    }
    .input-range__track {
      background: rgba(189,195,201,0.72);
      height: 2px;
  }

  .input-range__slider {
      background: ${colors.mineShaft};
      border: 1px solid ${colors.mineShaft};
    }
  span.input-range__label.input-range__label--value span {
      background: ${colors.mineShaft};
      color: #fff;
      font-size: 19px;
      padding: 10px;
      top: -40px;
      border-radius: 3px;
      min-width: 66px;
      display: inline-block;
      text-align: center;
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        position: absolute;
        color: red;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 37px;
        background: ${colors.mineShaft};
        border-radius: 2px;
        transform: rotate(45deg);
      }
  }
}

.btnContainer {
  text-align: right;
  padding: 25px;
  border-top: 1px solid rgba(204,204,204,0.72);
  color: #778691;
  font-size: 12px;
  font-weight: 500;
  button {
    transition: .15s;
    padding: 5px 10px;
    &:focus {
      outline: none;
    }
    &:active {
      opacity: 0.7;
    }
  }
  .cancel {
    border: none;
    margin-right: 15px;
  }
  .addWebchat {
    border: 2px solid #BDC3C9;
    border-radius: 3px;
    &:active {
      border: 2px solid #BDC3C9;
    }
  }
  }
}

`;

export default slidercss;
