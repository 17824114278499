import styled from 'styled-components';
import colors from '../../styles/colors';

export const BuildContainer = styled.div`
  margin: 0 auto;
`;

export const Section = styled.div`
  display: flex;
  margin: 48px 0 96px 0;

  .title-holder {
    width: 150px;
    margin-right: 48px;

    .number {
      font-weight: 600;
      font-size: 21px;
    }

    .title {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .content-holder {
    width: 100%
  }
`;

export const Footer = styled.div`
  min-height: 75px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  background-color: ${colors.whiteSmoke};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-weight: 600;
`;
