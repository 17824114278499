/* eslint-disable dot-notation */
import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip} from 'recharts'; // Line, LineChart } from 'recharts';

// eslint-disable-next-line react/prefer-stateless-function
export default class StageChart extends Component {

  findApplicableTotal = (stage) => {
    switch(stage) {
      case "Hired":
        return "total_hired"
      case "Offer":
        return "total_offer"
      case "Onsite Interview":
        return "total_onsite"
      case "Phone Interview":
        return "total_phone"
      default:
        return "total_eeocs"
    }
  }

  renderTooltip = (value) => {
    return `${value}%`; 
  }

  render() {
    /* eslint-disable camelcase */
    /* eslint-disable react/jsx-curly-brace-presence */
    /* eslint-disable prefer-template */
    const {
      data,
      stage
    } = this.props;

    const organizedData = [
      {
        "quarter": (stage === "Hired" ? "Q1 (" + data['q1'].total_hired + " total)": "Q1"),
        "total": data["q1"].total_eeocs,
        "Women": Math.floor(100 * (data["q1"].women[stage] / data["q1"][this.findApplicableTotal(stage)])) || 0,
        "URMs": Math.floor(100 * ( data["q1"].urms[stage] / data["q1"][this.findApplicableTotal(stage)])) || 0,
        "Non-Diverse": Math.floor(100 * (data["q1"].nondiverse[stage] / data["q1"][this.findApplicableTotal(stage)])) || 0,
        "Unknown": Math.floor(100 * (data["q1"].unknown[stage] / data["q1"][this.findApplicableTotal(stage)])) || 0
      },
      {
        "quarter": (stage === "Hired" ? "Q2 (" + data['q2'].total_hired + " total)": "Q2"),
        "total": data["q2"].total_eeocs,
        "Women": Math.floor(100 * (data["q2"].women[stage] / data["q2"][this.findApplicableTotal(stage)])) || 0,
        "URMs": Math.floor(100 * ( data["q2"].urms[stage] / data["q2"][this.findApplicableTotal(stage)])) || 0,
        "Non-Diverse": Math.floor(100 * (data["q2"].nondiverse[stage] / data["q2"][this.findApplicableTotal(stage)])) || 0,
        "Unknown": Math.floor(100 * (data["q2"].unknown[stage] / data["q2"][this.findApplicableTotal(stage)])) || 0
      },
      {
        "quarter": (stage === "Hired" ? "Q3 (" + data['q3'].total_hired + " total)": "Q3"),
        "total": data["q3"].total_eeocs,
        "Women": Math.floor(100 * (data["q3"].women[stage] / data["q3"][this.findApplicableTotal(stage)])) || 0,
        "URMs": Math.floor(100 * ( data["q3"].urms[stage] / data["q3"][this.findApplicableTotal(stage)])) || 0,
        "Non-Diverse": Math.floor(100 * (data["q3"].nondiverse[stage] / data["q3"][this.findApplicableTotal(stage)])) || 0,
        "Unknown": Math.floor(100 * (data["q3"].unknown[stage] / data["q3"][this.findApplicableTotal(stage)])) || 0
      },
      {
        "quarter": (stage === "Hired" ? "Q4 (" + data['q4'].total_hired + " total)": "Q4"),
        "total": data["q4"].total_eeocs,
        "Women": Math.floor(100 * (data["q4"].women[stage] / data["q4"][this.findApplicableTotal(stage)])) || 0,
        "URMs": Math.floor(100 * ( data["q4"].urms[stage] / data["q4"][this.findApplicableTotal(stage)])) || 0,
        "Non-Diverse": Math.floor(100 * (data["q4"].nondiverse[stage] / data["q4"][this.findApplicableTotal(stage)])) || 0,
        "Unknown": Math.floor(100 * (data["q4"].unknown[stage] / data["q4"][this.findApplicableTotal(stage)])) || 0
      }
    ];

    return (
      <React.Fragment>
        <h3>{stage}</h3>
        <BarChart width={500} height={280} data={organizedData} layout='horizontal'>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type='category' dataKey='quarter' width={130} />
          <YAxis type='number' domain={[0, 100]} />
          <Tooltip formatter={this.renderTooltip} />
          {stage === "Hired" &&
            <Legend />
          }
          <Bar dataKey="Women" fill="#8884d8" />
          <Bar dataKey="URMs" fill="#82ca9d" />
          <Bar dataKey="Non-Diverse" fill="#db8686" />
          <Bar dataKey="Unknown" fill="#696565" />
        </BarChart>
      </React.Fragment>
    );
  }
}
