import { getCurrentUser } from "./auth"; 

const GIMLI_API_HOST = process.env.REACT_APP_GIMLI_API_HOST;
const currUser = getCurrentUser();

const getJwt = (userObj) => userObj.stsTokenManager.accessToken;

const get = async (endpoint) => {
  try {
    const response = await fetch(`${GIMLI_API_HOST}/api${endpoint}`, {
      method: 'GET',
      headers: {
        Authorization: getJwt(currUser),
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  } catch (err) {
    return err;
  }
};

const getCsv = async (endpoint, filename) => {
  try {
    const response = await fetch(`${GIMLI_API_HOST}/api${endpoint}`, {
      method: 'GET',
      headers: {
        Authorization: getJwt(currUser),
        'Content-Type': 'text/csv'
      }
    });
    if (!response.error) {
      response.blob().then((blob) => {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' });
        a.download = `${filename}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    }
    return response;
  } catch (err) {
    return err;
  }
};

const post = async (endpoint, payload) => {
  try {
    const response = await fetch(`${GIMLI_API_HOST}/api${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: getJwt(currUser),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    return response.json();
  } catch (err) {
    return err;
  }
};

export const addNewUser = async (user) => {
  const response = await post('/add-user', user);
  return response;
};

export const updateUser = async (user) => {
  try {
    const response = await post('/update-user', user);
    return response;
  } catch (err) {
    return err;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await post('/delete-user', { id });
    return response;
  } catch (err) {
    return err;
  }
};

export const getUsers = async () => {
  return get('/fetch-users');
};

export const getAdmins = async () => {
  return get('/fetch-admins');
};

export const fetchSalesforceDuplicates = async (placeId) => {
  const response = await post('/dedupe', { place_id: placeId });
  return response;
};

export const fetchLeadScore = async (placeId, sessionToken, vertical) => {
  const response = await post('/score', { placeId, vertical, sessionToken });
  return response;
};

export const createSalesforceLead = async (payload) => {
  const response = await post('/create-lead', { ...payload });
  return response;
};

export const strikezoneByName = async (name) => {
  try {
    const response = await get(`/fetch-strikezone-by-name?name=${name}`);
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const updateStrikezone = async (payload) => {
  try {
    const response = await post('/update-strikezone', payload);
    return response;
  } catch (err) {
    return err;
  }
}

export const createStrikezone = async (payload) => {
  try {
    const response = await post('/construct-strikezone', payload)
    return response;
  } catch (err) {
    return err;
  }
};

export const getGooglePlaceTypes = async (placesSearchString) => {
  try {
    const response = await get(`/get-place-types?search_string=${placesSearchString}`);
    return response;
  } catch (err) {
    return err;
  }
}

// localhost:4241/api/prospects?sub_vertical=Furniture&state=California&count=1
export const pullProspects = async (strikezone, state, count) => {
  try {
    const response = await get(
      `/prospects?strikezone=${strikezone}&state=${state}&count=${count}`
    );
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const pullProspectsCsv = async (strikezone, state, count, finish) => {
  const today = new Date(Date.now());
  const dateTag = `${today.getMonth() + 1}-${today.getDate()}`;
  try {
    const response = await getCsv(
      `/prospects?strikezone=${strikezone}&state=${state}&count=${count}&export=csv&finish_list=${finish}`,
      `${dateTag}_${strikezone}_${state}_leads`
    );
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const findNearbyCustomers = async (prospect) => {
  try {
    const response = await post('/find-nearby', prospect);
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const resift = async (strikezone, state) => {
  try {
    const response = await get(
      `/prospects/resift?strikezone=${strikezone}&state=${state}`
    );
    return response.message;
  } catch (err) {
    return { error: err };
  }
};

export const countHot = async (strikezone, state) => {
  try {
    const count = await get(
      `/prospects/counthot?strikezone=${strikezone}&state=${state}`
    );
    return count.message;
  } catch (err) {
    return { error: err };
  }
};

export const updateProspect = async (prospect) => {
  try {
    const response = await post('/update-prospect', prospect);
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const createBatch = async (prospects, email) => {
  try {
    const response = await post('/create-batch', { prospects, email });
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const bulkUpdateProspects = async (prospects) => {
  try {
    const response = await post('/upload-prospects', prospects);
    return response;
  } catch (err) {
    return { error: err };
  }
};

export const getDropDowns = async (endpoint) => {
  return get(endpoint);
};

export const getProspectSummaries = async (strikezone, state) => {
  return get(`/prospects/summaries?strikezone=${strikezone}&state=${state}`);
};
