import styled from 'styled-components';
import { Input } from '@podiumhq/podium-ui';

const acceptButtonActiveColor = (props) => {
  if (props.selected === 'yes') {
    return 'background-color: #00BD94; opacity: 100%; color: white;';
  }
  return null;
};

const rejectButtonActiveColor = (props) => {
  if (props.selected === 'no') {
    return 'background-color: #E73E51; opacity: 100%; color: white;';
  }
  return null;
};

const inputFieldBorderColor = (props) => {
  if (props.status !== '') {
    return 'border: 1px solid #E73E51; background-color: rgba(231, 62, 82, .1)';
  }
  return null;
};

// const nearbyCustomerBorderColor = (props) => {
//   if (props.status >= 1) {
//     return 'border: 1px solid #22d85d';
//   } else if (props.status >= 0 && props.status <= 1) {
//     return 'border: 1px solid #ffc636';
//   } else if (props.status < 0) {
//     return 'border: 1px solid #e66a5c';
//   } else {
//     return null;
//   };
// };

export const dropdownStyles = {
  option: provided => ({
    ...provided,
    padding: 15
  }),
  placeholder: styles => ({ ...styles, color: "black" }),
  control: styles => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderStyle: "solid",
    borderColor: "#E4E9F0",
    borderWidth: 2,
    color: "black",
    cursor: "pointer",
    width: 380,
    height: 40
  }),
  indicatorSeparator: () => null,
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity };
  }
};

export const AcceptButtonDiv = styled.div`
  color: #00BD94;
  text-align: center;
`;

export const BottomRow = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ButtonDiv = styled.div`
  width: 173px;
  padding-left: 55px;
  display: flex;
  justify-content: space-between;
  margin-right: 25px;

  .checkMark,
  .xMark {
    height: 10px;
    width: 10px;
  }
`;

export const ButtonText = styled.p`
  font-size: 12px;
`;

export const CompanyAddress = styled.p`
  font-weight: medium;
  font-size: 12px;
  color: steel;
  margin-top: 4px;
  margin-bottom: 18px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
`;

export const CompanyInfoDiv = styled.div`
  width: 100%;
  margin-left: 32px;
`;

export const CompanyInfoRow = styled.div`
  padding-left: 32px;  
  display: flex;
  background-color: ghostwhite;
  align-items: center;
  height: 158px;
  width: 625px;
`;

export const CompanyName = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
`;

export const ZoominfoData = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  margin: auto;
  line-height: 2;


  .employee,
  .revenue,
  .multiLocation {
    height: 17px;
    width: 17px;
    margin-right: 5px;
  }

  .revenue {
    margin-left: 25px;
  }

  .employees {
    margin-left: 4px;
  }

  .multiLocation {
    margin-left: 20px;
    border: 1px solid #FF7E55;
    padding: 5px;
    border-radius: 3px;
    color: #FF7E55;
  }
`;

export const DescriptionInput = styled(Input)`
  margin-bottom: 15px;
  margin-left: 10px;
  font-size: 12px;
`;

export const GetLeadButton = styled.button`
  align-items: center;
  border: 2px solid #00BD94;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  height: 40px;
  width: 40px;
  stroke-width: 2px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  background-color: ghostwhite;
  color: #00BD94;
  &:hover {
    background-color: #00BD94;
    background: rgba(0, 189, 148, .12);
    border: 2px solid #00BD94;
  }
  ${props => acceptButtonActiveColor(props)}
`;

export const Header = styled.div`
border-top-left-radius: 3px;
border-top-right-radius: 3px;
padding-left: 32px;
padding-right: 14px;
width: 625px;
background-color: #778692;
color: #FFFFFF;
display: flex;
align-items: center;
justify-content: space-between;
height: 55px;
`;

export const InputDiv = styled.div`
  background-color: ghostwhite;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  width: 426px;
  padding: 0px 60px 60px 30px;
  margin-top: 6px;
  height: 290px;

  .ownerEmail {
    width: 240px;
  }

  .leadOwnerText,
  .descriptionText {
    font-size: 14px;
    color: #878787;
    padding: 5px 0 0 10px;
  }

  .descriptionText {
    margin-top: 15px;
  }

  .description {
    background-color: #FAFAFB;
    border: 1px solid #EDEFF4;
    width: 240px;
    min-height: 70px;
    max-height: 70px;
  }

  .descriptionDiv {
    display: inline-flex;
  }
`;

export const InputForm = styled(Input)`
  width: 157px;
  background-color: #FAFAFB;
  border: 1px solid #EDEFF4;
  margin-bottom: 12px;
  margin-left: 0;
  font-size: 12px;
  ${props => inputFieldBorderColor(props)}
`;

export const InputErrorText = styled.p`
  font-size: 11px;
  font-style: italic;
  color: #E73E51;
  margin: -10px 0 0 5px;
`;

export const InputRow = styled.div`
  background-color: ghostwhite;
  align-items: center;
  
  .placesDetailsDiv {
    opacity: 0.8;
    text-align: center;
    margin-top: 5px;

  }
  
  .googleRating {
    font: inherit;
    font-size: 14px;
    margin-left: 10px;
  }

  .storeHours {
    max-width: 190px;
    font: inherit;
    font-size: 13px;
    overflow: auto;
    margin-left: 10px;
    
    .storeHoursTitle {
      font-weight: bold;
      margin: 5px 0 5px 8px;
    }
    
    ul {
      margin: 5px 0 5px 0;
      padding-left: 9px;
      list-style-type: none;
    }
  }
`;

export const CompanyCard = styled.div`
  margin-bottom: 25px;
`;

export const NearbyCustomerDiv = styled.div`
  background-color: ghostwhite;
  width: 625px;
  margin-top: -10px;
  padding: 0px 15px 15px 15px;
`;

export const ToggleSpan = styled.span`
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin-bottom: 15px;
  width: 325px;
  height: 32px;
  padding-top: 5px;
  margin: 10px auto;
  font-weight: 500;
  color: #666;

  &:hover {
    cursor: pointer;
    transition: 500ms;
    color: black;
  }

  .dropdown {
    width: 15px;
    height: 15px;
    margin-left: 5px;
  }
`;

export const NearbyCustomerRow = styled.div`
  text-align: center;
  border: 1px solid #3333;
  padding: 5px 0;
  margin-bottom: 5px;
  font-size: 13px;
  display: flex;
  justify-content: center;

    .icon,
    .businessIcon {
      border-left: 1px solid #3333;
      margin: 0 0 0 5px;
      height: 30px;
      width: 30px;
    }

    .podium {
      height: 30px;
      width: 20px;
      margin-right: 5px;
    }

    p {
      display: inline-flex;
      white-space: nowrap; 
      overflow: hidden;
      text-overflow: ellipsis;
      color: steel;
      margin: auto;
      padding-top: 6px;

      &:hover {
        overflow: visible;
        white-space: normal;
        cursor: pointer;
      }
    }

    #name {
      max-width: 245px;
    }

    #vertical {
      max-width: 90px;
    }

    #date {
      max-width: 68px;
    }

    #distance {
      max-width: 45px;
    }
`;

export const Divider = styled.div`
  background-color: #E4E9F0;
  max-width: 175px;
  height: 2px;
  margin: 9px 0 9px auto;
`;

export const LinksButton = styled.button`
  background-color: #8996A0;
  cursor: pointer;
  align-items: center;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  outline: none;
  font-weight: 600;
  line-height: 1;
  height: 30px;
  min-width: 90px;
  margin-left: 10px;
  display: inline;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  color: white;
  &:hover, &:focus {
    background-color: #626562;
  }
  .googleIcon {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    padding-bottom: 2px;
  }
  // .googleButton {
  //   background-color: blue;
  // }
`;

export const HunterLinksButton = styled(LinksButton)`
  background-color: #FF7E55;
  &:hover, &:focus {
    background-color: #cc6445;
  }
  div {
    padding-left: 5px;
    padding-right: 5px;
  }

  .hunterIcon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    padding-bottom: 2px;
  }
`;

export const ProspectLogo = styled.img`
  max-height: 100px;
  max-width: 160px;
  border-radius: 3px;
`;

export const RejectButtonDiv = styled.div`
  color: #E73E51;
  text-align: center;
`;

export const RejectLeadButton = styled.button`
  align-items: center;
  border: 2px solid #E73E51;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  height: 40px;
  width: 40px;
  stroke-width: 2px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  background-color: ghostwhite;
  color: #E73E51;
  &:hover {
    background-color: #E73E51;
    background: rgba(231, 62, 81, .11);
    border: 2px solid #E73E51;
    color: #E73E51;
  }
  ${props => rejectButtonActiveColor(props)}
`;

export const CheckboxDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const DivOfCheckboxes = styled.div`
  padding: 5px;
`;

export const CheckBoxText = styled.p`
  margin: 10px 0px 10px 5px;
`;

export const NotStrikezoneItalicText = styled.i`
  font-size: 14px;
  margin-top: 5px;
`;

export const TableRow = styled.div`
  padding: 35px;  
  display: flex;
  background-color: ghostwhite;
  align-items: center;
`;

export const TableDiv = styled.div`
  display: inline-block;
  text-align: center;
  min-width: 160px;
`;

export const RejectionSelectDiv = styled(TableDiv)`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
  min-width: 426px;
  max-width: 426px;
`;
