import styled from 'styled-components';
import colors from '../../styles/colors';

export const Row = styled.div`
  display: flex;
  margin: 0 0 16px 0;

  height: 136px;
  width: 100%;
  max-width: 446px;
  padding: 16px;
  border-radius: 4px;
  transistion: all .2s ease-in-out;

  ${props => (props.selected ? `
    background-color: ${colors.white};
    border: 2px solid ${colors.bearLake};
  ` : `
    background-color: ${colors.whiteSmoke};
    border: 2px solid ${colors.whiteSmoke};
  `)}

  .icon {
    margin-right: 16px;
  }

  .content {
    margin-top: 4px;
    .title {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 14px;
    }
    .message {
      font-size: 14px;
    }
    .featureDetails title {
      font-weight: 600;
      font-size: 13px;
    }
  }
`;

export const PlatformContainer = styled.div`
  display: flex;

  .title {
    font-weight: 600;
    font-size: 16px;
  }

  .description {
    color: ${colors.jumbo};
    font-size: 14px;
    margin-bottom: 24px;
  }

  .feature-holder {
    margin-left: 48px;
    max-width: 356px;
    width: 100%;

    .feature {
      font-size: 12px;
      margin: 8px 0;
      color: ${colors.jumbo};
      display: flex;
    }

    .selected {
      color: ${colors.mineShaft};
      font-weight: 600;
    }

    .check-holder {
      width: 16px;
      margin-right: 8px;
    }

  }
`;

export const TooltipDiv = styled.div`
  width: 300px;
`;


export const PriceContainer = styled.div`
font-weight: 700;
font-size: 18px;
color: #333333;

  .duration {
    font-weight: normal;
    font-size: 15px;
  }

 div {
    margin-top: 26px;
  }

`;
