import { createGlobalStyle } from 'styled-components';
import Color from 'color';
import fontFaces from './fontFaces';
import colors from './colors';
import typography from './typography';

const textSelection = Color(colors.bearLake)
  .saturate(0.24)
  .lighten(0.27)
  .hsl()
  .string();

/* eslint-disable no-unused-expressions */
createGlobalStyle`
  ${fontFaces}
  html, body, #root {
    min-height: 100vh;
    position: relative;
    z-index: 0;
  }

  html, body {
    font-family: ${typography.font_family};
    color: ${colors.mineShaft};
    overflow: auto !important;
  }

  ::-moz-selection, {
    background-color: ${textSelection};
    color: inherit;
  }

  ::selection {
    background-color: ${textSelection};
    color: inherit;
  }

  ul {
    list-style: none;
  }

  a {
    cursor: pointer;
    color: ${colors.bearLake};
  }
`;
/* eslint-enable no-unused-expressions */
