import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ProductsContainer,
  IframeContainer,
  ButtonContainer,
  IconButton,
} from "./styles";
import { Section, SectionContents } from "../Packaged/styles";

const ROADMAP = "roadmap";
const BETA = "beta";

const iframe1Url =
  "https://airtable.com/embed/shr8iWSMgrjDgwfvv?backgroundColor=purple&viewControls=on";
const iframe2Url =
  "https://airtable.com/tblhmQM0AnKk4HJmz/viwoWQ4tUhnQ9WMKr?blocks=hide";

const Products = () => {
  // Set state
  const [selectedView, setSelectedView] = useState(ROADMAP);

  // Helper functions
  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  const renderIframeUrl = () => {
    return selectedView === ROADMAP ? iframe1Url : iframe2Url;
  };

  const renderIframeHeight = () => {
    return selectedView === ROADMAP ? "775" : "1375";
  };

  // JSX to be returned
  return (
    <ProductsContainer>
      <Section>
        <SectionContents>
          <ButtonContainer>
            <IconButton height="40" onClick={() => handleViewChange(ROADMAP)}>
              Roadmap
            </IconButton>
            <IconButton height="40" onClick={() => handleViewChange(BETA)}>
              Beta
            </IconButton>
          </ButtonContainer>
          <Iframe url={renderIframeUrl()} height={renderIframeHeight()} />
        </SectionContents>
      </Section>
    </ProductsContainer>
  );
};

const Iframe = (props) => {
  return (
    <IframeContainer>
      <iframe
        height={props.height}
        className="airtable-embed"
        src={props.url}
        title="airtable"
      />
    </IframeContainer>
  );
};

Iframe.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Products;
