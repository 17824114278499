import React from 'react';
import Select from 'react-select';
import * as moment from 'moment';
import { Tooltip } from 'react-tippy';
import * as icon from '../../images/icon';
import { fetchLeadScore, findNearbyCustomers } from '../../lib/client';
import { toolTips } from '../Prospector/optionData';
import NearbyLocationSearchInput from './NearbyLocationSearchInput';
import { verticalOptions, subverticalOptions } from '../../lib/toolData';
import {
  Section,
  SectionContents,
  SectionTitle,
  VerticalSelect,
  SubverticalSelect,
} from '../Packaged/styles';
import {
  ButtonSpinner,
  ScoreResultContainer,
} from '../LeadScoring/styles';
import {
  PositiveResultContainer,
  ScoreFormContainer,
} from '../LeadScoring/ScoreResult/styles';
import { dropdownStyles } from '../Prospector/styles';
import { 
  CustomerButton, 
  CustomerInfo, 
  CustomerDiv, 
  CustomerRow, 
  Divider, 
  NameHeader, 
  NearbyCustomersHeading,
  SuggestedText
} from './styles';

const DEFAULT_VERTICAL = [{ label: 'Auto', value: 'Auto' }];
const DEFAULT_SUBVERTICAL = [
  { label: 'OEM/New Car Dealer', value: 'OEM/New Car Dealer' },
];

/* eslint-disable dot-notation */
/* eslint-disable no-plusplus */
/* eslint-disable object-shorthand */
/* eslint-disable no-shadow */
/* eslint-disable prefer-template */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unescaped-entities */
export default class NearbyCustomers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nearbyCustomers: [],
      selectedVertical: DEFAULT_VERTICAL[0],
      selectedSubvertical: DEFAULT_SUBVERTICAL[0],
      placeId: null,
      sessionToken: null,
      fetchingResultData: {},
      loading: false,
      subverticalOptions: subverticalOptions['Auto'],
      verticalOptions: verticalOptions,
    };
  }

  getLevenschteinDistance = (orgName, locationName) => {
    if (orgName.length === 0) return locationName.length;
    if (locationName.length === 0) return orgName.length;

    const matrix = [];

    // increment along the first column of each row
    let i;
    for (i = 0; i <= locationName.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    let j;
    for (j = 0; j <= orgName.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= locationName.length; i++) {
      for (j = 1; j <= orgName.length; j++) {
        if (locationName.charAt(i - 1) === orgName.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[locationName.length][orgName.length];
  };

  getName = (orgName, locationName) => {
    if (this.areNamesSimilar(orgName, locationName)) {
      return `${locationName}`;
    }
    return `${orgName} - ${locationName}`;
  };

  calculateCustomerReviewScore = (existingCustomer) => {
    const avgRatingScore = this.avgRatingScore(existingCustomer);
    const reviewCountIncreasePercentile = this.reviewCountIncreasePercentile(
      existingCustomer
    );
    return avgRatingScore > 0
      ? avgRatingScore + reviewCountIncreasePercentile / 100
      : reviewCountIncreasePercentile / 100;
  };

  reviewCountIncreasePercentile = (existingCustomer) => {
    return existingCustomer.reviews_30days_before_podium
      ? (existingCustomer.reviews_last_30days -
          existingCustomer.reviews_30days_before_podium) /
          existingCustomer.reviews_30days_before_podium
      : 0;
  };

  areNamesSimilar = (orgName, locationName) => {
    const flattenedOrgName = this.flattenNameForComparison(orgName);
    const flattenedLocationName = this.flattenNameForComparison(locationName);
    const lengthOfOrgName = flattenedOrgName.length;
    const beginningOfLocation = flattenedLocationName.substr(
      0,
      lengthOfOrgName
    );
    const endOfLocation = flattenedLocationName.substr(
      lengthOfOrgName * -1,
      lengthOfOrgName
    );

    if (
      this.getLevenschteinDistance(flattenedOrgName, beginningOfLocation) <=
        3 ||
      this.getLevenschteinDistance(flattenedOrgName, endOfLocation) <= 3
    ) {
      return true;
    }
    return false;
  };

  flattenNameForComparison = (name) =>
    name
      .toLowerCase()
      .replace(/\b(the|and|of|at|in)\b/g, '')
      .replace(/\W/g, '');

  avgRatingScore = (existingCustomer) => {
    return existingCustomer.avg_review_rating_90days_before_podium
      ? Math.round(
          (existingCustomer.avg_review_rating_last_90days -
            existingCustomer.avg_review_rating_90days_before_podium) *
            10
        ) / 10
      : 0;
  };

  fetchNearbyCustomers = async () => {
    const { selectedVertical, selectedSubvertical, fetchingResultData } = this.state;
    if (selectedVertical && selectedSubvertical && fetchingResultData) {
      const addressSearchString = `${fetchingResultData.address}, ${fetchingResultData.city}, ${fetchingResultData.state} ${fetchingResultData.postalCode}`;
      console.log("ADDRESS", addressSearchString)
      const response = await findNearbyCustomers({
        vertical: selectedVertical.value,
        subvertical: selectedSubvertical.value,
        address: addressSearchString,
      });
      return response;
    }
    return {};
  };

  fetchLocation = async () => {
    const { placeId, sessionToken, selectedVertical } = this.state;
    if (placeId && sessionToken && selectedVertical) {
      const response = await fetchLeadScore(
        placeId,
        sessionToken,
        selectedVertical
      );
      return response;
    }
    return {};
  };

  handleVerticalChange = (option) =>
    this.setState({
      selectedVertical: option,
      subverticalOptions: subverticalOptions[option.value],
      selectedSubvertical: subverticalOptions[option.value][0],
    });

  handleSubverticalChange = (option) =>
    this.setState({
      selectedSubvertical: option,
    });

  handleSelectLocation = (placeId, sessionToken) => {
    this.setState({
      placeId,
      sessionToken,
    });
  };

  handleFetchButtonClick = async () => {
    this.setState({
      loading: true,
    });
    const fetchingResultData = await this.fetchLocation();
    this.setState({ fetchingResultData });
    const nearby = await this.fetchNearbyCustomers();
    this.setState({
      loading: false,
      nearbyCustomers: nearby
    })
  };

  render() {
    const {
      fetchingResultData,
      loading,
      nearbyCustomers,
      placeId,
      subverticalOptions,
      selectedSubvertical,
      selectedVertical,
      verticalOptions,
    } = this.state;

    return (
      <>
        <Section>
          <SectionTitle>
            <div>01</div>
            <div>VERTICAL</div>
          </SectionTitle>
          <SectionContents>
            <VerticalSelect>
              <Select
                placeholder={selectedVertical}
                isSearchable
                options={verticalOptions}
                styles={dropdownStyles}
                className={selectedVertical}
                value={selectedVertical}
                onChange={(option) => this.handleVerticalChange(option)}
              />
            </VerticalSelect>
          </SectionContents>
        </Section>
        <Section>
          <SectionTitle>
            <div>02</div>
            <div>SUBVERTICAL</div>
          </SectionTitle>
          <SectionContents>
            <SubverticalSelect>
              <Select
                placeholder={selectedSubvertical}
                isSearchable
                options={subverticalOptions}
                styles={dropdownStyles}
                className={selectedSubvertical}
                value={selectedSubvertical}
                onChange={(option) => this.handleSubverticalChange(option)}
              />
            </SubverticalSelect>
          </SectionContents>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>
            <div>03</div>
            <div>SEARCH</div>
          </SectionTitle>
          <SectionContents>
            <NearbyLocationSearchInput onSelect={this.handleSelectLocation} />
            <CustomerButton onClick={this.handleFetchButtonClick} height={40} disabled={!placeId}>
              <Tooltip
                title={toolTips.uploadTool}
                position="top"
                trigger="mouseenter"
                size="big"
                arrow
                distance={30}
                offset={-40}
                delay={200}
                hideDelay={0}
              >
                {!placeId ?
                  <span>Please select a location</span>
                  :
                  <span>Find Customers</span>
                }
              </Tooltip>
              {loading && (
                <ButtonSpinner width={20} height={20} color="white" />
              )}
            </CustomerButton>
            <SuggestedText><i>We recommend searching by address, and selecting the suggested row that matches before clicking 'Find Customers'.</i></SuggestedText>
          </SectionContents>
        </Section>
        <Divider />
        <Section>
          <SectionTitle>
            <div>04</div>
            <div>RESULTS</div>
          </SectionTitle>
          <SectionContents>
            <ScoreResultContainer>
              <PositiveResultContainer>
                <br />
                <ScoreFormContainer>
                  {fetchingResultData.hasOwnProperty('address') && !loading &&
                    nearbyCustomers.length === 0 && (
                      <Section>
                        No results? Try just the address instead: 
                        &nbsp;<u>{`${fetchingResultData.address}, ${fetchingResultData.city}, ${fetchingResultData.state} ${fetchingResultData.postalCode}`}</u>
                      </Section>
                  )}
                </ScoreFormContainer>
              </PositiveResultContainer>
              {nearbyCustomers.length > 0 && 
                <CustomerDiv>
                  <NearbyCustomersHeading>Nearby Customers</NearbyCustomersHeading>
                  {nearbyCustomers.map((location) => {
                    return (
                      <CustomerRow>
                        <NameHeader>
                          <img src={icon.newPodium} alt="name" className="podium" />
                          <span>
                            {this.getName(location.org_name, location.location_name)}
                          </span>
                        </NameHeader>
                        <CustomerInfo>

                          <div id="leftSide">
                            <div id="distanceDiv">
                              <img src={icon.mapMarker} alt="distance" className="icon" />
                              <span>
                                {location.distance_to_target.toString().substr(0, 4)} mi
                              </span>
                            </div>
                            <div id="customerSinceDiv">
                              <img src={icon.calendar} alt="calendar" className="icon" />
                              <span>
                                {moment().diff(
                                  location.location_active_start_date,
                                  'months'
                                )}{' '}
                                months
                              </span>
                            </div>
                            <div id="verticalDiv">
                              <img
                                src={icon.business}
                                alt="vertical"
                                className="businessIcon"
                              />
                              <span>{location.vertical_name}</span>
                            </div>
                          </div>
                          <div id="rightSide">
                            <div>
                              <span>{`${location.reviews_all_time} total reviews`}</span>
                            </div>
                            <div>
                              <span>{`${location.reviews_since_joining_podium} reviews since joining Podium`}</span>
                            </div>
                            <div>
                              <span>
                                {this.avgRatingScore(location) > 0
                                  ? this.avgRatingScore(location) +
                                    ' ★ increase in avg rating since joining Podium'
                                  : Math.round(
                                      location.avg_review_rating_last_90days * 10
                                    ) /
                                      10 +
                                    ' ★ average review rating in last 90 days'}
                              </span>
                            </div>
                          </div>
                        </CustomerInfo>
                      </CustomerRow>
                    );
                  })}
                </CustomerDiv>
              }
            </ScoreResultContainer>
          </SectionContents>
        </Section>
      </>
    );
  }
}
