import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Checkbox } from '@podiumhq/podium-ui';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { pullCountOptions, toolTips } from '../optionData';
import * as icon from '../../../images/icon';

import {
  Divider,
  Section,
  SectionContents,
  VerticalSelect,
  SubverticalSelect,
  StateSelect
} from '../../Packaged/styles';
import {
  CountInput,
  dropdownStyles,
  ProspectorSectionTitle,
  ToggleSpan,
  ZoomInfoSelectDiv
} from '../styles';

const Range = createSliderWithTooltip(Slider.Range);
const ratingFormatter = v => v;

const sliderMarks = {
  0.5: 0.5,
  1.0: 1.0,
  1.5: 1.5,
  2.0: 2.0,
  2.5: 2.5,
  3.0: 3.0,
  3.5: 3.5,
  4.0: 4.0,
  4.5: 4.5,
  5.0: 5.0
};

const sliderStyle = {
  margin: '25px 10px 0 5px',
  width: '515px'
};

const ProspectingDropdowns = ({
  cityOptions,
  handleCityChange,
  handlePostalCodeChange,
  handlePullCountChange,
  handleShowMultiLocs,
  handleStarRatingChange,
  handleStateChange,
  handleStrikezoneChange,
  handleSubverticalChange,
  handleVerticalChange,
  postalCodeOptions,
  pullCount,
  selectedCity,
  selectedPostalCode,
  selectedState,
  selectedStateOptions,
  selectedStrikezone,
  selectedStrikezoneOptions,
  selectedSubvertical,
  selectedSubverticalOptions,
  selectedVertical,
  selectedVerticalOptions,
  showMultiLocs,
  starRatingMin,
  starRatingMax,
}) => {
  const advancedAttrsRef = useRef();

  const toggled = JSON.parse(localStorage.getItem('toggleAdvancedAttrs'));
  
  const [advancedAttrs, toggleAdvancedAttrs] = useState(toggled);

  useEffect(() => {
    if (advancedAttrsRef.current) {
      window.scrollTo({
        behavior: 'smooth',
        top: advancedAttrsRef.current.offsetTop
      });
    }
  }, [advancedAttrs]);

  const toggle = () => {
    toggleAdvancedAttrs(!advancedAttrs);
    localStorage.setItem('toggleAdvancedAttrs', JSON.stringify(!toggled));
  };

  return (
    <React.Fragment>
      <h5>Set prospect attributes</h5>
      <Divider />
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.vertical}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div>VERTICAL</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <VerticalSelect data-tip>
            <Select
              isSearchable
              options={selectedVerticalOptions}
              styles={dropdownStyles}
              className={selectedVertical}
              value={selectedVertical}
              onChange={option => handleVerticalChange(option)}
            />
          </VerticalSelect>
        </SectionContents>
      </Section>
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.subVertical}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div>SUBVERTICAL</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <SubverticalSelect>
            <Select
              isSearchable
              styles={dropdownStyles}
              options={selectedSubverticalOptions}
              value={selectedSubvertical}
              className={selectedSubvertical}
              onChange={option => handleSubverticalChange(option)}
            />
          </SubverticalSelect>
        </SectionContents>
      </Section>
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.strikezone}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div>STRIKEZONE</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <SubverticalSelect>
            <Select
              isSearchable
              styles={dropdownStyles}
              options={selectedStrikezoneOptions}
              value={selectedStrikezone}
              className={selectedStrikezone}
              onChange={option => handleStrikezoneChange(option)}
            />
          </SubverticalSelect>
        </SectionContents>
      </Section>
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.state}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div>STATE</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <StateSelect>
            <Select
              isSearchable
              options={selectedStateOptions}
              styles={dropdownStyles}
              value={selectedState}
              className={selectedState}
              onChange={option => handleStateChange(option)}
            />
          </StateSelect>
        </SectionContents>
      </Section>
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.city}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div>CITY</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <SubverticalSelect>
            <Select
              isSearchable
              styles={dropdownStyles}
              options={cityOptions}
              value={selectedCity}
              className={selectedCity}
              onChange={option => handleCityChange(option)}
            />
          </SubverticalSelect>
        </SectionContents>
      </Section>
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.postalCode}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div>POSTAL CODE</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <SubverticalSelect>
            <Select
              styles={dropdownStyles}
              options={postalCodeOptions}
              onChange={option => handlePostalCodeChange(option)}
              value={selectedPostalCode}
              className={selectedPostalCode}
              isSearchable
            />
          </SubverticalSelect>
        </SectionContents>
      </Section>
      <ToggleSpan onClick={toggle}>
        <Tooltip
          title={toolTips.advancedAttrsToggle}
          position="top"
          trigger="mouseenter"
          size="big"
          arrow
          delay={300}
        >
          Advanced Attributes{' '}
          {advancedAttrs === false ? (
            <img src={icon.dropdown} className="dropdown" alt="" />
          ) : (
            <img src={icon.carrot} className="dropdown" alt="" />
          )}
        </Tooltip>
      </ToggleSpan>
      {advancedAttrs && (
        <div>
          <h5 ref={advancedAttrsRef} className="advancedAttrs">
            Set advanced attributes
          </h5>
          <Divider />
          <Section>
            <ProspectorSectionTitle>
              <div>MULTI LOCATIONS</div>
            </ProspectorSectionTitle>
            <SectionContents>
              <ZoomInfoSelectDiv>
                <div className="checkbox">
                  <Checkbox
                    checked={showMultiLocs}
                    onChange={handleShowMultiLocs}
                  />
                </div>
                <div>
                  Filter prospect list to show companies that may have
                  multiple locations. Data is collected from the business
                  website, but is <strong>not guaranteed</strong> to be 100%
                  accurate.
                </div>
              </ZoomInfoSelectDiv>
            </SectionContents>
          </Section>
          <Section>
            <ProspectorSectionTitle>
              <Tooltip
                title={toolTips.starRatingRange}
                position="right"
                trigger="mouseenter"
                size="big"
                arrow
              >
                <div>STAR RATING RANGE</div>
              </Tooltip>
            </ProspectorSectionTitle>
            <SectionContents>
              <div style={sliderStyle}>
                <Range
                  allowCross={false}
                  activeDotStyle={{
                    backgroundColor: '#6e91e6',
                    border: 'none'
                  }}
                  defaultValue={[starRatingMin, starRatingMax]}
                  handleStyle={{
                    backgroundColor: '#6e91e6',
                    border: 'none'
                  }}
                  min={0}
                  max={5}
                  marks={sliderMarks}
                  onChange={value => handleStarRatingChange(value)}
                  railStyle={{
                    backgroundColor: 'steel'
                  }}
                  step={0.1}
                  tipFormatter={value => ratingFormatter(value)}
                  tipProps={{
                    placement: 'top',
                    visible: true
                  }}
                  trackStyle={[
                    {
                      backgroundColor: '#6e91e6'
                    }
                  ]}
                />
              </div>
            </SectionContents>
          </Section>
        </div>
      )}
      <h5>How many prospects?</h5>
      <Divider />
      <Section>
        <ProspectorSectionTitle>
          <Tooltip
            title={toolTips.pullCount}
            position="right"
            trigger="mouseenter"
            size="big"
            arrow
          >
            <div># OF LEADS</div>
          </Tooltip>
        </ProspectorSectionTitle>
        <SectionContents>
          <CountInput>
            <Select
              styles={dropdownStyles}
              options={pullCountOptions}
              onChange={value =>
                handlePullCountChange(_.parseInt(value.value) || 0)
              }
              placeholder={pullCount}
              id={pullCount}
              menuPlacement="top"
              isSearchable
            />
          </CountInput>
        </SectionContents>
      </Section>
    </React.Fragment>
  );
};

export default ProspectingDropdowns;
