import { Input } from '@podiumhq/podium-ui';
import styled from 'styled-components';
import Spinner from '../../components/Spinner/Spinner';

const getColor = (props) => {
  if (props.isDragAccept) {
    return '#00e676';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isDragActive) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const dropdownStyles = {
  option: provided => ({
    ...provided,
    padding: 15
  }),
  placeholder: styles => ({ ...styles, color: "black" }),
  control: styles => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderStyle: "solid",
    borderColor: "#E4E9F0",
    borderWidth: 2,
    color: "black",
    cursor: "pointer",
    width: 180,
    height: 40
  }),
  indicatorSeparator: () => null,
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity };
  }
};

export const DragonContainer = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  max-width: 518px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const InputDiv = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  padding: 20px 20px 25px 20px;
  height: 330px;
  width: 300px;

  p {
    margin-top: 8px;
  }

  .addAnotherUser {
    margin-left: 25px;

    h4 {
      padding-bottom: 25px;
    }

    button {
      width: 160px;
      height: 45px;
      margin-left: 25px;
    }
  }
`;

export const TableInput = styled(Input)`
  background-color: inherit;
  border: none;
  
  .email {
    width: 250px;
  }
`;

export const Table = styled.table`
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;

  .email {
    width: 265px;
  }

  .title {
    text-align: center;
    font-family: arial, sans-serif;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4C76E0;
    color: white;
  }

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  tr:hover {background-color: #ddd;}
  tr:nth-child(even){background-color: #f2f2f2;}
`;

export const DeleteButton = styled.button`
  background-color: #E73E51;
  color: white;
  border-radius: 3px;
  outline: none;
  height: 30px;
  width: 70px;
  cursor: pointer;
`;

export const UpdateButton = styled.button`
  background-color: #4C76E0;
  color: white;
  border-radius: 3px;
  outline: none;
  height: 30px;
  width: 75px;
  cursor: pointer;
`;

export const SubmitButton = styled.button`
  background-color: #00e676;
  color: white;
  border-radius: 3px;
  outline: none;
  height: 30px;
  width: 75px;
  cursor: pointer;
`;

export const UserInput = styled(Input)`
  width: 260px;
  height: 40px;
  background-color: white;
  border: 1px solid #EDEFF4;
  margin-bottom: 12px;
  margin-left: 0;
  font-size: 12px;
`;

export const SubmitUserButton = styled.button`
  background-color: #4C76E0;
  border-radius: 3px;
  border: none;
  outline: none;
  cursor: pointer;
  color: white;
  height: 40px;
  width: 110px;
  transition: all 0.15s ease-in-out;

  &:hover, &:focus {
    background-color: #2353cc;
  }

  &:active {
    background-color: #1a3e97;
  }
`;

export const ProspectingContainer = styled.div`
  margin-top: 54px;
  h1 {
    text-align: center;
  }
`;

export const CountInput = styled.div`
  width: 100%;    
  max-width: 518px;
`;

export const StrikezoneButtonDiv = styled.div`
  width: 390px;
  display: flex;
  justify-content: center;

  
    button {
      border-radius: 3px;
      border: none;
      outline: none;
      cursor: pointer;
      height: 40px;
      widht: 130px;
      color: white
    }

    #addStrikezone {
      margin-right: 40px;
      background-color: #00e676;
    }

    #editStrikezone {
      background-color: #4C76E0;
    }
`;

export const CancelButton = styled.button`
  background-color: #8996A0;
  color: white;
  height: 40px;
  border: none;
  border-radius: 3px;
  padding: 10px;
  margin: 0 0 15px 340px;
  cursor: pointer;
  width: 100px;
  font-weight: 600;
  text-decoration: none;

  &:hover, &:focus {
    background-color: #626562;
  }
`;

export const ProspectingButton = styled.button`
  display: flex
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 0 16px;
  position: relative;
  font-weight: 600;
  line-height: 1;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  height: ${props => props.height}px;
  background-color: #4C76E0;
  color: white;

  &:hover, &:focus {
    background-color: #2353cc;
  }

  &:active {
    background-color: #1a3e97;
  }
  margin-right: 10px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: left;
`;

export const ButtonSpinner = styled(Spinner)`
  margin-left: 12px;
`;

export const CloseButton = styled.span`
  color: red;
`;

export const ApiError = styled.span`
  color: red;
`;
