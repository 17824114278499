/* eslint-disable dot-notation */
import React, { Component } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, Tooltip } from 'recharts';

// eslint-disable-next-line react/prefer-stateless-function
export default class BarCharts extends Component {

  renderTooltip = (value) => {
    return `${value}%`; 
  }

  render() {
    /* eslint-disable camelcase */
    /* eslint-disable react/jsx-curly-brace-presence */
    /* eslint-disable prefer-template */
    const {
      data,
    } = this.props
    
    const organizedData = [
      {
        "stage": `Application Review - (${data["Application Review"].total_eeocs} total)`,
        "total": data["Application Review"].total_eeocs,
        "Women": Math.floor(100 * (data["Application Review"].women / data["Application Review"].total_eeocs)) || 0,
        "URMs": Math.floor(100 * (data["Application Review"].urms / data["Application Review"].total_eeocs)) || 0,
        "Non-Diverse": Math.floor(100 * (data["Application Review"].non_diverse / data["Application Review"].total_eeocs)) || 0,
        "Unknown": Math.floor(100 * (data["Application Review"].unknown / data["Application Review"].total_eeocs)) || 0
      },
      {
        "stage": `Phone Interview - (${data["Phone Interview"].total_eeocs} total)`,
        "total": data["Phone Interview"].total_eeocs,
        "Women": Math.floor(100 * (data["Phone Interview"].women / data["Phone Interview"].total_eeocs)) || 0,
        "URMs": Math.floor(100 * (data["Phone Interview"].urms / data["Phone Interview"].total_eeocs)) || 0,
        "Non-Diverse": Math.floor(100 * (data["Phone Interview"].non_diverse / data["Phone Interview"].total_eeocs)) || 0,
        "Unknown": Math.floor(100 * (data["Phone Interview"].unknown / data["Phone Interview"].total_eeocs)) || 0
      },
      {
        "stage": `Onsite Interview - (${data["Onsite Interview"].total_eeocs} total)`,
        "total": data["Onsite Interview"].total_eeocs,
        "Women": Math.floor(100 * (data["Onsite Interview"].women / data["Onsite Interview"].total_eeocs)) || 0,
        "URMs": Math.floor(100 * (data["Onsite Interview"].urms / data["Onsite Interview"].total_eeocs)) || 0,
        "Non-Diverse": Math.floor(100 * (data["Onsite Interview"].non_diverse / data["Onsite Interview"].total_eeocs)) || 0,
        "Unknown": Math.floor(100 * (data["Onsite Interview"].unknown / data["Onsite Interview"].total_eeocs)) || 0
      },
      {
        "stage": `Offer - (${data["Offer"].total_eeocs} total)`,
        "total": data["Offer"].total_eeocs,
        "Women": Math.floor(100 * (data["Offer"].women / data["Offer"].total_eeocs)) || 0,
        "URMs": Math.floor(100 * (data["Offer"].urms / data["Offer"].total_eeocs)) || 0,
        "Non-Diverse": Math.floor(100 * (data["Offer"].non_diverse / data["Offer"].total_eeocs)) || 0,
        "Unknown": Math.floor(100 * (data["Offer"].unknown / data["Offer"].total_eeocs)) || 0
      },
      {
        "stage": `Hired - (${data["Hired"].total_eeocs} total)`,
        "total": data["Hired"].total_eeocs,
        "Women": Math.floor(100 * (data["Hired"].women / data["Hired"].total_eeocs)) || 0,
        "URMs": Math.floor(100 * (data["Hired"].urms / data["Hired"].total_eeocs)) || 0,
        "Non-Diverse": Math.floor(100 * (data["Hired"].non_diverse / data["Hired"].total_eeocs)) || 0,
        "Unknown": Math.floor(100 * (data["Hired"].unknown / data["Hired"].total_eeocs)) || 0
      },
    ];

    return (
      <React.Fragment>
        <h1>Out of {data["Application Review"].total_eeocs} total applications</h1>
        <h3>For applications received since January 1, 2021</h3>
        <BarChart width={1000} height={800} data={organizedData} layout='vertical'>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type='number' domain={[0, 100]} label={{ value: 'Percentage %', position: 'insideBottomLeft', offset: -10 }} />
          <YAxis type='category' dataKey='stage' width={130} />
          <Tooltip formatter={this.renderTooltip} />
          <Legend />
          <Bar dataKey="Women" fill="#8884d8" />
          <Bar dataKey="URMs" fill="#82ca9d" />
          <Bar dataKey="Non-Diverse" fill="#db8686" />
          <Bar dataKey="Unknown" fill="#696565" />
        </BarChart>
      </React.Fragment>
    );
  }
}
