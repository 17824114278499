import styled from 'styled-components';
import Spinner from '../../components/Spinner/Spinner';

export const LeadScoringContainer = styled.div`
  margin-top: 54px;
  h1 {
    text-align: center;
  }
`;

export const IconButton = styled.button`
  display: flex
  align-items: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  outline: none;
  padding: 0 16px;
  position: relative;
  font-weight: 600;
  line-height: 1;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  height: ${props => props.height}px;
  background-color: #4C76E0;
  color: white;

  &:hover, &:focus {
    background-color: #2353cc;
  }

  &:active {
    background-color: #1a3e97;
  }
`;

export const ButtonSpinner = styled(Spinner)`
  margin-left: 12px;
`;

export const ScoringButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 105px;
`;

export const ScoreResultContainer = styled.div`
  width: 90%;
  min-height: 175px;
  background-color: #F5F5F7;
  padding-bottom: 32px;
`;
