import styled, { keyframes } from 'styled-components';

const svgAnimation = keyframes`
  0%{
    transform:rotate(0);
  }
  100%{
    transform:rotate(360deg);
  }
`;

const circleAnimation = keyframes`
  0%{
    stroke-dashoffset:600;
  }
  100%{
    stroke-dashoffset:0;
  }
`;

const SpinnerContainer = styled.div`
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  line-height: 0;
  box-sizing: border-box;

  svg {
    animation: ${svgAnimation} 2s linear 0s infinite normal none running;
  }

  circle {
    stroke-dashoffset: 600;
    stroke-dasharray: 300;
    stroke-width: 12;
    stroke-miterlimit: 10;
    stroke-linecap: round;
    stroke: ${props => props.color};
    fill: transparent;
    animation: ${circleAnimation} 1.6s cubic-bezier(0.4, 0.15, 0.6, 0.85) 0s infinite normal none running;
  }
`;

export default SpinnerContainer;
