import { colors } from '@podiumhq/podium-ui';

export default {
  ...colors,

  jumbo: '#878787',
  borderColor: colors.mystic,
  outboundMessageColor: '#E6F6FE',
  selectedBlue: '#259CE2',
  outboundMessageText: '#CBE7F7',
  composerPrimary: '#aaa',
  formBg: '#e4e9f0',
  formBgHover: '#ebeef4'
};

export const { siteColors } = colors;

export const eventTypeColors = {
  message_sent: '#dbf0ee',
  review_invite_link_clicked: '#77daef',
  email_review_invite: '#FFB47A',
  sms_review_invite: '#7ef0a8',
  customer_posted_review: '#ffe996',
  facebook_message_event: '#0084FF'
};
