import styled from 'styled-components';
import Spinner from '../../components/Spinner/Spinner';

export const CheckDiv = styled.div`
  display: inline-block;
  padding: 3px;
`;

export const EngRecruitingContainer = styled.div`
    width: 100%;
`;

export const BarChartContainer = styled.div`
    width: 100%;
`;

export const Divider = styled.div`
  background-color: #E4E9F0;
  max-width: 992px;
  width: 100%;
  height: 2px;
`;

export const Section = styled.div`
  display: flex;
  margin: 56px 0;
`;

export const SectionContents = styled.div`
  width: 100%;
  position: relative;
`;

export const RightSectionContents = styled.div`
  width: 33%;
  position: relative;
`;

export const SectionTitle = styled.div`
  width: 150px;
  margin-right: 48px;

  div:first-child {
    font-weight: 700;
    font-size: 21px;
  }

  div:last-child {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }
`;

export const LoadingSpinner = styled(Spinner)`
  margin: auto;
`;

export const fetchButton = styled.button`
  background-color: #8996A0;
  cursor: pointer;
  align-items: center;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  font-weight: 600;
  line-height: 1;
  height: 50px;
  min-width: 130px;
  margin-left: 10px;
  display: inline;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  color: white;
  &:hover, &:focus {
    background-color: #626562;
  }
`;

export const FetchButton = styled(fetchButton)`
  background-color: #4B76E0;
  &:hover, &:focus {
    background-color: #4B76F9;
  }
  div {
    padding-left: 5px;
    padding-right: 5px;
  }
/* 
  .hunterIcon {
    height: 16px;
    width: 16px;
    margin-right: 8px;
    padding-bottom: 2px;
  } */
`;