import { Socket } from 'phoenix-socket';
import { getCurrentUser } from '../../lib/auth';

const GREENALYZER_WS_HOST = process.env.REACT_APP_GREENALYZER_WS_HOST;

const { email } = getCurrentUser() || '';

const socket = new Socket(`${GREENALYZER_WS_HOST}/socket`, {
  params: { token: email },
  logger: (kind, msg, data) => { console.log(`${kind}: ${msg}`, data); }
});

let greenalyzerChannel = null;

export const getGreenalyzerChannel = () => {
  if(!greenalyzerChannel) {
    socket.connect();
    greenalyzerChannel = socket.channel(
      `greenalyzer:${email}-${Math.random()}`
    );
    greenalyzerChannel
      .join()
      .receive('ok', (resp) =>
        console.log('joined the greenalyzer channel', resp)
      )
      .receive('error', (reason) => console.log('join failed', reason));
  };
  return greenalyzerChannel;
};

export default socket;
