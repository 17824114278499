import React from 'react';
import _ from 'lodash';
import { Dolphin } from '@podiumhq/podium-ui';
import * as icon from '../../../images/icon';
import { BackButton, SeeLeadsButton, SuccessCheckMark } from '../styles';
import {
  FilterFade,
  FilterTicker,
  LoadingScreen,
  LoadingSpinner,
  NoLeadScreen,
  TheStatusScreen,
} from './styles';

const StatusScreen = (props) => {
  const {
    allProspectsRejected,
    error,
    lastSession,
    leadCount,
    loading,
    filteredLeads,
    handleEarlyTerminate,
    noLeadsFound,
    onBackButtonRedirect,
    onRenderSomeFilters,
    onRestoreSession,
    sentToSalesforce,
    statusScreenMode,
    uploadingToSalesforce,
  } = props;

  return (
    <React.Fragment>
      {loading && (
        <LoadingScreen>
          {statusScreenMode === 'sitTight' && (
            <div className="loadingContent">
              <LoadingSpinner width={50} height={50} color="#4B76E0" />
              <h3>Fetching Prospects...</h3>
              <p>Sit tight, this may take a few minutes.</p>
              <FilterTicker>
                {_.map(filteredLeads, (filtered) =>
                  onRenderSomeFilters(filtered)
                )}
                <FilterFade />
              </FilterTicker>
            </div>
          )}
          {statusScreenMode === 'hasSomeLeads' && (
            <div className="loadingContent">
              <LoadingSpinner width={50} height={50} color="#4B76E0" />
              <h3>Fetching Prospects...</h3>
              <div className="leadCountTextDiv">
                <p>
                  We have found {leadCount} prospect(s) so far. Would you like
                  to see those prospects now?
                </p>
                <i className="italicText">
                  If not, hang tight. The Prospector is still searching.
                </i>
                <SeeLeadsButton onClick={handleEarlyTerminate}>See {leadCount} Prospects</SeeLeadsButton>
              </div>
              <FilterTicker>
                {_.map(filteredLeads, (filtered) =>
                  onRenderSomeFilters(filtered)
                )}
                <FilterFade />
              </FilterTicker>
            </div>
          )}
          {statusScreenMode === 'preparingResults' && (
            <div className="loadingContent">
              <LoadingSpinner width={50} height={50} color="#4B76E0" />
              <h3>Preparing Results...</h3>
              <p>We are preparing your results. Almost there!</p>
            </div>
          )}
        </LoadingScreen>
      )}
      {(uploadingToSalesforce && !allProspectsRejected) && (
        <TheStatusScreen>
          <LoadingSpinner width={70} height={70} color="#4B76E0" />
          <br />
          <span>Submitting Prospect List</span>
          <p>Sit tight, this may take a few minutes.</p>
        </TheStatusScreen>
      )}
      {(sentToSalesforce && !allProspectsRejected) && (
        <React.Fragment>
          <BackButton onClick={onBackButtonRedirect}>← Back</BackButton>
          <TheStatusScreen>
            <SuccessCheckMark>✔</SuccessCheckMark>
            <span>Submitted</span>
            <p>
              Make sure to check Salesforce in a few minutes.
              <br />
              <br />
              Your leads will be in <i>Prospects.</i>
            </p>
          </TheStatusScreen>
        </React.Fragment>
      )}
      {(allProspectsRejected) && (
        <React.Fragment>
          <BackButton onClick={onBackButtonRedirect}>← Back</BackButton>
          <TheStatusScreen>
            <SuccessCheckMark>✔</SuccessCheckMark>
            <span>Submitted</span>
            <p>
              All leads have been rejected.
              <br />
              If you&apos;re seeing leads that are consistently not strikezone, please reach out to the Moneyball team so we can help improve your results!
            </p>
          </TheStatusScreen>
        </React.Fragment>
      )}
      {error && (
        <React.Fragment>
          <BackButton onClick={onBackButtonRedirect}>← Back</BackButton>
          <TheStatusScreen>
            <img src={icon.alertCircle} alt="error" className="alertCircle" />
            <br />
            <span>Oops! There was an error on our end.</span>
            <p>Try refreshing the page or submit again.</p>
            {lastSession !== '' && (
              <button
                className="restoreSessionButton"
                onClick={onRestoreSession}
              >
                Restore Session
              </button>
            )}
          </TheStatusScreen>
        </React.Fragment>
      )}
      {noLeadsFound && (
        <React.Fragment>
          <BackButton onClick={onBackButtonRedirect}>← Back</BackButton>
          <NoLeadScreen>
            <Dolphin emotion="concerned" />
            <br />
            <br />
            <span>It looks like no new leads were found.</span>
            <p>Try a new or larger area and submit again.</p>
          </NoLeadScreen>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StatusScreen;
