/* eslint-disable quote-props */
export const columns = [
  {
    Header: "Prospect ID",
    accessor: "id",
    foldable: true
  },
  {
    Header: "Company Name",
    accessor: "company_name",
    foldable: true,
    minWidth: 200
  },
  {
    Header: "Revenue",
    accessor: "zoominfo_revenue",
    foldable: true,
    minWidth: 150
  },
  {
    Header: "Employees",
    accessor: "zoominfo_employee_count",
    foldable: true
  },
  {
    Header: "Street",
    accessor: "street_address",
    foldable: true,
    minWidth: 150
  },
  {
    Header: "City",
    accessor: "city",
    foldable: true,
    minWidth: 150
  },
  {
    Header: "State",
    accessor: "state",
    foldable: true
  },
  {
    Header: "Zip Code",
    accessor: "postal_code",
    foldable: true
  },
  {
    Header: "Website",
    accessor: "website",
    foldable: true,
    minWidth: 300
  }
];

export const verticalOptions = [
  {
    value: "auto",
    label: "Auto"
  },
  {
    value: "autoServices",
    label: "Auto Services"
  },
  {
    value: "homeServices",
    label: "Home Services"
  },
  {
    value: "healthcare",
    label: "Healthcare"
  },
  {
    value: "professionalServices",
    label: "Professional Services"
  },
  {
    value: "retail",
    label: "Retail"
  },
  {
    value: "hospitality",
    label: "Hospitality"
  }
];

export const subverticalOptions = {
  auto: [
    {
      value: "Powersports Dealer",
      label: "Powersports Dealer"
    },
    {
      value: "RV Dealer",
      label: "RV Dealer"
    },
    {
      value: "Used Car Dealer",
      label: "Used Car Dealer"
    }
    // {
    //   value: 'Marine Dealer',
    //   label: 'Marine Dealer'
    // },
    // {
    //   value: 'New Car Dealer',
    //   label: 'New Car Dealer'
    // },
    // {
    //   value: 'OEM/New Car Dealer',
    //   label: 'OEM/New Car Dealer'
    // },
  ],
  autoServices: [
    {
      value: "Collision Center",
      label: "Collision Center"
    },
    {
      value: "Tire Shop",
      label: "Tire Shop"
    }
    // {
    //   value: 'Auto Repair',
    //   label: 'Auto Repair'
    // },
    // {
    //   value: 'Auto Services - Other',
    //   label: 'Auto Services - Other'
    // },
    // {
    //   value: 'Car Wash',
    //   label: 'Car Wash'
    // },
    // {
    //   value: 'Glass',
    //   label: 'Glass'
    // },
    // {
    //   value: 'Oil/Filter',
    //   label: 'Oil/Filter'
    // },
    // {
    //   value: 'Transmission Shop',
    //   label: 'Transmission Shop'
    // }
  ],
  professionalServices: [
    {
      label: "Insurance Brokerage",
      value: "Insurance Brokerage"
    },
    {
      label: "Law Firm",
      value: "Law Firm"
    },
    {
      label: "Mortgage Brokerage",
      value: "Mortgage Brokerage"
    }
    // {
    //   label: 'Cannabis Dispensary',
    //   value: 'Cannabis Dispensary'
    // },
    // {
    //   label: 'Diversified - Other',
    //   value: 'Diversified - Other'
    // },
    // {
    //   label: 'Education',
    //   value: 'Education'
    // },
    // {
    //   label: 'Events/Catering',
    //   value: 'Events/Catering'
    // },
    // {
    //   label: 'Credit Unions',
    //   value: 'Credit Unions'
    // },
    // {
    //   label: 'Banks',
    //   value: 'Banks'
    // },
    // {
    //   label: 'Hospitality/Travel',
    //   value: 'Hospitality/Travel'
    // },
    // {
    //   label: 'Mortuary',
    //   value: 'Mortuary'
    // },
    // {
    //   label: 'Online Domain',
    //   value: 'Online Domain'
    // },
    // {
    //   label: 'Property Management',
    //   value: 'Property Management'
    // },
    // {
    //   label: 'Real Estate',
    //   value: 'Real Estate'
    // },
    // {
    //   label: 'Tax Firm',
    //   value: 'Tax Firm'
    // },
    // {
    //   label: 'Tax Firms',
    //   value: 'Tax Firms'
    // }
  ],
  homeServices: [
    {
      label: "Exteriors",
      value: "Exteriors"
    },
    {
      label: "Flooring",
      value: "Flooring"
    },
    {
      label: "HVAC/Plumbing",
      value: "HVAC/Plumbing"
    },
    {
      label: "Interiors",
      value: "Interiors"
    },
    {
      label: "Moving",
      value: "Moving"
    },
    {
      label: "Pest Control",
      value: "Pest Control"
    },
    {
      label: "Pools",
      value: "Pools"
    },
    {
      label: "Roofing",
      value: "Roofing"
    },
    {
      label: "Security",
      value: "Security"
    },
    {
      label: "Self Storage",
      value: "Self Storage"
    },
    {
      label: "Solar",
      value: "Solar"
    }
    // {
    //   label: 'Electrical',
    //   value: 'Electrical'
    // },
    // {
    //   label: 'Floor Cleaning',
    //   value: 'Floor Cleaning'
    // },
    // {
    //   label: 'Lawn Care',
    //   value: 'Lawn Care'
    // },
    // {
    //   label: 'Locksmith',
    //   value: 'Locksmith'
    // },
    // {
    //   label: 'Maid Service',
    //   value: 'Maid Service'
    // },
    // {
    //   label: 'Pet Care',
    //   value: 'Pet Care'
    // },
    // {
    //   label: 'Windows/Doors',
    //   value: 'Windows/Doors'
    // },
    // {
    //   label: 'Wireless Internet Provider',
    //   value: 'Wireless Internet Provider'
    // }
  ],
  healthcare: [
    {
      label: "Dental (General)",
      value: "Dental (General)"
    },
    {
      label: "Dermatology",
      value: "Dermatology"
    },
    {
      label: "Family Practice",
      value: "Family Practice"
    },
    {
      label: "Med Spa/Wellness Center/Skin Care",
      value: "Med Spa/Wellness Center/Skin Care"
    },
    {
      label: "Ophthalmology",
      value: "Ophthalmology"
    },
    {
      label: "Orthodontics",
      value: "Orthodontics"
    },
    {
      label: "Sports Medicine/Spine/Pain Centers",
      value: "Sports Medicine/Spine/Pain Centers"
    },
    {
      label: "Urgent Care",
      value: "Urgent Care"
    }
    // {
    //   label: 'Addiction Recovery',
    //   value: 'Addiction Recovery'
    // },
    // {
    //   label: 'Assisted Living/Home Health/Hospice',
    //   value: 'Assisted Living/Home Health/Hospice'
    // },
    // {
    //   label: 'Chiropractic',
    //   value: 'Chiropractic'
    // },
    // {
    //   label: 'Dermatologist',
    //   value: 'Dermatologist'
    // },
    // {
    //   label: 'Fertility Center',
    //   value: 'Fertility Center'
    // },
    // {
    //   label: 'Med Spa/Wellness Center/Skin Care',
    //   value: 'Med Spa/Wellness Center/Skin Care'
    // },
    // {
    //   label: 'Hospital',
    //   value: 'Hospital'
    // },
    // {
    //   label: 'Day Spa/Salon',
    //   value: 'Day Spa/Salon'
    // },
    // {
    //   label: 'Vision',
    //   value: 'Vision'
    // },
    // {
    //   label: 'Medical - Other',
    //   value: 'Medical - Other'
    // },
    // {
    //   label: 'Fitness Center (Non-Corporate)',
    //   value: 'Fitness Center (Non-Corporate)'
    // },
    // {
    //   label: 'Ophthalmology',
    //   value: 'Ophthalmology'
    // },
    // {
    //   label: 'Orthopedic Surgery',
    //   value: 'Orthopedic Surgery'
    // },
    // {
    //   label: 'Pediatric Care',
    //   value: 'Pediatric Care'
    // },
    // {
    //   label: 'Plastic Surgery',
    //   value: 'Plastic Surgery'
    // },
    // {
    //   label: 'Radiology/Imaging Center',
    //   value: 'Radiology/Imaging Center'
    // },
    // {
    //   label: 'Veterinarian',
    //   value: 'Veterinarian'
    // },
    // {
    //   label: 'Vision',
    //   value: 'Vision'
    // },
    // {
    //   label: 'Women\'s Health',
    //   value: 'Women\'s Health'
    // }
  ],
  retail: [
    {
      label: "Appliance",
      value: "Appliance"
    },
    {
      label: "Bridal Shop",
      value: "Bridal Shop"
    },
    {
      label: "Furniture",
      value: "Furniture"
    },
    {
      label: "Jewelry",
      value: "Jewelry"
    },
    {
      label: "Mattress",
      value: "Mattress"
    },
    {
      label: "Pawn Shop",
      value: "Pawn Shop"
    }
    // {
    //   label: 'General Retail - Focused on Commercial Businesses',
    //   value: 'General Retail - Focused on Commercial Businesses'
    // },
    // {
    //   label: 'General Retail - Low Volume',
    //   value: 'General Retail - Low Volume'
    // },
    // {
    //   label: 'Sporting Goods',
    //   value: 'Sporting Goods'
    // },
    // {
    //   label: 'General Retail - Owner Operator',
    //   value: 'General Retail - Owner Operator'
    // },
    // {
    //   label: 'Legal',
    //   value: 'Legal'
    // },
  ],
  hospitality: [
    {
      label: "Experiences",
      value: "Experiences"
    },
    {
      label: "Lodging",
      value: "Lodging"
    }
  ]
};

export const strikezoneOptions = {
  "Collision Center": [
    {
      value: "Collision Center",
      label: "Collision Center"
    }
  ],
  "Tire Shop": [
    {
      value: "Tire Shop",
      label: "Tire Shop"
    }
  ],
  Exteriors: [
    {
      label: "Doors",
      value: "Doors"
    },
    {
      label: "Fence",
      value: "Fence"
    },
    {
      label: "Windows",
      value: "Windows"
    }
  ],
  Flooring: [
    {
      label: "Restoration",
      value: "Restoration"
    }
  ],
  "HVAC/Plumbing": [
    {
      label: "HVAC",
      value: "HVAC"
    },
    {
      label: "Electrical",
      value: "Electrical"
    },
    {
      label: "Plumbing",
      value: "Plumbing"
    }
  ],
  Interiors: [
    {
      label: "Blinds",
      value: "Blinds"
    }
  ],
  Moving: [
    {
      label: "Moving",
      value: "Moving"
    }
  ],
  "Pest Control": [
    {
      label: "Pest Control",
      value: "Pest Control"
    }
  ],
  Pools: [
    {
      label: "Pools",
      value: "Pools"
    }
  ],
  Roofing: [
    {
      label: "Roofing",
      value: "Roofing"
    }
  ],
  Security: [
    {
      label: "Security",
      value: "Security"
    }
  ],
  "Self Storage": [
    {
      label: "Self Storage",
      value: "Self Storage"
    }
  ],
  Solar: [
    {
      label: "Solar",
      value: "Solar"
    }
  ],
  Appliance: [
    {
      label: "Appliance Store",
      value: "Appliance Store"
    }
  ],
  "Bridal Shop": [
    {
      label: "Bridal Shop",
      value: "Bridal Shop"
    }
  ],
  Furniture: [
    {
      label: "Furniture",
      value: "Furniture"
    }
  ],
  Jewelry: [
    {
      label: "Engagement Rings",
      value: "Engagement Rings"
    },
    {
      label: "Fine Jewelry",
      value: "Fine Jewelry"
    }
  ],
  Mattress: [
    {
      label: "Mattress",
      value: "Mattress"
    }
  ],
  "Pawn Shop": [
    {
      label: "Pawn Shop",
      value: "Pawn Shop"
    }
  ],
  Experiences: [
    {
      label: "Adventures",
      value: "Adventures"
    },
    {
      label: "Tours",
      value: "Tours"
    }
  ],
  Lodging: [
    {
      label: "Lodging",
      value: "Lodging"
    }
  ],
  "Dental (General)": [
    {
      label: "Dentists",
      value: "Dentists"
    }
  ],
  Dermatology: [
    {
      label: "Dermatologists",
      value: "Dermatologists"
    }
  ],
  "Med Spa/Wellness Center/Skin Care": [
    {
      label: "Med Spas",
      value: "Med Spas"
    }
  ],
  "Family Practice": [
    {
      label: "Family Practice",
      value: "Family Practice"
    }
  ],
  Ophthalmology: [
    {
      label: "Ophthalmology",
      value: "Ophthalmology"
    }
  ],
  Orthodontics: [
    {
      label: "Orthodontists",
      value: "Orthodontists"
    }
  ],
  "Sports Medicine/Spine/Pain Centers": [
    {
      label: "Pain Management",
      value: "Pain Management"
    }
  ],
  "Urgent Care": [
    {
      label: "Urgent Care",
      value: "Urgent Care"
    }
  ],
  "Powersports Dealer": [
    {
      label: "Powersports Dealer",
      value: "Powersports Dealer"
    }
  ],
  "RV Dealer": [
    {
      label: "RV Dealer",
      value: "RV Dealer"
    }
  ],
  "Used Car Dealer": [
    {
      label: "Used Car Dealers",
      value: "Used Car Dealers"
    }
  ],
  "Insurance Brokerage": [
    {
      label: "Insurance Agents",
      value: "Insurance Agents"
    }
  ],
  "Law Firm": [
    {
      label: "Family Law",
      value: "Family Law"
    },
    {
      label: "Immigration Law",
      value: "Immigration Law"
    },
    {
      label: "Personal Injury Law",
      value: "Personal Injury Law"
    }
  ],
  "Mortgage Brokerage": [
    {
      label: "Mortgage Brokers",
      value: "Mortgage Brokers"
    }
  ]
};

export const statesOptions = [
  {
    label: "Alabama",
    value: "Alabama"
  },
  {
    label: "Alaska",
    value: "Alaska"
  },
  {
    label: "Arizona",
    value: "Arizona"
  },
  {
    label: "Arkansas",
    value: "Arkansas"
  },
  {
    label: "California",
    value: "California"
  },
  {
    label: "Colorado",
    value: "Colorado"
  },
  {
    label: "Connecticut",
    value: "Connecticut"
  },
  {
    label: "Delaware",
    value: "Delaware"
  },
  {
    label: "District of Columbia",
    value: "District of Columbia"
  },
  {
    label: "Florida",
    value: "Florida"
  },
  {
    label: "Georgia",
    value: "Georgia"
  },
  {
    label: "Hawaii",
    value: "Hawaii"
  },
  {
    label: "Idaho",
    value: "Idaho"
  },
  {
    label: "Illinois",
    value: "Illinois"
  },
  {
    label: "Indiana",
    value: "Indiana"
  },
  {
    label: "Iowa",
    value: "Iowa"
  },
  {
    label: "Kansas",
    value: "Kansas"
  },
  {
    label: "Kentucky",
    value: "Kentucky"
  },
  {
    label: "Louisiana",
    value: "Louisiana"
  },
  {
    label: "Maine",
    value: "Maine"
  },
  {
    label: "Maryland",
    value: "Maryland"
  },
  {
    label: "Massachusetts",
    value: "Massachusetts"
  },
  {
    label: "Michigan",
    value: "Michigan"
  },
  {
    label: "Minnesota",
    value: "Minnesota"
  },
  {
    label: "Mississippi",
    value: "Mississippi"
  },
  {
    label: "Missouri",
    value: "Missouri"
  },
  {
    label: "Montana",
    value: "Montana"
  },
  {
    label: "Nebraska",
    value: "Nebraska"
  },
  {
    label: "Nevada",
    value: "Nevada"
  },
  {
    label: "New Hampshire",
    value: "New Hampshire"
  },
  {
    label: "New Jersey",
    value: "New Jersey"
  },
  {
    label: "New Mexico",
    value: "New Mexico"
  },
  {
    label: "New York",
    value: "New York"
  },
  {
    label: "North Carolina",
    value: "North Carolina"
  },
  {
    label: "North Dakota",
    value: "North Dakota"
  },
  {
    label: "Ohio",
    value: "Ohio"
  },
  {
    label: "Oklahoma",
    value: "Oklahoma"
  },
  {
    label: "Oregon",
    value: "Oregon"
  },
  {
    label: "Pennsylvania",
    value: "Pennsylvania"
  },
  {
    label: "Rhode Island",
    value: "Rhode Island"
  },
  {
    label: "South Carolina",
    value: "South Carolina"
  },
  {
    label: "South Dakota",
    value: "South Dakota"
  },
  {
    label: "Tennessee",
    value: "Tennessee"
  },
  {
    label: "Texas",
    value: "Texas"
  },
  {
    label: "Utah",
    value: "Utah"
  },
  {
    label: "Vermont",
    value: "Vermont"
  },
  {
    label: "Virginia",
    value: "Virginia"
  },
  {
    label: "Washington",
    value: "Washington"
  },
  {
    label: "West Virginia",
    value: "West Virginia"
  },
  {
    label: "Wisconsin",
    value: "Wisconsin"
  },
  {
    label: "Wyoming",
    value: "Wyoming"
  },
  {
    label: "Ontario",
    value: "Ontario"
  },
  {
    label: "Quebec",
    value: "Quebec"
  },
  {
    label: "British Columbia",
    value: "British Columbia"
  },
  {
    label: "Alberta",
    value: "Alberta"
  },
  {
    label: "Nova Scotia",
    value: "Nova Scotia"
  },
  {
    label: "Manitoba",
    value: "Manitoba"
  },
  {
    label: "Saskatchewan",
    value: "Saskatchewan"
  },
  {
    label: "New Brunswick",
    value: "New Brunswick"
  },
  {
    label: "Newfoundland and Labrador",
    value: "Newfoundland and Labrador"
  },
  {
    label: "Prince Edward Island",
    value: "Prince Edward Island"
  }
];

export const rejectionReasons = [
  { value: "ADDITIONAL_LOCATION", label: "Additional Location" },
  { value: "BUSINESS_FOCUS", label: "Business Focus" },
  { value: "COMMERCIAL", label: "Commercial" },
  { value: "COVID_19", label: "Covid-19 (Temporarily closed or affected by Covid-19 in some way)" },
  { value: "DUPLICATE", label: "Duplicate" },
  { value: "NOT_MULTI_LOC", label: "Not Multi Location" },
  { value: "NOT_RIGHT_NOW", label: "Not Right Now" },
  { value: "NOT_STRIKEZONE", label: "Not Strikezone" },
  { value: "WRONG_SUB_VERTICAL", label: "Wrong Sub-Vertical" }
];

export const toolTips = {
  vertical:
    "Use this dropdown to select the vertical you'd like to prospect into.",
  subVertical:
    "Use this dropdown to narrow your search by selecting a sub-vertical.",
  strikezone:
    "Strikezone refers to the different business focuses that might exist within a sub-vertical, and helps to narrow down the results further. If you would like to see something else here, please reach out to someone on the moneyball team.",
  state: "Choose the state you'd like to prospect in.",
  city: "(Optional) Select the city you'd like to prospect in.",
  pullCount:
    "You can pull up to 5 leads at a time. You're welcome to pull as many times per day as you'd like.",
  postalCode:
    "(Optional) Narrow your geographical area even more by targeting a specific postal code.",
  fetchProspects:
    "Once you click this button, the Prospector will search through Google and filter out most duplicates as well as make sure that each lead passes through a set of filters that are set up. You'll be presented with leads momentarily and can either reject or accept the leads you see.",
  leadOwner:
    "This field defaults to your email. If you see a lead that should be accepted and go to someone else, change this field to their email and the lead will be uploaded to Salesforce in their name.",
  zoomInfo:
    "This is what Zoominfo reports as this company's revenue and employee count. If you see this info, there's a good chance that there is contact information for this company in Zoominfo.",
  rejectionReason:
    "If you're rejecting this lead, please provide a rejection reason.",
  hunter:
    "If you see this orange button, that means Hunter.io found at least 1 contact through the company's website.",
  submitLeads:
    "All of the leads you've accepted will be in your 'Prospects' in Salesforce. The ones you've rejected will no longer show up.",
  moneyballTab:
    "This is the upload tool which lets you upload 1 lead at a time to Salesforce.",
  nearbyTab:
    "Allows you to find existing Podium customers near a potential prospect.",
  prospector:
    "This is the Prospector tool that will give you up to 5 leads at a time by programatically searching through Google.",
  starRatingRange:
    "You will only receive leads that fall under the Google star rating range that you have set. If you don't set a range, the default values are 0.0-5.0",
  multiLoc:
    "Information that we found on this business' website indicates that there may be more than 1 location.",
  advancedAttrsToggle:
    "Click here to reveal more options for filtering prospects.",
  uploadTool:
    "A business will only be found if there is an address listed on their GMB page."
};

export const pullCountOptions = [
  {
    label: 1,
    value: 1
  },
  {
    label: 2,
    value: 2
  },
  {
    label: 3,
    value: 3
  },
  {
    label: 4,
    value: 4
  },
  {
    label: 5,
    value: 5
  },
  {
    label: 6,
    value: 6
  },
  {
    label: 7,
    value: 7
  },
  {
    label: 8,
    value: 8
  },
  {
    label: 9,
    value: 9
  },
  {
    label: 10,
    value: 10
  },
];

export const sampleCompany = {
  name: 'Podium',
  revenue: '55,000,000',
  employees: 25,
  address: '1650 W Digital Drive, Lehi, UT 84043',
  website: 'www.podium.com',
  id: 12345,
  google:
    'https://www.google.com/search?q=podium&oq=podium&aqs=chrome..69i57j69i60l5.1597j0j7&sourceid=chrome&ie=UTF-8',
  image: 'http://www.gtgraphics.org/generics/99gen_arc.jpg',
};
