import styled from 'styled-components';

export const LocationSearchInputContainer = styled.div`
  width: 90%;
  position: relative;
`;

export const PlacesSearch = styled.input`
  width: 100%;
  height: 68px;
  font-size: 16px;
  font-weight: 500;
  border: 2px solid #e4e9f0;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 12px;
  padding-right: 200px;
  &:focus {
    outline: 0;
    border-color: #4C76E0;
  }
  &::placeholder {
    color: #333;
  }
`;

export const PlacesResults = styled.div`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  color: #333;
  border-width: 2px;
  border-style: solid;
  transition: background-color 0.15s,border-color 0.15s;
  border-color: transparent;
  background-color: white;
  padding: 0.5rem;
  border-top: 0;
  margin: 0;
  position: absolute;
  z-index: 500;
  width: 100%;
  overflow: auto;
  box-shadow: 0 6px 12px rgba( 0,0,0,.24);
  max-height: 60vh;
`;

export const PlacesResult = styled.div`
  background-color: ${props => (props.active ? '#f3f3f3' : '#ffffff')};
  padding: 5px 10px 5px 10px;
  font-size: 16px;
  &:last-child {
    padding-bottom: 10px;
  }
  p:first-child {
    font-weight: 500;
    margin: 0;
  }
  p:last-child {
    margin: 0;
    font-size: 14px;
  }
`;
