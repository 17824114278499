import React, { Component } from 'react';
import Select from 'react-select';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import * as moment from 'moment';
import { Checkbox } from '@podiumhq/podium-ui';
import {
  AcceptButtonDiv,
  BottomRow,
  ButtonDiv,
  ButtonText,
  CheckboxDiv,
  CheckBoxText,
  CompanyAddress,
  CompanyCard,
  CompanyInfoDiv,
  CompanyInfoRow,
  CompanyName,
  DivOfCheckboxes,
  ZoominfoData,
  DescriptionInput,
  Divider,
  dropdownStyles,
  GetLeadButton,
  Header,
  HunterLinksButton,
  InputErrorText,
  InputForm,
  InputDiv,
  InputRow,
  LinksButton,
  NearbyCustomerDiv,
  NearbyCustomerRow,
  NotStrikezoneItalicText,
  ProspectLogo,
  RejectButtonDiv,
  RejectLeadButton,
  RejectionSelectDiv,
  TableDiv,
  ToggleSpan,
} from './styles';
import * as icon from '../../../images/icon';
import { rejectionReasons, toolTips } from '../optionData';

export default class ProspectorCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputError: {
        first_name: '',
        last_name: '',
        title: '',
        email: '',
        owner_email: '',
        phone: '',
        mobile_phone: '',
        alt_phone: '',
        locations: '',
      },
      nearbyCustomerMessage: 'Click to add to Description',
      nearbyCustomersToggled: false,
      checkBoxArr: [
        {
          key: 'bad_website',
          text: 'Bad Website',
          status: false,
        },
        {
          key: 'too_small',
          text: 'Too Small',
          status: false,
        },
        {
          key: 'rural_area',
          text: 'Rural Area',
          status: false,
        },
        {
          key: 'permanently_closed',
          text: 'Permanently Closed',
          status: false,
        },
        {
          key: 'no_email',
          text: 'No Email/Contact Info',
          status: false,
        },
      ],
    };
  }

  /* eslint-disable no-plusplus */
  getLevenschteinDistance = (orgName, locationName) => {
    if (orgName.length === 0) return locationName.length;
    if (locationName.length === 0) return orgName.length;

    const matrix = [];

    // increment along the first column of each row
    let i;
    for (i = 0; i <= locationName.length; i++) {
      matrix[i] = [i];
    }

    // increment each column in the first row
    let j;
    for (j = 0; j <= orgName.length; j++) {
      matrix[0][j] = j;
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= locationName.length; i++) {
      for (j = 1; j <= orgName.length; j++) {
        if (locationName.charAt(i - 1) === orgName.charAt(j - 1)) {
          matrix[i][j] = matrix[i - 1][j - 1];
        } else {
          matrix[i][j] = Math.min(
            matrix[i - 1][j - 1] + 1, // substitution
            Math.min(
              matrix[i][j - 1] + 1, // insertion
              matrix[i - 1][j] + 1
            )
          ); // deletion
        }
      }
    }

    return matrix[locationName.length][orgName.length];
  };

  getName = (orgName, locationName) => {
    if (this.areNamesSimilar(orgName, locationName)) {
      return `${locationName}`;
    }
    return `${orgName} - ${locationName}`;
  };

  areNamesSimilar = (orgName, locationName) => {
    const flattenedOrgName = this.flattenNameForComparison(orgName);
    const flattenedLocationName = this.flattenNameForComparison(locationName);
    const lengthOfOrgName = flattenedOrgName.length;
    const beginningOfLocation = flattenedLocationName.substr(
      0,
      lengthOfOrgName
    );
    const endOfLocation = flattenedLocationName.substr(
      lengthOfOrgName * -1,
      lengthOfOrgName
    );

    if (
      this.getLevenschteinDistance(flattenedOrgName, beginningOfLocation) <=
        3 ||
      this.getLevenschteinDistance(flattenedOrgName, endOfLocation) <= 3
    ) {
      return true;
    }
    return false;
  };

  flattenNameForComparison = (name) =>
    name
      .toLowerCase()
      .replace(/\b(the|and|of|at|in)\b/g, '')
      .replace(/\W/g, '');

  /* eslint-disable default-case */
  handleAdditionalRejectionData = (id, key) => {
    const { checkBoxArr } = this.state;

    switch(key) {
      case 'bad_website':
        this.setState(({
          checkBoxArr: checkBoxArr.map((obj) =>
            obj.key === 'bad_website'
              ? Object.assign(obj, { status: !obj.status })
              : obj
          ),
        }));
        break;
      case 'too_small':
        this.setState(({
          checkBoxArr: checkBoxArr.map((obj) =>
            obj.key === 'too_small'
              ? Object.assign(obj, { status: !obj.status })
              : obj
          ),
        }));
        break;
      case 'rural_area':
        this.setState(({
          checkBoxArr: checkBoxArr.map((obj) =>
            obj.key === 'rural_area'
              ? Object.assign(obj, { status: !obj.status })
              : obj
          ),
        }));
        break;
      case 'permanently_closed':
        this.setState(({
          checkBoxArr: checkBoxArr.map((obj) =>
            obj.key === 'permanently_closed'
              ? Object.assign(obj, { status: !obj.status })
              : obj
          ),
        }));
        break;
      case 'no_email':
        this.setState(({
          checkBoxArr: checkBoxArr.map((obj) =>
            obj.key === 'no_email'
              ? Object.assign(obj, { status: !obj.status })
              : obj
          ),
        }));
        break;
    }
    this.props.onAdditionalRejectionData(id, checkBoxArr);
  };

  handleInputValidation = (id, key, value) => {
    const inputError = { ...this.state.inputError };
    const textRegex = /^[a-zA-Z\s]+$/;
    const emailRegex = /\S+@\S+\.\S+/;
    const numberRegex = /^[0-9\b]+$/;
    const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    switch (key) {
      case 'first_name':
        if (!textRegex.test(value) && value !== '') {
          inputError.first_name = 'Invalid Text';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.first_name = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'last_name':
        if (!textRegex.test(value) && value !== '') {
          inputError.last_name = 'Invalid Text';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.last_name = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'title':
        if (!textRegex.test(value) && value !== '') {
          inputError.title = 'Invalid Text';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.title = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'phone':
        if (!phoneRegex.test(value) && value !== '') {
          inputError.phone = 'Invalid Phone #';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.phone = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'mobile_phone':
        if (!phoneRegex.test(value) && value !== '') {
          inputError.mobile_phone = 'Invalid Phone #';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.mobile_phone = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'alt_phone':
        if (!phoneRegex.test(value) && value !== '') {
          inputError.alt_phone = 'Invalid Phone #';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.alt_phone = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'locations':
        if (!numberRegex.test(value) && value !== '') {
          inputError.locations = 'Invalid Number';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.locations = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'email':
        if (!emailRegex.test(value) && value !== '') {
          inputError.email = 'Invalid Email';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.email = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
      case 'owner_email':
        if (!emailRegex.test(value) && value !== '') {
          inputError.owner_email = 'Invalid Email';
          this.props.onInputError(true);
          this.setState({ inputError });
        } else {
          inputError.owner_email = '';
          this.props.onInputError(false);
          this.setState({ inputError });
        }
        break;
    }
    this.props.onTextFieldUpdate(id, key, value);
  };

  addCustomerToDesc = (id, location) => {
    const addDescriptionText = (desc) =>
      desc.includes('Nearby Podium Customer') ? '' : 'Nearby Podium Customer: ';

    this.props.onTextFieldUpdate(
      id,
      'description',
      `${this.props.data.description} \n \n${addDescriptionText(
        this.props.data.description
      )} ${this.getName(
        location.org_name,
        location.location_name
      )} | ${location.distance_to_target
        .toString()
        .substr(0, 4)} mi. | ${moment(
        location.location_active_start_date
      ).format('l')} mo. | ${location.reviews_all_time} Google reviews | ${
        location.reviews_since_joining_podium
      } reviews since joining Podium
      `
    );
    this.setState({
      nearbyCustomerMessage: 'Customer Added!',
    });
  };

  resetCustomerTooltip = () => {
    this.setState({
      nearbyCustomerMessage: 'Click to add to Description',
    });
  };

  formatNumber = (num) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(num)) return num;
    if (num < 9999) {
      return num;
    }
    if (num < 1000000) {
      return `${Math.round(num / 1000)}K`;
    }
    if (num < 10000000) {
      return `${(num / 1000000).toFixed(2)}M`;
    }
    if (num < 1000000000) {
      return `${Math.round(num / 1000000)}M`;
    }
    if (num < 1000000000000) {
      return `${Math.round(num / 1000000000)}B`;
    }
    return '1T+';
  };

  /* eslint-disable camelcase */
  formatHours = (store_hours) => {
    if (store_hours !== null) {
      return (
        <React.Fragment>
          <p className="storeHoursTitle">Store Hours</p>
          <ul>
            {store_hours.map((hours) => {
              return <li>{hours}</li>;
            })}
          </ul>
        </React.Fragment>
      );
    }
    return <p className="storeHoursTitle">No store hours found.</p>;
  };

  avgRatingScore = (existingCustomer) => {
    return existingCustomer.avg_review_rating_90days_before_podium
      ? Math.round(
          (existingCustomer.avg_review_rating_last_90days -
            existingCustomer.avg_review_rating_90days_before_podium) *
            10
        ) / 10
      : 0;
  };

  reviewCountIncreasePercentile = (existingCustomer) => {
    return existingCustomer.reviews_30days_before_podium
      ? (existingCustomer.reviews_last_30days -
          existingCustomer.reviews_30days_before_podium) /
          existingCustomer.reviews_30days_before_podium
      : 0;
  };

  calculateCustomerReviewScore = (existingCustomer) => {
    const avgRatingScore = this.avgRatingScore(existingCustomer);
    const reviewCountIncreasePercentile = this.reviewCountIncreasePercentile(
      existingCustomer
    );
    return avgRatingScore > 0
      ? avgRatingScore + reviewCountIncreasePercentile / 100
      : reviewCountIncreasePercentile / 100;
  };

  handleRedirectWithCompanyUrl = (url) => {
    window.open(url, '_blank');
  };

  handleToggleNearbyCustomers = () => {
    this.setState({
      nearbyCustomersToggled: !this.state.nearbyCustomersToggled,
    });
  };

  render() {
    /* eslint-disable camelcase */
    /* eslint-disable react/jsx-curly-brace-presence */
    /* eslint-disable prefer-template */
    const {
      id,
      website,
      google_search,
      google_photos,
      google_rating,
      google_review_count,
      store_hours,
      company_name,
      street_address,
      city,
      contacts,
      state,
      country,
      postal_code,
      zoominfo_employee_count,
      zoominfo_revenue,
      nearby_locations,
      first_name,
      last_name,
      title,
      phone,
      mobile_phone,
      alt_phone,
      email,
      locations,
      description,
      in_strikezone,
      rejection_reason,
      owner_email,
      multi_loc_indicator,
    } = this.props.data;

    const {
      inputError,
      nearbyCustomerMessage,
      nearbyCustomersToggled,
    } = this.state;

    return (
      <CompanyCard>
        <Header>
          <div>
            <strong>ID: {id}</strong>
          </div>
          <div>
            <LinksButton
              onClick={() => this.handleRedirectWithCompanyUrl(google_search)}
            >
              <div className="googleButton">
                <img src={icon.google} alt="Google" className="googleIcon" />
                Google
              </div>
            </LinksButton>
            <LinksButton
              onClick={() => this.handleRedirectWithCompanyUrl(website)}
            >
              Website
            </LinksButton>
            {contacts && contacts.length > 0 && (
              <HunterLinksButton
                className="open-modal-btn"
                onClick={() => this.props.toggleContactModal(id, contacts)}
              >
                <div className="googleButton">
                  <Tooltip
                    title={toolTips.hunter}
                    position="top"
                    trigger="mouseenter"
                    size="big"
                    delay={300}
                    hideDelay={0}
                    arrow
                  >
                    <img src={icon.hunterWhite} alt="" className="hunterIcon" />
                  </Tooltip>
                  {`Found ${contacts ? contacts.length : '0'} Contacts`}
                </div>
              </HunterLinksButton>
            )}
          </div>
        </Header>
        <CompanyInfoRow>
          <TableDiv>
            <a
              href={google_photos[0] || ''}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ProspectLogo
                alt="picture"
                src={google_photos[0] || icon.dwight}
              />
            </a>
          </TableDiv>
          <CompanyInfoDiv>
            <CompanyName>{company_name}</CompanyName>
            <CompanyAddress>
              {street_address}, {city}, {state} {postal_code} | {country}
            </CompanyAddress>
            <ZoominfoData>
              <Tooltip
                title={toolTips.zoomInfo}
                position="top"
                trigger="mouseenter"
                size="big"
                arrow
                delay={300}
              >
                <img src={icon.employee} alt="employee" className="employee" />
                {zoominfo_employee_count === -1 || !zoominfo_employee_count
                  ? 'Not Found'
                  : `${zoominfo_employee_count} employees`}
                <img src={icon.revenue} alt="money" className="revenue" />
                {zoominfo_revenue === -1 || !zoominfo_revenue
                  ? 'Not Found'
                  : `$${this.formatNumber(zoominfo_revenue)}`}
              </Tooltip>
              {multi_loc_indicator && (
                <Tooltip
                  title={toolTips.multiLoc}
                  position="right"
                  trigger="mouseenter"
                  size="big"
                  arrow
                  delay={300}
                >
                  <span className="multiLocation">Multiple Locations</span>
                </Tooltip>
              )}
            </ZoominfoData>
          </CompanyInfoDiv>
        </CompanyInfoRow>
        <BottomRow>
          <InputRow>
            <ButtonDiv>
              <AcceptButtonDiv>
                <GetLeadButton
                  onClick={() => this.props.onDecisionButtonClick(id, 'yes')}
                  selected={in_strikezone}
                >
                  {(in_strikezone === 'no' || !in_strikezone) && (
                    <img
                      src={icon.accept}
                      alt="checkMark"
                      className="checkMark"
                    />
                  )}
                  {in_strikezone === 'yes' && (
                    <img
                      src={icon.checkClicked}
                      alt="checkMark"
                      className="checkMark"
                    />
                  )}
                </GetLeadButton>
                <ButtonText>Accept</ButtonText>
              </AcceptButtonDiv>
              <RejectButtonDiv>
                <RejectLeadButton
                  onClick={() => this.props.onDecisionButtonClick(id, 'no')}
                  selected={in_strikezone}
                >
                  {(in_strikezone === 'yes' || !in_strikezone) && (
                    <img src={icon.reject} alt="xMark" className="xMark" />
                  )}
                  {in_strikezone === 'no' && (
                    <img
                      src={icon.xClicked}
                      alt="xClicked"
                      className="checkMark"
                    />
                  )}
                </RejectLeadButton>
                <ButtonText>Reject</ButtonText>
              </RejectButtonDiv>
              <br />
            </ButtonDiv>
            <div className="placesDetailsDiv">
              <div className="googleRating">
                {google_rating !== 0 &&
                  `${google_review_count} reviews | ${google_rating}/5 Stars`}
                {google_review_count === 0 &&
                  google_rating === 0 &&
                  'No Google reviews found.'}
              </div>
              <Divider />
              <div className="storeHours">{this.formatHours(store_hours)}</div>
            </div>
          </InputRow>
          <InputRow>
            {in_strikezone !== 'no' && (
              <InputDiv>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="First Name"
                    type="text"
                    className="first_name"
                    value={first_name}
                    status={inputError.first_name}
                    onChange={(e) =>
                      this.handleInputValidation(
                        id,
                        'first_name',
                        e.target.value
                      )
                    }
                  />
                  {inputError.first_name && (
                    <InputErrorText>{inputError.first_name}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="Last Name"
                    type="text"
                    className="last_name"
                    value={last_name}
                    status={inputError.last_name}
                    onChange={(e) =>
                      this.handleInputValidation(
                        id,
                        'last_name',
                        e.target.value
                      )
                    }
                  />
                  {inputError.last_name && (
                    <InputErrorText>{inputError.last_name}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="Title"
                    type="text"
                    className="title"
                    value={title}
                    status={inputError.title}
                    onChange={(e) =>
                      this.handleInputValidation(id, 'title', e.target.value)
                    }
                  />
                  {inputError.title && (
                    <InputErrorText>{inputError.title}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="Telephone #"
                    type="tel"
                    className="phone"
                    value={phone}
                    status={inputError.phone}
                    onChange={(e) =>
                      this.handleInputValidation(id, 'phone', e.target.value)
                    }
                  />
                  {inputError.phone && (
                    <InputErrorText>{inputError.phone}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="Mobile #"
                    type="tel"
                    className="mobile"
                    value={mobile_phone}
                    status={inputError.mobile_phone}
                    onChange={(e) =>
                      this.handleInputValidation(
                        id,
                        'mobile_phone',
                        e.target.value
                      )
                    }
                  />
                  {inputError.mobile_phone && (
                    <InputErrorText>{inputError.mobile_phone}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="Alt Phone #"
                    type="tel"
                    className="alt"
                    value={alt_phone}
                    status={inputError.alt_phone}
                    onChange={(e) =>
                      this.handleInputValidation(
                        id,
                        'alt_phone',
                        e.target.value
                      )
                    }
                  />
                  {inputError.alt_phone && (
                    <InputErrorText>{inputError.alt_phone}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="Email Address"
                    type="email"
                    className="email"
                    value={email}
                    status={inputError.email}
                    onChange={(e) =>
                      this.handleInputValidation(id, 'email', e.target.value)
                    }
                  />
                  {inputError.email && (
                    <InputErrorText>{inputError.email}</InputErrorText>
                  )}
                </div>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="# of Locations"
                    type="number"
                    className="locations"
                    value={locations}
                    status={inputError.locations}
                    onChange={(e) =>
                      this.handleInputValidation(
                        id,
                        'locations',
                        e.target.value
                      )
                    }
                  />
                  {inputError.locations && (
                    <InputErrorText>{inputError.locations}</InputErrorText>
                  )}
                </div>
                <div className="descriptionDiv">
                  <span className="descriptionText">Description:</span>
                  <DescriptionInput
                    placeholder="Description"
                    type="text"
                    className="description"
                    value={description}
                    onChange={(e) =>
                      this.props.onTextFieldUpdate(
                        id,
                        'description',
                        e.target.value
                      )
                    }
                    textarea
                  />
                </div>
                <Tooltip
                  title={toolTips.leadOwner}
                  position="left"
                  trigger="mouseenter"
                  size="big"
                  arrow
                  delay={200}
                >
                  <span className="leadOwnerText">Lead owner:</span>
                </Tooltip>
                <div id="formAndErrorContainer">
                  <InputForm
                    placeholder="first.last@podium.com"
                    type="email"
                    className="ownerEmail"
                    value={owner_email}
                    status={inputError.owner_email}
                    onChange={(e) =>
                      this.handleInputValidation(
                        id,
                        'owner_email',
                        e.target.value
                      )
                    }
                  />
                  {inputError.owner_email && (
                    <InputErrorText>{inputError.owner_email}</InputErrorText>
                  )}
                </div>
              </InputDiv>
            )}
            {in_strikezone === 'no' && (
              <RejectionSelectDiv>
                <Tooltip
                  title={toolTips.rejectionReason}
                  position="top"
                  trigger="mouseenter"
                  size="big"
                  arrow
                  delay={400}
                  hideDelay={0}
                >
                  <Select
                    isSearchable
                    placeholder={
                      rejection_reason !== ''
                        ? rejection_reason
                        : 'Reason for rejection'
                    }
                    options={rejectionReasons}
                    value={rejection_reason}
                    styles={dropdownStyles}
                    onChange={(reason) =>
                      this.props.onRejectSelectUpdate(id, reason)
                    }
                    className="rejectionReasonDropdown"
                  />
                </Tooltip>
                {rejection_reason === 'NOT_STRIKEZONE' && (
                  <>
                    <NotStrikezoneItalicText>Please select one or more of the reasons below...</NotStrikezoneItalicText>
                    <DivOfCheckboxes>
                      <CheckboxDiv>
                        <Checkbox
                          onChange={() =>
                            this.handleAdditionalRejectionData(id, 'bad_website')
                          }
                        />
                        <CheckBoxText>Bad Website</CheckBoxText>
                      </CheckboxDiv>
                      <CheckboxDiv>
                        <Checkbox
                          onChange={() =>
                            this.handleAdditionalRejectionData(id, 'too_small')
                          }
                        />
                        <CheckBoxText>Too Small</CheckBoxText>
                      </CheckboxDiv>
                      <CheckboxDiv>
                        <Checkbox
                          onChange={() =>
                            this.handleAdditionalRejectionData(id, 'rural_area')
                          }
                        />
                        <CheckBoxText>Rural Area</CheckBoxText>
                      </CheckboxDiv>
                      <CheckboxDiv>
                        <Checkbox
                          onChange={() =>
                            this.handleAdditionalRejectionData(
                              id,
                              'permanently_closed'
                            )
                          }
                        />
                        <CheckBoxText>Permanently Closed</CheckBoxText>
                      </CheckboxDiv>
                      <CheckboxDiv>
                        <Checkbox
                          onChange={() =>
                            this.handleAdditionalRejectionData(
                              id,
                              'no_email'
                            )
                          }
                        />
                        <CheckBoxText>No Email/Contact Info</CheckBoxText>
                      </CheckboxDiv>
                    </DivOfCheckboxes>
                  </>
                )}
              </RejectionSelectDiv>
            )}
          </InputRow>
        </BottomRow>
        <NearbyCustomerDiv>
          <ToggleSpan
            id="toggleSpan"
            onClick={this.handleToggleNearbyCustomers}
          >
            Click Here to see Nearby Podium Customers{' '}
            {nearbyCustomersToggled === false ? (
              <img src={icon.dropdown} className="dropdown" alt="" />
            ) : (
              <img src={icon.carrot} className="dropdown" alt="" />
            )}
          </ToggleSpan>
          {nearbyCustomersToggled && (
            <div>
              {nearby_locations.map((location) => {
                return (
                  <Tooltip
                    title={`${nearbyCustomerMessage} <br> ${
                      location.reviews_all_time + ' total reviews'
                    } <br> ${
                      location.reviews_since_joining_podium +
                      ' reviews since joining Podium'
                    } <br> ${
                      this.avgRatingScore(location) > 0
                        ? this.avgRatingScore(location) +
                          ' ★ increase in avg rating since joining Podium'
                        : Math.round(
                            location.avg_review_rating_last_90days * 10
                          ) /
                            10 +
                          ' ★ average review rating in last 90 days'
                    } <br> ${
                      location.reviews_last_30days +
                      ' review(s) in last 30 days'
                    } `}
                    position="top"
                    trigger="mouseenter"
                    size="small"
                    hideOnClick={false}
                    onHide={this.resetCustomerTooltip}
                  >
                    <NearbyCustomerRow
                      onClick={() => this.addCustomerToDesc(id, location)}
                      status={this.calculateCustomerReviewScore(location)}
                    >
                      <div id="nameDiv">
                        <img src={icon.podium} alt="name" className="podium" />
                        <p id="name">
                          {this.getName(
                            location.org_name,
                            location.location_name
                          )}
                        </p>
                      </div>
                      <div id="distanceDiv">
                        <img
                          src={icon.mapMarker}
                          alt="distance"
                          className="icon"
                        />
                        <p id="distance">
                          {location.distance_to_target.toString().substr(0, 4)}{' '}
                          mi
                        </p>
                      </div>
                      <div id="customerSinceDiv">
                        <img
                          src={icon.calendar}
                          alt="calendar"
                          className="icon"
                        />
                        <p id="date">
                          {moment().diff(
                            location.location_active_start_date,
                            'months'
                          )}{' '}
                          months
                        </p>
                      </div>
                      <div id="verticalDiv">
                        <img
                          src={icon.business}
                          alt="vertical"
                          className="businessIcon"
                        />
                        <p id="vertical">{location.vertical_name}</p>
                      </div>
                    </NearbyCustomerRow>
                  </Tooltip>
                );
              })}
            </div>
          )}
        </NearbyCustomerDiv>
      </CompanyCard>
    );
  }
}
