import React from 'react';
import { getGooglePlaceTypes } from '../../../lib/client';
import {
  GooglePlaceTypesDiv,
  GooglePlaceTypesButton,
  GooglePlaceTypesInput,
  GooglePlaceTypesRow,
  LoadingSpinner,
  PlaceTypesSearchDiv,
  SpinnerContainer,
  Tag
} from './styles';

export default class GooglePlaceTypesSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      typesObject: {},
      typesSearchString: ''
    }
  }


  getAllTypes = async () => {
    this.setState({ loading: true, typesObject: {} });
    const listOfGoogleTypes = await getGooglePlaceTypes(this.state.typesSearchString);
    this.getTypesObject(listOfGoogleTypes);
  };

  /* eslint-disable no-param-reassign */
  /* eslint-disable no-plusplus */
  /* eslint-disable no-param-reassign */
  getTypesObject = arrayOfTypes => {
    const occurences = arrayOfTypes.flat().reduce((obj, type) => {
      obj[type] = ++obj[type] || 1;
      return obj;
    }, {});
    this.setState({
      loading: false,
      typesObject: occurences
    })
    return occurences;
  };

  handleTypesInput = searchString =>
    this.setState({
      typesSearchString: searchString
    });

  render() {
    const { loading, typesObject, typesSearchString } = this.state;

    return (
      <div>
        <PlaceTypesSearchDiv>
          <GooglePlaceTypesInput
            placeholder="Ex: plumber in salt lake city"
            type="text"
            className={typesSearchString}
            onChange={e => this.handleTypesInput(e.target.value)}
          />
          <GooglePlaceTypesButton onClick={this.getAllTypes}>
            Search
          </GooglePlaceTypesButton>
        </PlaceTypesSearchDiv>
        <React.Fragment>
          <GooglePlaceTypesDiv>
            {loading &&
            <SpinnerContainer>
              <LoadingSpinner width={100} height={100} color="#4B76E0" />
            </SpinnerContainer>
            }
            <React.Fragment>
              {Object.keys(typesObject).map((key) => {
                return (
                  <React.Fragment>
                    <GooglePlaceTypesRow>
                      <span id="key">
                        <Tag>{key}</Tag>
                      </span>
                      <span id="value">
                        { ` shows up ${typesObject[key]} times.` }
                      </span>
                    </GooglePlaceTypesRow> 
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          </GooglePlaceTypesDiv>
        </React.Fragment>
      </div>
    );
  }

};
