import styled from 'styled-components';
import Spinner from '../../../components/Spinner/Spinner';

export const CheckDiv = styled.div`
  display: inline-block;
  margin-top: 20px;
  padding: 3px;
`;

export const LoadingSpinner = styled(Spinner)`
  margin: auto;
`;

export const StageContainer = styled.div`
  display: inline-block;
  padding: 3px;
  width: 50%;
  vertical-align: top;
`;
