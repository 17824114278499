import styled from 'styled-components';
import colors from '../../styles/colors';

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  padding: 36px;
  max-width: 850px;
  border-radius: 4px;

  .optimizer-icon {
    background: #da6ed3;
    border-radius: 50%;
    padding: 12px;
    margin-right: 16px;
  }

  ${props => (props.selected ? `
    background-color: ${colors.white};
    border: 2px solid ${colors.bearLake};
  ` : `
    background-color: ${colors.whiteSmoke};
    border: 2px solid ${colors.whiteSmoke};
  `)}

  .content {
    max-width: 650px;
    width: 200%;
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 600;
      margin-bottom: 6px;
      font-size: 14px;
    }
    .titleSection {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      justify-content: center;
    }
  }

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    background-color: ${colors.mineShaft};
  }
`;


export const PriceContainer = styled.div`
  font-weight: 700;
  font-size: 18px;
  color: #333333;

  .duration {
    font-weight: normal;
    font-size: 15px;
  }
`;
