import styled from 'styled-components';
import { IconButton } from '../styles';

export const PositiveResultContainer = styled.div`
  h1 {
    color: #333333;
    margin-top: 0;
    &.disabled {
      color: #e6e6e6;
    }
    padding-top: 34px;
  }
`;

export const UploadButton = styled(IconButton)`
  display: flex;
  margin: 0 auto;
  margin-top: 24px;
`;

export const Score = styled.div`
  position: relative;
  height: 44px;
  line-height: 44px;
  display: table;
  margin: 0 auto 32px auto;
  padding: 0 12px;
  background-color: ${props => (props.disabled ? '#e6e6e6' : '#5280f2')}
  color: ${props => (props.disabled ? '#c3c3c3' : '#ffffff')}
  font-size: 20px;
  font-weight: 500;

  ${(props) => {
    if (!props.disabled) {
      return (`
        ::after {
        content: '';
        height: 2px;
        width: 710px;
        position: absolute;
        background-color: #eceef3;
        bottom: -32px;
      }`);
    }
    return null;
  }}
`;

export const ScoreSelect = styled.div`
  width: 200px;
  label div:last-child {
    border-color: transparent;
    margin: 0;
    height: 40px;
    line-height: 38px;
  }
`;

export const ScoreFormContainer = styled.div`
  width: 90%;
  margin: 0 auto;

  .score-input {
    background-color: white;
    margin-bottom: 16px;
  }
`;

export const CityStateZip = styled.div`
  display: flex;
  > div:nth-child(1) {
    width: 75%;
    margin-right: 16px;
  }
  > div:nth-child(2) {
    margin-right: 16px;
  }
  > div:nth-child(3) {
    margin-right: 16px;
  }
`;

export const Section = styled.div`
  ::after {
    content: '';
    height: 2px;
    width: 710px;
    position: absolute;
    background-color: #eceef3;
  }

  h4 {
    font-weight: 600;
    margin: 0 0 13px 0;
    padding-top: 20px;
  }

  h4 ~ div label > div {
    margin-bottom: 6px;
    font-size: 14px;
    font-weight: 500;
  }

  textarea {
    background: white;
    min-height: 130px;
    margin-bottom: 16px;
    &:hover {
      background: white;
    }
  }
`;

export const TwoColumns = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
> div {
    width: 48.85%;
  }
`;

export const UploadSuccess = styled.div`
  margin: 0;
  p {
    text-align: center;
  }
`;
