import React from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { getDropDowns, strikezoneByName, updateStrikezone } from '../../../lib/client';
import {
  // Divider,
  Section,
  SectionContents,
  VerticalSelect,
  SubverticalSelect,
  // StateSelect
} from '../../Packaged/styles';
import {
  // CountInput,
  dropdownStyles,
  ProspectorSectionTitle,
} from '../../Prospector/styles';
import { StrikezoneButton, StrikezoneDiv, StrikezoneInfoRow, StrikezoneInput, Tag, TagList, TheInputDiv } from './styles';

const DEFAULT_VERTICAL = { label: 'Retail', value: 'retail', id: 6 };
const DEFAULT_SUBVERTICAL = { label: 'Appliance', value: 'Appliance' };
const DEFAULT_STRIKEZONE = {
  label: 'Appliance Store',
  value: 'Appliance Store'
};
const VERTICAL_ENDPOINT = '/fetch-verticals';
const SUBVERTICAL_ENDPOINT = '/fetch-sub-verticals';
const STRIKEZONE_ENDPOINT = '/fetch-strikezones';
const SET_DEFAULT_OPTIONS = {
  retail: [
    {
      label: 'Retail',
      value: 'retail'
    }
  ]
};
const DEFAULT_SUB_OPTIONS = {
  retail: [
    {
      label: 'Appliance',
      value: 'Appliance'
    }
  ]
};

const DEFAULT_STRIKE_OPTIONS = {
  retail: [
    {
      label: 'Appliance Store',
      value: 'Appliance Store'
    }
  ]
};

export default class UpdateStrikezone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVertical:
        JSON.parse(localStorage.getItem('chosenVertical')) || DEFAULT_VERTICAL,
      selectedVerticalId:
        parseInt(localStorage.getItem('chosenVerticalId'), 10) ||
        DEFAULT_VERTICAL.id,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        DEFAULT_SUBVERTICAL,
      selectedStrikezone:
        JSON.parse(localStorage.getItem('chosenStrikezone')) ||
        DEFAULT_STRIKEZONE,
      selectedSubverticalOptions: DEFAULT_SUB_OPTIONS.retail,
      selectedStrikezoneOptions: DEFAULT_STRIKE_OPTIONS.retail,
      selectedVerticalOptions: SET_DEFAULT_OPTIONS.retail,
      showStrikezoneDiv: false,
      strikezoneToUpdate: ""
    }
  }

  componentDidMount() {
    getDropDowns(VERTICAL_ENDPOINT).then(res => {
      return this.processVerticals(res);
    });

    getDropDowns(SUBVERTICAL_ENDPOINT)
      .then(res => {
        return res;
      })
      .then(subVerts => {
        getDropDowns(STRIKEZONE_ENDPOINT).then(res => {
          return this.processStrikeZonesAndSubverticals(res, subVerts);
        });
      });
  }

  fetchStrikezone = async (strikezone) => {
    const sz = await strikezoneByName(strikezone);
    this.setState({
      strikezoneToUpdate: sz,
      showStrikezoneDiv: true
    })
    return sz;
  };

  processVerticals = res => {
    const selectedVerticalOptions = res.map(v => {
      return { label: v.name, value: v.name, id: v.id };
    });

    this.setState({ selectedVerticalOptions });
  };

  processStrikeZonesAndSubverticals = (res, subVerticals) => {
    const formatedStrikeZone = {};
    const validSubVerticals = [];
    const { selectedVerticalId } = this.state;

    res.map(strikezone => {
      const subVerticalWithStrikezone = _.find(subVerticals, obj => {
        return obj.id === strikezone.sub_vertical_id;
      });
      const formatedSubVertical = {
        label: subVerticalWithStrikezone.name,
        value: subVerticalWithStrikezone.name,
        vertical_id: subVerticalWithStrikezone.vertical_id
      };

      if (!_.some(validSubVerticals, formatedSubVertical)) {
        validSubVerticals.push(formatedSubVertical);
      }

      if (formatedStrikeZone[subVerticalWithStrikezone.name]) {
        formatedStrikeZone[subVerticalWithStrikezone.name].push({
          value: strikezone.name,
          label: strikezone.name,
          ...strikezone
        });
      } else {
        formatedStrikeZone[subVerticalWithStrikezone.name] = [
          {
            value: strikezone.name,
            label: strikezone.name,
            ...strikezone
          }
        ];
      }

      return formatedStrikeZone;
    });

    const selectedSubverticalOptions = _.filter(
      validSubVerticals,
      sub => sub.vertical_id === selectedVerticalId
    );

    const selectedStrikezone =
      JSON.parse(localStorage.getItem('chosenStrikezone')) ||
      formatedStrikeZone[selectedSubverticalOptions[0].value][0];

    this.setState({
      validSubVerticals,
      strikezoneOptions: formatedStrikeZone,
      selectedSubverticalOptions,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        selectedSubverticalOptions[0],
      selectedStrikezoneOptions:
        formatedStrikeZone[this.state.selectedSubvertical.value],
      selectedStrikezone
    });

    return formatedStrikeZone;
  };

  // Handles
  handleVerticalChange = option => {
    localStorage.setItem('chosenVertical', JSON.stringify(option));
    const { validSubVerticals, selectedVerticalOptions } = this.state;

    const selectedOption = _.find(
      selectedVerticalOptions,
      optn => optn.value === option.value
    );
    localStorage.setItem('chosenVerticalId', selectedOption.id);

    const selectedSubverticalOptions = _.filter(
      validSubVerticals,
      sub => sub.vertical_id === selectedOption.id
    );
    const hasOneSubvertical = selectedSubverticalOptions.length > 0;

    this.setState({
      selectedVertical: option,
      selectedSubverticalOptions,
      selectedSubvertical: hasOneSubvertical
        ? selectedSubverticalOptions[0]
        : ''
    });
    if (hasOneSubvertical) {
      this.handleSubverticalChange(selectedSubverticalOptions[0]);
    }
  };

  handleSubverticalChange = option => {
    const { strikezoneOptions } = this.state;
    localStorage.setItem('chosenSubvertical', JSON.stringify(option));
    localStorage.setItem(
      'chosenStrikezone',
      JSON.stringify(strikezoneOptions[option.value][0])
    );

    this.setState({
      selectedSubvertical: option,
      selectedStrikezoneOptions: strikezoneOptions[option.value],
      selectedStrikezone: strikezoneOptions[option.value]
        ? strikezoneOptions[option.value][0]
        : ''
    });

    if (strikezoneOptions[option.value]) {
      this.handleStrikezoneChange(strikezoneOptions[option.value][0]);
    }
  };

  handleStrikezoneChange = option => {
    localStorage.setItem('chosenStrikezone', JSON.stringify(option));
    this.setState({
      selectedStrikezone: option
    });
    this.fetchStrikezone(option.value);
  };

  /* eslint-disable no-shadow */
  /* eslint-disable no-unused-expressions */
  /* eslint-disable react/no-array-index-key */
  /* eslint-disable no-param-reassign */
  handleAddFalsePositives = event => {
    const { strikezoneToUpdate } = this.state;
    const val = event.target.value;
    if (event.key === 'Enter' && val) {
      if (strikezoneToUpdate.false_positive_company_names.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      this.setState({
        strikezoneToUpdate: {...strikezoneToUpdate, false_positive_company_names: [...strikezoneToUpdate.false_positive_company_names, val]}
      })
      event.target.value = '';
    }
  };

  handleRemoveFalsePositives = indexToRemove => {
    const { strikezoneToUpdate } = this.state;
    const updated = strikezoneToUpdate.false_positive_company_names.filter((x, index) => index !== indexToRemove);

    this.setState({
      strikezoneToUpdate: {...strikezoneToUpdate, false_positive_company_names: updated}
    })
  };

  handleSubmitUpdatedStrikezone = async (sz) => {
    await updateStrikezone(sz);
  };

  handleAddSearchStrings = event => {
    const { strikezoneToUpdate } = this.state;
    const val = event.target.value;
    if (event.key === 'Enter' && val) {
      if (strikezoneToUpdate.search_strings.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      this.setState({
        strikezoneToUpdate: {...strikezoneToUpdate, search_strings: [...strikezoneToUpdate.search_strings, val]}
      })
      event.target.value = '';
    }
  };

  handleRemoveSearchStrings = indexToRemove => {
    const { strikezoneToUpdate } = this.state;
    const updated = strikezoneToUpdate.search_strings.filter((x, index) => index !== indexToRemove);

    this.setState({
      strikezoneToUpdate: {...strikezoneToUpdate, search_strings: updated}
    })
  };

  handleSubmitUpdatedStrikezone = async (sz) => {
    await updateStrikezone(sz);
  };

  render() {
    const {
      selectedStrikezone,
      selectedStrikezoneOptions,
      selectedSubverticalOptions,
      selectedSubvertical,
      selectedVertical,
      selectedVerticalOptions,
      showStrikezoneDiv,
      strikezoneToUpdate,
    } = this.state;
    return (
      <React.Fragment>
        <Section>
          <ProspectorSectionTitle>
            <div>VERTICAL</div>
          </ProspectorSectionTitle>
          <SectionContents>
            <VerticalSelect data-tip>
              <Select
                isSearchable
                options={selectedVerticalOptions}
                styles={dropdownStyles}
                className={selectedVertical}
                value={selectedVertical}
                onChange={option => this.handleVerticalChange(option)}
              />
            </VerticalSelect>
          </SectionContents>
        </Section>
        <Section>
          <ProspectorSectionTitle>
            <div>SUBVERTICAL</div>
          </ProspectorSectionTitle>
          <SectionContents>
            <SubverticalSelect>
              <Select
                isSearchable
                styles={dropdownStyles}
                options={selectedSubverticalOptions}
                value={selectedSubvertical}
                className={selectedSubvertical}
                onChange={option => this.handleSubverticalChange(option)}
              />
            </SubverticalSelect>
          </SectionContents>
        </Section>
        <Section>
          <ProspectorSectionTitle>
            <div>STRIKEZONE</div>
          </ProspectorSectionTitle>
          <SectionContents>
            <SubverticalSelect>
              <Select
                isSearchable
                styles={dropdownStyles}
                options={selectedStrikezoneOptions}
                value={selectedStrikezone}
                className={selectedStrikezone}
                onChange={option => this.handleStrikezoneChange(option)}
              />
            </SubverticalSelect>
          </SectionContents>
        </Section>
        <Section>
          {showStrikezoneDiv &&
            <StrikezoneDiv>
              <h1>{strikezoneToUpdate.name}</h1>
              <div id="rows">
                <StrikezoneInfoRow>
                  <div id="text">
                    {`Companies we avoid: `}
                  </div>
                  <TheInputDiv>
                    <TagList>
                      {strikezoneToUpdate.false_positive_company_names.map((tag, index) => (
                        <Tag key={index}>
                          <span className="tag-title">{tag}</span>
                          <span role="button" tabIndex={index} className="tag-close-icon" onClick={() => this.handleRemoveFalsePositives(index)}>
                            x
                          </span>
                        </Tag>
                      ))}
                    </TagList>
                    <StrikezoneInput
                      type="text"
                      onKeyDown={this.handleAddFalsePositives}
                    />
                  </TheInputDiv>
                </StrikezoneInfoRow>
                <StrikezoneInfoRow>
                  <div id="text">
                    {`Search terms: `}
                  </div>
                  <TheInputDiv>
                    <TagList>
                      {strikezoneToUpdate.search_strings.map((tag, index) => (
                        <Tag key={index}>
                          <span className="tag-title">{tag}</span>
                          <span role="button" tabIndex={index} className="tag-close-icon" onClick={() => this.handleRemoveSearchStrings(index)}>
                            x
                          </span>
                        </Tag>
                      ))}
                    </TagList>
                    <StrikezoneInput
                      type="text"
                      onKeyDown={this.handleAddSearchStrings}
                    />
                  </TheInputDiv>
                </StrikezoneInfoRow>
                <StrikezoneInfoRow>
                  <div id="text">
                    {`Required Google Place Types: `}
                  </div>
                  <TheInputDiv>
                    <TagList>
                      {strikezoneToUpdate.required_google_place_types.map((tag, index) => (
                        <Tag key={index} status="disabled">
                          <span className="tag-title">{tag}</span>
                          {/* <span role="button" tabIndex={index} className="tag-close-icon" onClick="#">
                            x
                          </span> */}
                        </Tag>
                      ))}
                    </TagList>
                    {/* <StrikezoneInput
                      disabled
                      type="text"
                      onKeyDown={this.handleAddTags}
                    /> */}
                  </TheInputDiv>
                </StrikezoneInfoRow>
                <StrikezoneInfoRow>
                  <div id="text">
                    {`Optional Google Place Types: `}
                  </div>
                  <TheInputDiv>
                    <TagList>
                      {strikezoneToUpdate.optional_google_place_types.map((tag, index) => (
                        <Tag key={index} status="disabled">
                          <span className="tag-title">{tag}</span>
                          {/* <span role="button" tabIndex={index} className="tag-close-icon" onClick="#">
                            x
                          </span> */}
                        </Tag>
                      ))}
                    </TagList>
                    {/* <StrikezoneInput
                      disabled
                      type="text"
                      onKeyDown={this.handleAddTags}
                    /> */}
                  </TheInputDiv>
                </StrikezoneInfoRow>
              </div>
              <React.Fragment>
                <StrikezoneButton onClick={() => this.handleSubmitUpdatedStrikezone(strikezoneToUpdate)}>
                  Submit
                </StrikezoneButton>
              </React.Fragment>
            </StrikezoneDiv>
          }
        </Section>
      </React.Fragment>
    );
  }
};
