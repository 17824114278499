import { v4 as uuidv4 } from 'uuid';
import { firebaseAuth, googleProvider } from './firebase-config';

export const IS_AUTHENTICATING = 'firebaseAuthInProgress';
export const CURRENT_USER = 'currentUser';
export const SESSION_UID = 'sessionUID';
export const APP_TOKEN_KEY = 'appTokenKey';

export function login() {
  googleProvider.setCustomParameters({
    prompt: 'select_account'
  });
  return firebaseAuth().signInWithRedirect(googleProvider);
}

export const getCurrentUser = () => JSON.parse(localStorage.getItem(CURRENT_USER));
export const setCurrentUser = user => { 
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));
  localStorage.setItem(SESSION_UID, uuidv4());
}
export const unsetCurrentUser = () => {
  localStorage.removeItem(CURRENT_USER);
  localStorage.removeItem(SESSION_UID);
}

export const getSessionUID = () => {
  let sessionUID = localStorage.getItem(SESSION_UID);

  if ( typeof sessionUID == 'undefined' || !sessionUID ) {
    sessionUID = uuidv4()
    localStorage.setItem(SESSION_UID, sessionUID);
  }

  return sessionUID
}
export const getIsAuthenticating = () => localStorage.getItem(IS_AUTHENTICATING);
export const setIsAuthenticating = () => localStorage.setItem(IS_AUTHENTICATING, true);
export const unsetIsAuthenticating = () => localStorage.removeItem(IS_AUTHENTICATING);

export function logout() {
  unsetCurrentUser();
  return firebaseAuth().signOut();
}

