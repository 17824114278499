import { css } from 'styled-components';
import AvenirNextRegular from './fonts/AvenirNext/AvenirNext-Regular.woff';
import AvenirNextBold from './fonts/AvenirNext/AvenirNext-Bold.woff';
import AvenirNextDemiBold from './fonts/AvenirNext/AvenirNext-DemiBold.woff';
import AvenirNextHeavy from './fonts/AvenirNext/AvenirNext-Heavy.woff';
import AvenirNextMedium from './fonts/AvenirNext/AvenirNext-Medium.woff';
import AvenirNextItalic from './fonts/AvenirNext/AvenirNext-Italic.woff';
import AvenirNextBoldItalic from './fonts/AvenirNext/AvenirNext-BoldItalic.woff';
import AvenirNextDemiBoldItalic from './fonts/AvenirNext/AvenirNext-DemiBoldItalic.woff';
import AvenirNextHeavyItalic from './fonts/AvenirNext/AvenirNext-HeavyItalic.woff';
import AvenirNextMediumItalic from './fonts/AvenirNext/AvenirNext-MediumItalic.woff';

export default css`
  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextRegular}') format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextBold}') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextDemiBold}') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextHeavy}') format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextMedium}') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextItalic}') format('woff');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextBoldItalic}') format('woff');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextDemiBoldItalic}') format('woff');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextHeavyItalic}') format('woff');
    font-weight: 800;
    font-style: italic;
  }

  @font-face {
    font-family: 'AvenirNext';
    src: url('${AvenirNextMediumItalic}') format('woff');
    font-weight: 500;
    font-style: italic;
  }
`;
