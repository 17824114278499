import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ToolTip } from '@podiumhq/podium-ui';
import * as icon from '../../images/icon';
import { Row, PlatformContainer, PriceContainer, TooltipDiv } from './styles';
import { features, platforms } from '../../lib/toolData';

const { func } = PropTypes;

class PlatformSection extends Component {
  static propTypes = {
    addToTotalPrice: func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: 'standard'
    };
  }

  selectPlatform = (platform) => {
    this.setState({ selected: platform.key });
    const priceItem = {
      title: platform.title,
      price: platform.price,
      type: 'platform',
      users: platform.users
    };

    this.props.addToTotalPrice(priceItem);
  }

  renderFeature = (currentPlatform, feature, index) => {
    const isCurrentFeature = _.includes(currentPlatform.features, feature.key);
    return (
      <div className={`feature ${isCurrentFeature ? 'selected' : ''}`} key={index}>
        <div className="check-holder">
          {isCurrentFeature ? <img src={icon.check} alt="check icon" /> : null}
        </div>
        <div className="toolTip">
          <ToolTip position="right" tip={<TooltipDiv>{feature.tooltip}</TooltipDiv>}>
            {feature.title}
          </ToolTip>
        </div>
      </div>
    );
  }

  render() {
    const { selected } = this.state;
    const currentPlatform = _.get(platforms, `${selected}`);

    return (
      <PlatformContainer>
        <div>
          {_.map(platforms, (platform, index) => (
            <Row
              selected={selected === platform.key}
              key={index}
              onClick={() => this.selectPlatform(platform)}
            >
              <div className="icon">
                <img src={_.get(icon, platform.key)} alt="platform icons" />
              </div>
              <div className="content">
                <div className="title">{platform.title}</div>
                <div className="message">{platform.message}</div>
                <PriceContainer>
                  {platform.key === 'enterprise' ? (
                    <div> <span>{platform.price}</span></div>
                  ) : (
                    <div>${platform.price}<span className="duration">/month</span></div>
                    )}
                </PriceContainer>
              </div>
            </Row>
          ))}
        </div>
        <div className="feature-holder">
          <div>
            <div className="content">
              <div className="title">{currentPlatform.title}</div>
              <div className="description">{currentPlatform.desc}</div>
              <div>
                {_.map(features, (feature, index) => (
                  this.renderFeature(currentPlatform, feature, index)))
                }
              </div>
            </div>
          </div>
        </div>
      </PlatformContainer>
    );
  }
}

export default PlatformSection;
