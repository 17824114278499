/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import { getCurrentUser } from "../../lib/auth"; 

const GREENALYZER_API_HOST = process.env.REACT_APP_GREENALYZER_API_HOST;
const currUser = getCurrentUser();

const getJwt = (userObj) => userObj.stsTokenManager.accessToken;

const get = async (endpoint) => {
  try {
    const response = await fetch(`${GREENALYZER_API_HOST}/api${endpoint}`, {
      method: 'GET',
      headers: {
        Authorization: getJwt(currUser),
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  } catch (err) {
    return err;
  }
};

const post = async (endpoint, payload) => {
  try {
    const response = await fetch(`${GREENALYZER_API_HOST}/api${endpoint}`, {
      method: 'POST',
      headers: {
        Authorization: getJwt(currUser),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    return response.json();
  } catch (err) {
    return err;
  }
};

export const getEEOCData = async () => {
  return get('/eng_eeoc');
};

export const getGreenUsers = async (email) => {
  return get(`/is_user_valid?email=${email}`);
};

export const getQuarterData = async () => {
  return get('/quarterized_summaries');
};

export const getHealth = async () => {
  try {
    const response = await fetch(`${GREENALYZER_API_HOST}/health`, {
      method: 'GET',
      headers: {
        Authorization: getJwt(currUser),
        'Content-Type': 'application/json'
      }
    });
    return response.json();
  } catch (err) {
    return err;
  }
};
