import React, { useState } from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { dropdownStyles } from "../Prospector/styles";
import {
  IntegrationsContainer,
  IframeContainer,
  ButtonContainer,
  IconButton,
} from "./styles";
import { integrationsOptions } from "../../lib/toolData";
import {
  Divider,
  Section,
  SectionContents,
  SectionTitle,
  VerticalSelect,
} from "../Packaged/styles";

const TABLE = "table";
const DOC = "doc";

const Integrations = (props) => {
  // Pulling route value from URL param variable
  const initialRouteValue = props.match.params.vertical || "";
  const foundRoute = integrationsOptions.find(
    (opt) => opt.value === initialRouteValue
  );
  const initialRoute = foundRoute || integrationsOptions[0];

  // Set state
  const [selectedVertical, setSelectedVertical] = useState(initialRoute);
  const [selectedView, setSelectedView] = useState(
    initialRoute.value === "" ? DOC : TABLE
  );
  const [selectedIframe1, setSelectedIframe1] = useState(
    initialRoute.iframe1Url
  );
  const [selectedIframe2, setSelectedIframe2] = useState(
    initialRoute.iframe2Url
  );

  // Helper functions
  const handleVerticalChange = (option) => {
    setSelectedVertical(option);
    setSelectedIframe1(option.iframe1Url);
    setSelectedIframe2(option.iframe2Url);
    props.history.replace(`/integrations/${option.value}`);
  };

  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  const renderIframeUrl = () => {
    return selectedView === TABLE ? selectedIframe1 : selectedIframe2;
  };

  const renderIframeHeight = () => {
    return selectedView === TABLE ? "775" : "1375";
  };

  // JSX to be returned
  return (
    <IntegrationsContainer>
      <Section>
        <SectionTitle>
          <div>01</div>
          <div>VERTICAL</div>
        </SectionTitle>
        <SectionContents>
          <VerticalSelect>
            <Select
              placeholder={selectedVertical}
              isSearchable
              options={integrationsOptions}
              styles={dropdownStyles}
              className={selectedVertical}
              value={selectedVertical}
              onChange={handleVerticalChange}
            />
          </VerticalSelect>
        </SectionContents>
      </Section>
      <Divider />
      <Section>
        <SectionTitle>
          <div>02</div>
          <div>INTEGRATIONS</div>
        </SectionTitle>
        <SectionContents>
          <ButtonContainer>
            <IconButton height="40" onClick={() => handleViewChange(TABLE)}>
              List View
            </IconButton>
            <IconButton height="40" onClick={() => handleViewChange(DOC)}>
              Detailed View
            </IconButton>
          </ButtonContainer>
          <Iframe url={renderIframeUrl()} height={renderIframeHeight()} />
        </SectionContents>
      </Section>
    </IntegrationsContainer>
  );
};

const Iframe = (props) => {
  return (
    <IframeContainer>
      <iframe
        height={props.height}
        className="airtable-embed"
        src={props.url}
        title="airtable"
      />
    </IframeContainer>
  );
};

Iframe.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Integrations;
