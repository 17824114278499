export const features = [
  {
    title: "Google My Business Integration",
    key: "gmbIntegration",
    tooltip:
      "Connect Podium with your Google My Business account to collect reviews and respond to inbound Google Click-to-Message conversations.",
  },
  {
    title: "Conversation Assignment",
    key: "conversationAssignment",
    tooltip: "Assign conversations to the appropriate team or team member.",
  },
  {
    title: "Messaging Templates",
    key: "messageTemplates",
    tooltip:
      "Use consistent messaging for the most common responses or outbound message.",
  },
  {
    title: "iOS & Android Apps",
    key: "iosAndroidApps",
    tooltip:
      "Respond to leads and customers or send review invites from anywhere.",
  },
  {
    title: "Chrome Browser Extension",
    key: "chromeBrowserExt",
    tooltip:
      "Interact with leads and customers through our fully-integrated Chrome Extension.",
  },
  {
    title: "Facebook Business Integration",
    key: "facebookBusinessIntegration",
    tooltip:
      "Connect Podium with your Facebook Business account to collect reviews and respond to inbound Facebook Messenger conversations.",
  },
  {
    title: "Multi-location Management",
    key: "multiLocation",
    tooltip: "Manage multiple locations from one Podium account.",
  },
  {
    title: "Reporting",
    key: "reporting",
    tooltip:
      "See trends and analytics for all of your interactions including Reviews, Feedback, and Chat.",
  },
  {
    title: "Sentiment Analysis",
    key: "sentimentAnalysis",
    tooltip:
      "Understand areas of your business that customers enjoy or dislike.",
  },
  {
    title: "Hosted Landline",
    key: "hostedLandline",
    tooltip: "Make your existing phone number a textable number.",
  },
  {
    title: "Interaction Automation",
    key: "interactionAutomation",
    tooltip:
      "Send automated interactions including review invites and feedback requests.",
  },
  {
    title: "Report Export",
    key: "reportExport",
    tooltip: "Export interaction data for certain time periods or locations.",
  },
  {
    title: "Customer Import",
    key: "customerImport",
    tooltip: "Import customers into Podium to send a one-time interaction.",
  },
  {
    title: "Interaction Message Editor",
    key: "interactionEditor",
    tooltip:
      "Customize the message template for your interactions including review invites and feedback requests.",
  },
  {
    title: "Round Robin Interactions",
    key: "roundRobinInteraction",
    tooltip:
      "Set the percentage of your customers that will automatically get certain interactions.",
  },

  {
    title: "Multi-language Interactions",
    key: "multilLanguageInteractions",
    tooltip:
      "Send interactions in a number of different languages including English, Spanish, and French.",
  },
  {
    title: "Discounted Service Location ($65)",
    key: "discountedServiceLocation",
    tooltip: "Add a service center or satellite office at a discounted rate.",
  },
];

export const verticalClosePercentages = {
  autoNew: 45.2,
  autoUsed: 45.2,
  autoServices: 49.0,
  professionalServices: 38.1,
  homeServices: 45.5,
  healthcare: 44.3,
  retail: 45.4,
};

export const verticalOptions = [
  {
    value: "Auto",
    label: "Auto",
  },
  {
    value: "Auto Services",
    label: "Auto Services",
  },
  {
    value: "Professional Services",
    label: "Professional Services",
  },
  {
    value: "Home Services",
    label: "Home Services",
  },
  {
    value: "Healthcare",
    label: "Healthcare",
  },
  // {
  //   value: "Hospitality",
  //   label: "Hospitality"
  // },
  {
    value: "Partner",
    label: "Partner",
  },
  {
    value: "Retail",
    label: "Retail",
  },
];

export const integrationsOptions = [
  {
    value: "",
    label: "All",
    iframe1Url:
      "https://airtable.com/embed/shrCNCLesVDIQtTB9?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "auto",
    label: "Auto",
    iframe1Url:
      "https://airtable.com/embed/shrfwyKYuZNC8NQWQ?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "auto-services",
    label: "Auto Services",
    iframe1Url:
      "https://airtable.com/embed/shrqaY286ZTB1Hp3I?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "general",
    label: "General",
    iframe1Url:
      "https://airtable.com/embed/shrmsR2lo0BLf567m?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "healthcare",
    label: "Healthcare",
    iframe1Url:
      "https://airtable.com/embed/shrEmiM6ggDgMiE0J?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "home-services",
    label: "Home Services",
    iframe1Url:
      "https://airtable.com/embed/shrLbSb1xn7esmmEC?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "hospitality",
    label: "Hospitality",
    iframe1Url:
      "https://airtable.com/embed/shrzaUTVbU0FvvqA6?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "pro-serv",
    label: "Professional Services",
    iframe1Url:
      "https://airtable.com/embed/shrsDwYMnaPk2LmUb?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "retail",
    label: "Retail",
    iframe1Url:
      "https://airtable.com/embed/shrpGysMQ2FvtJZ7o?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
  {
    value: "australia",
    label: "Australia",
    iframe1Url:
      "https://airtable.com/embed/shrwjnAqQd203ms0R?backgroundColor=cyan&viewControls=on",
    iframe2Url:
      "https://airtable.com/embed/shreGPeIkyTW8G9d9?backgroundColor=cyan",
  },
];

export const subverticalOptions = {
  Auto: [
    {
      value: "OEM/New Car Dealer",
      label: "OEM/New Car Dealer",
    },
    {
      value: "Marine Dealer",
      label: "Marine Dealer",
    },
    {
      value: "Powersports Dealer",
      label: "Powersports Dealer",
    },
    {
      value: "RV Dealer",
      label: "RV Dealer",
    },
    {
      value: "Used Car Dealer",
      label: "Used Car Dealer",
    },
  ],
  "Auto Services": [
    {
      value: "Auto Repair",
      label: "Auto Repair",
    },
    {
      value: "Auto Recycler",
      label: "Auto Recycler"
    },
    {
      value: "Auto Services - Other",
      label: "Auto Services - Other",
    },
    {
      value: "Car Wash",
      label: "Car Wash",
    },
    {
      value: "Collision Center",
      label: "Collision Center",
    },
    {
      value: "Glass",
      label: "Glass",
    },
    {
      value: "Oil/Filter",
      label: "Oil/Filter",
    },
    {
      value: "Tire Shop",
      label: "Tire Shop",
    },
    {
      value: "Transmission Shop",
      label: "Transmission Shop",
    },
  ],
  "Professional Services": [
    {
      label: "Cannabis Dispensary",
      value: "Cannabis Dispensary",
    },
    {
      label: "Diversified - Other",
      value: "Diversified - Other",
    },
    {
      label: "Education",
      value: "Education",
    },
    {
      label: "Events/Catering",
      value: "Events/Catering",
    },
    {
      label: "Financial Institution",
      value: "Financial Institution",
    },
    {
      label: "Hospitality/Travel",
      value: "Hospitality/Travel",
    },
    {
      label: "Insurance Brokerage",
      value: "Insurance Brokerage",
    },
    {
      label: "Law Firm",
      value: "Law Firm",
    },
    {
      label: "Law Firm - Criminal Defense",
      value: "Law Firm - Criminal Defense",
    },
    {
      label: "Immigration Attorney",
      value: "Immigration Attorney",
    },
    {
      label: "Mortgage Brokerage",
      value: "Mortgage Brokerage",
    },
    {
      label: "Mortuary",
      value: "Mortuary",
    },
    {
      label: "Online Domain",
      value: "Online Domain",
    },
    {
      label: "Pet Care",
      value: "Pet Care",
    },
    {
      label: "Property Management",
      value: "Property Management",
    },
    {
      label: "Real Estate",
      value: "Real Estate",
    },
    {
      label: "Tax Firms",
      value: "Tax Firms",
    },
  ],
  "Home Services": [
    {
      label: "Electrical",
      value: "Electrical",
    },
    {
      label: "Exteriors",
      value: "Exteriors",
    },
    {
      label: "Interiors",
      value: "Interiors",
    },
    {
      label: "Floor Cleaning",
      value: "Floor Cleaning",
    },
    {
      label: "Flooring",
      value: "Flooring",
    },
    {
      label: "General Home Services - Low Volume",
      value: "General Home Services - Low Volume",
    },
    {
      label: "General Home Services - Other",
      value: "General Home Services - Other",
    },
    {
      label: "Home Automation",
      value: "Home Automation",
    },
    {
      label: "Home Security",
      value: "Home Security",
    },
    {
      label: "HVAC/Plumbing",
      value: "HVAC/Plumbing",
    },
    {
      label: "Lawn Care",
      value: "Lawn Care",
    },
    {
      label: "Locksmith",
      value: "Locksmith",
    },
    {
      label: "Maid Service",
      value: "Maid Service",
    },
    {
      label: "Moving",
      value: "Moving",
    },
    {
      label: "Pest Control",
      value: "Pest Control",
    },
    {
      label: "Pools",
      value: "Pools",
    },
    {
      label: "Roofing",
      value: "Roofing",
    },
    {
      label: "Self Storage",
      value: "Self Storage",
    },
    {
      label: "Solar",
      value: "Solar",
    },
    {
      label: "Windows/Doors",
      value: "Windows/Doors",
    },
    {
      label: "Wireless Internet Provider",
      value: "Wireless Internet Provider",
    },
  ],
  Healthcare: [
    {
      label: "Dental (General)",
      value: "Dental (General)",
    },
    {
      label: "Addiction Recovery",
      value: "Addiction Recovery",
    },
    {
      label: "Assisted Living/Home Health/Hospice",
      value: "Assisted Living/Home Health/Hospice'",
    },
    {
      label: "Chiropractic",
      value: "Chiropractic",
    },
    {
      label: "Dermatology",
      value: "Dermatology",
    },
    {
      label: "Family Practice",
      value: "Family Practice",
    },
    {
      label: "Fertility Center",
      value: "Fertility Center",
    },
    {
      label: "Hair Removal",
      value: "Hair Removal",
    },
    {
      label: "Hair Restoration",
      value: "Hair Restoration",
    },
    {
      label: "Hospital",
      value: "Hospital",
    },
    {
      label: "Large Spa/Salon",
      value: "Large Spa/Salon",
    },
    {
      label: "LASIK",
      value: "LASIK",
    },
    {
      label: "Med Spa/Wellness Center/Skin Care",
      value: "Med Spa/Wellness Center/Skin Care",
    },
    {
      label: "Medical - Other",
      value: "Medical - Other",
    },
    {
      label: "Fitness Center (Non-Corporate)",
      value: "Fitness Center (Non-Corporate)",
    },
    {
      label: "Spa/Salon (Non-Corporate)",
      value: "Spa/Salon (Non-Corporate)",
    },
    {
      label: "Ophthalmology",
      value: "Ophthalmology",
    },
    {
      label: "Orthodontics",
      value: "Orthodontics",
    },
    {
      label: "Orthopedic Surgery",
      value: "Orthopedic Surgery",
    },
    {
      label: "Pain Center",
      value: "Pain Center",
    },
    {
      label: "Pediatric Care",
      value: "Pediatric Care",
    },
    {
      label: "Plastic Surgery",
      value: "Plastic Surgery",
    },
    {
      label: "Radiology",
      value: "Radiology",
    },
    {
      label: "Urgent Care",
      value: "Urgent Care",
    },
    {
      label: "Veterinarian",
      value: "Veterinarian",
    },
    {
      label: "Vision",
      value: "Vision",
    },
    {
      label: "Women's Health",
      value: "Women's Health",
    },
  ],
  Partner: [
    {
      label: "Partner",
      value: "",
    },
  ],
  Retail: [
    {
      label: "Experiences",
      value: "Experiences",
    },
    {
      label: "Jewelry",
      value: "Jewelry",
    },
    {
      label: "Appliance",
      value: "Appliance",
    },
    {
      label: "Music Store",
      value: "Music Store",
    },
    {
      label: "Bike Shop",
      value: "Bike Shop",
    },
    {
      label: "Bridal Shop",
      value: "Bridal Shop",
    },
    {
      label: "Furniture",
      value: "Furniture",
    },
    {
      label: "General Retail - Focused on Commercial Businesses",
      value: "General Retail - Focused on Commercial Businesses",
    },
    {
      label: "General Retail - Low Volume",
      value: "General Retail - Low Volume",
    },
    {
      label: "General Retail - Other",
      value: "General Retail - Other",
    },
    {
      label: "General Retail - Owner Operator",
      value: "General Retail - Owner Operator",
    },
    {
      label: "Gun Shops",
      value: "Gun Shops",
    },
    {
      label: "Mattress",
      value: "Mattress",
    },
    {
      label: "Pawn Shop",
      value: "Pawn Shop",
    },
    {
      label: "Sporting Goods",
      value: "Sporting Goods",
    },
  ],
};

export const platforms = {
  basic: {
    features: [
      "gmbIntegration",
      "conversationAssignment",
      "messageTemplates",
      "iosAndroidApps",
      "chromeBrowserExt",
    ],
    price: 199,
    message: "Up to 3 users, additional users are $5 / month.",
    desc:
      "For teams looking to modernize the way they communicate with their customers.",
    default: false,
    title: "Basic",
    key: "basic",
    users: 3,
  },
  standard: {
    features: [
      "gmbIntegration",
      "conversationAssignment",
      "messageTemplates",
      "iosAndroidApps",
      "chromeBrowserExt",
      "facebookBusinessIntegration",
      "multiLocation",
      "reporting",
      "sentimentAnalysis",
      "hostedLandline",
      "interactionAutomation",
    ],
    price: 239,
    message: "Up to 10 users, additional users are $5 / month.",
    desc:
      "For teams looking to modernize their interaction and gain critical insight into their business.",
    default: true,
    title: "Standard",
    key: "standard",
    users: 10,
  },
  pro: {
    features: [
      "gmbIntegration",
      "conversationAssignment",
      "messageTemplates",
      "iosAndroidApps",
      "chromeBrowserExt",
      "facebookBusinessIntegration",
      "multiLocation",
      "reporting",
      "sentimentAnalysis",
      "hostedLandline",
      "interactionAutomation",
      "reportExport",
      "customerImport",
      "interactionEditor",
      "roundRobinInteraction",
      "multilLanguageInteractions",
      "discountedServiceLocation",
    ],
    price: 289,
    message: "Up to 25 users, additional users are $5 / month.",
    desc:
      "For local businesses looking to modernize every interaction along the customer journey.",
    default: false,
    title: "Pro",
    key: "pro",
    users: 25,
  },
  enterprise: {
    features: [
      "gmbIntegration",
      "conversationAssignment",
      "messageTemplates",
      "iosAndroidApps",
      "chromeBrowserExt",
      "facebookBusinessIntegration",
      "multiLocation",
      "reporting",
      "sentimentAnalysis",
      "hostedLandline",
      "interactionAutomation",
      "reportExport",
      "customerImport",
      "interactionEditor",
      "roundRobinInteraction",
      "multilLanguageInteractions",
      "discountedServiceLocation",
    ],
    price: "Custom Pricing",
    message: "Custom implementation",
    desc:
      "For enterprise and franchise businesses looking to interact through 100s or 1000s of locations.",
    default: false,
    title: "Enterprise",
    key: "enterprise",
    users: null,
  },
};

export const products = {
  reviews: {
    title: "Reviews",
    price: 109,
    desc:
      "Generate real-time reviews on the sites that matter most to your business.",
    default: true,
    key: "reviews",
  },
  chat: {
    title: "Webchat",
    price: 99,
    desc:
      "Capture website visitors' questions and information to take the conversation to SMS.",
    default: false,
    key: "chat",
  },
  reporting: {
    title: "Reporting+",
    price: 89,
    desc:
      "Get in-depth reporting on historicals, trends, and specific locations or regions.",
    default: false,
    key: "reporting",
  },
};

export const optimizer = {
  key: "optimizer",
  price: 59,
  name: "Premium Integration",
  features: [
    "Integration with your systems",
    "Library of industy-specific templates (updates quarterly)",
    "Industry-specific support representative",
    "Quarterly Customer Interaction industry review",
  ],
};

export const allPackages = {
  reputation: {
    title: "Reputation",
    platform: "pro",
    products: ["reviews"],
    heading: "Reviews + Premium Integration",
    features: [
      "Get reviews on the most popular sites",
      "Send unlimited review invites",
      "Communicate via text and Facebook Messenger",
    ],
    key: "reputation",
  },
  interact: {
    title: "Interact",
    platform: "pro",
    products: ["reviews", "chat"],
    heading: "Reviews + Webchat + Premium Integration",
    features: [
      "Get reviews on the most popular sites",
      "Convert web visitors to customers",
      "Manage reviews and interactions from one dashboard",
    ],
    key: "interact",
  },
};

export const states = [
  {
    value: "Alabama",
    label: "Alabama",
  },
  {
    value: "Alaska",
    label: "Alaska",
  },
  {
    value: "Arizona",
    label: "Arizona",
  },
  {
    value: "Arkansas",
    label: "Arkansas",
  },
  {
    value: "California",
    label: "California",
  },
  {
    value: "Colorado",
    label: "Colorado",
  },
  {
    value: "Connecticut",
    label: "Connecticut",
  },
  {
    value: "Delaware",
    label: "Delaware",
  },
  {
    value: "District of Columbia",
    label: "District of Columbia",
  },
  {
    value: "Florida",
    label: "Florida",
  },
  {
    value: "Georgia",
    label: "Georgia",
  },
  {
    value: "Hawaii",
    label: "Hawaii",
  },
  {
    value: "Idaho",
    label: "Idaho",
  },
  {
    value: "Illinois",
    label: "Illinois",
  },
  {
    value: "Indiana",
    label: "Indiana",
  },
  {
    value: "Iowa",
    label: "Iowa",
  },
  {
    value: "Kansas",
    label: "Kansas",
  },
  {
    value: "Kentucky",
    label: "Kentucky",
  },
  {
    value: "Louisiana",
    label: "Louisiana",
  },
  {
    value: "Maine",
    label: "Maine",
  },
  {
    value: "Maryland",
    label: "Maryland",
  },
  {
    value: "Massachusetts",
    label: "Massachusetts",
  },
  {
    value: "Michigan",
    label: "Michigan",
  },
  {
    value: "Minnesota",
    label: "Minnesota",
  },
  {
    value: "Mississippi",
    label: "Mississippi",
  },
  {
    value: "Missouri",
    label: "Missouri",
  },
  {
    value: "Montana",
    label: "Montana",
  },
  {
    value: "Nebraska",
    label: "Nebraska",
  },
  {
    value: "Nevada",
    label: "Nevada",
  },
  {
    value: "New Hampshire",
    label: "New Hampshire",
  },
  {
    value: "New Jersey",
    label: "New Jersey",
  },
  {
    value: "New Mexico",
    label: "New Mexico",
  },
  {
    value: "New York",
    label: "New York",
  },
  {
    value: "North Carolina",
    label: "North Carolina",
  },
  {
    value: "North Dakota",
    label: "North Dakota",
  },
  {
    value: "Ohio",
    label: "Ohio",
  },
  {
    value: "Oklahoma",
    label: "Oklahoma",
  },
  {
    value: "Oregon",
    label: "Oregon",
  },
  {
    value: "Pennsylvania",
    label: "Pennsylvania",
  },
  {
    value: "Rhode Island",
    label: "Rhode Island",
  },
  {
    value: "South Carolina",
    label: "South Carolina",
  },
  {
    value: "South Dakota",
    label: "South Dakota",
  },
  {
    value: "Tennessee",
    label: "Tennessee",
  },
  {
    value: "Texas",
    label: "Texas",
  },
  {
    value: "Utah",
    label: "Utah",
  },
  {
    value: "Vermont",
    label: "Vermont",
  },
  {
    value: "Virginia",
    label: "Virginia",
  },
  {
    value: "Washington",
    label: "Washington",
  },
  {
    value: "West Virginia",
    label: "West Virginia",
  },
  {
    value: "Wisconsin",
    label: "Wisconsin",
  },
  {
    value: "Wyoming",
    label: "Wyoming",
  },
];

export const verticalPricing = [
  {
    key: "autoNew",
    price: {
      reputation: 457,
      interact: 557,
    },
  },
  {
    key: "autoUsed",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "autoServices",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "professionalServices",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "homeServices",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "healthcare",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "hospitality",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "partner",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
  {
    key: "retail",
    price: {
      reputation: 348,
      interact: 447,
    },
  },
];
