import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete from '../LeadScoring/PlacesAutocomplete/PlacesAutocomplete';
import {
  LocationSearchInputContainer,
  PlacesSearch,
  PlacesResult,
  PlacesResults
} from '../LeadScoring/LocationSearchInput/styles';

const { func } = PropTypes;

export default class NearbyLocationSearchInput extends React.Component {
  static propTypes = {
    onSelect: func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = { searchString: '' };
  }

  handleChange = searchString => this.setState({ searchString });

  handleSelect = ({
    address,
    placeId,
    sessionToken,
  }) => {
    this.setState({
      searchString: address
    });

    this.props.onSelect(placeId, sessionToken);
  };


  // These props come directly from the PlacesAutocomplete render()
  searchWidget = ({
    getInputProps,
    suggestions,
    getSuggestionItemProps,
    loading
  }) => {
    const inputProps = getInputProps();
    return [
      <PlacesSearch
        {...inputProps}
        key="input"
        placeholder="Search for a business here..."
      />,
      <PlacesResults show={loading || suggestions.length > 0} key="results">
        {loading && <div>Loading...</div>}
        {suggestions.map((suggestion) => {
          console.log("SUGGESTION", suggestion);
          const suggestionProps = getSuggestionItemProps(suggestion);
          return (
            <PlacesResult {...suggestionProps} active={suggestion.active}>
              <p>{suggestion.formattedSuggestion.mainText}</p>
              <p>{suggestion.formattedSuggestion.secondaryText}</p>
            </PlacesResult>
          );
        })}
      </PlacesResults>
    ];
  };

  render() {
    const autocompleteOptions = {
      value: this.state.searchString,
      onChange: this.handleChange,
      onSelect: this.handleSelect,
      returnSessionToken: true
    };

    return (
      <LocationSearchInputContainer>
        <PlacesAutocomplete {...autocompleteOptions}>
          {this.searchWidget}
        </PlacesAutocomplete>
      </LocationSearchInputContainer>
    );
  }
}
