import { v4 as uuidv4 } from 'uuid';
import { getProspectorAdminChannel } from './socket';
import { getSessionUID } from './auth';

/* eslint-disable no-param-reassign */
export const startTimer = (codePoint, requestUID) => {
  getProspectorAdminChannel();
  if(!requestUID) {
    requestUID = uuidv4();
  };
  const metricUID = uuidv4();
  getProspectorAdminChannel().push("start-timer", {
    code_point: `frontend - ${codePoint}`,
    start_at: new Date().toISOString(),
    metric_uid: metricUID,
    session_uid: getSessionUID(),
    request_uid: requestUID
  });
  return metricUID;
};

export const stopTimer = (metricUID) => {
  if (! metricUID) { return; }

  getProspectorAdminChannel().push("end-timer", {
    metric_uid: metricUID, end_time: new Date().toISOString()
  });
};
