import styled from 'styled-components';
import colors from '../../styles/colors';
import { check } from '../../images/icon';

export const contentStyles = `
    background-color: none;
    border: none;
`;

export const overlayBackground = `
    background-color: rgba(0,0,0,0.99);
`;

export const DisplayPackages = styled.div`{
  .showPackageWrapper {
      padding: 30px 0 50px;
      border-radius: 4px;
      background-color: #fff;
      .up-front {
        max-width: 243px;
        text-align: center;
      }
      .icons {
          padding: 15px 0;
          display: flex;
          align-items: center;
          justify-content: center;
      }

      .icon-text {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        width: 18%;
        div {
            min-height: 60px;
            display: flex;
            justify-content: center;
        }
        span {
            padding: 0 10px;
            font-size: 12px;
            font-weight: normal;
        }
        .platform {
          font-weight: bold;
          font-size: 13px;
        }
      }
      .featurePoints {
        max-width: 340px;
        margin:0 auto;
        div {
          background-image: url(${check});
          text-align: left;
          padding: 0 0 20px 30px;
        }
      }
      .price {
        font-weight: bold;
      }

      .packageDivider {
        background-color: ${colors.iron};
        height: 1px;
        width: 340px;
        opacity: 0.72;
        margin: 22px auto;
      }
      .locationDiscount {
          padding-bottom: 30px;
      }
  }

    .close {
      position: absolute;
      top: 0;
      cursor: pointer;
      right: -50px;
      display: flex;
      align-items: center;
      flex-flow: column wrap;
      span {
        font-size: 12px;
        font-weight: 500;
        color: ${colors.white};
        padding-top: 6px;
      }
    }

  .up-front {
    background: #ececec;
    padding: 10px 14px;
    max-width: 200px;
    margin: 0 auto 25px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    text-align: right;
    cursor: pointer;
  }
}`;

export const Summary = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  .createPackage {
      border: 2px solid #e4e9f0;
      padding: 15px 30px;
      text-align: center;
      margin: 30px auto 0;
      border-radius: 4px;
      cursor: pointer;
      color: ${colors.jumbo};
  }
  .up-front {
    background: ${colors.whiteSmoke};
    padding: 6px;
    max-width: 200px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
  }

  .price-items {
    width: 100%;
  }

  .single-item {
    color: ${colors.jumbo};
    font-weight: 300;
    display: flex;
    justify-content: space-between;

    margin: 16px 0;
  }
  .platform {
    padding: 0 0 26px;
  }

  .total_before_single{
    padding: 26px 0;
  }
  .total-before {
    font-weight: 600;
    color: ${colors.mineShaft};
  }

  .discounts {
    span {
      font-weight: 600;
      color: ${colors.mineShaft};
    }
  }

  .setup {
    span {
      font-weight: 600;
      color: ${colors.mineShaft};
    }
  }
  .totals {
    font-size: 20px;
    margin-top: 44px;
    span {
      font-size: 19px;
      font-weight: 700;
      color: ${colors.mineShaft};
    }
  }
`;

