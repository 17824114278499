/* global document */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Modal, IconX } from "@podiumhq/podium-ui";
import { createGlobalStyle } from "styled-components";
import * as icon from "../../images/icon";
import {
  Summary,
  contentStyles,
  overlayBackground,
  DisplayPackages
} from "./styles";

const SETUP_FEE = { title: "One-time setup fee", price: 449, type: "setup" };
const OPTIMIZER_FEE = {
  title: "Premium Integration",
  price: 59,
  type: "optimizer"
};
const SHOW_DISCOUNTS = false;

const { arrayOf, string, shape, number, bool, func } = PropTypes;

class SummarySection extends Component {
  static propTypes = {
    priceItems: arrayOf(
      shape({
        title: string,
        price: number
      })
    ).isRequired,
    discounts: arrayOf(
      shape({
        title: string,
        percentage: number,
        amount: number,
        key: string
      })
    ).isRequired,
    optimizerEnabled: bool.isRequired,
    totalAfterDiscounts: number.isRequired,
    onUpfrontPayment: func.isRequired,
    isUpfrontPaid: bool.isRequired,
    totalBeforeDiscounts: number.isRequired,
    showCreatePackage: bool.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentWillMount() {
    return createGlobalStyle`
    div.podium-ui-modal-content.podium-ui-modal-content-no-padding {
      ${contentStyles}
    }
    .openCreateBtnModal div.podium-ui-modal-overlay {
      ${overlayBackground}
    }`;
  }

  getLocationDiscount = discounts =>
    _.get(_.filter(discounts, { key: "locationsDiscount" })[0], "amount");

  addOpenClassToBody = openStatus => {
    if (openStatus) {
      document.body.classList.add("openCreateBtnModal");
    } else {
      document.body.classList.remove("openCreateBtnModal");
    }
  };

  toggleOpen = () =>
    this.setState({ open: !this.state.open }, () =>
      this.addOpenClassToBody(this.state.open)
    );

  showCreatedPackages = () => {
    const {
      priceItems,
      discounts,
      totalAfterDiscounts,
      totalBeforeDiscounts,
      onUpfrontPayment,
      isUpfrontPaid,
      optimizerEnabled
    } = this.props;

    const sortedPriceItems = _.sortBy(
      priceItems,
      item => item.type === "platform"
    ).reverse();
    const selectedItems = [];

    _.map(sortedPriceItems, val => {
      if (_.get(val, "type") === "platform") {
        selectedItems.push({
          type: _.get(val, "type"),
          key: _.get(val, "title").toLowerCase(),
          title: _.get(val, "title")
        });
      } else if (_.get(val, "type") === "product") {
        switch (_.get(val, "title")) {
          case "Reporting+":
            selectedItems.push({
              type: _.get(val, "type"),
              key: "reporting",
              title: _.get(val, "title")
            });
            break;
          case "Webchat":
            selectedItems.push({
              type: _.get(val, "type"),
              key: "chat",
              title: _.get(val, "title")
            });
            break;
          default:
            selectedItems.push({
              type: _.get(val, "type"),
              key: _.get(val, "title").toLowerCase(),
              title: _.get(val, "title")
            });
        }
      }
    });

    return (
      <div className="showPackageWrapper">
        <div className="icons">
          {_.map(selectedItems, (prod, i) => (
            <div key={i} className="icon-text">
              <div>
                <img
                  className={_.get(prod, "key")}
                  key={i}
                  src={_.get(icon, _.get(prod, "key"))}
                  alt={`${_.get(prod, "key")}'s icon`}
                />
              </div>
              <span className={_.get(prod, "type")} key={i}>
                {_.get(prod, "title")}
              </span>
            </div>
          ))}
        </div>
        <div className="packageDivider" />
        {_.find(selectedItems, { key: "chat" }) ? (
          <h3>Interactive</h3>
        ) : (
          <h3>Reputation</h3>
        )}
        {_.find(selectedItems, { key: "chat" }) ? (
          <div className="featurePoints">
            <div>Get reviews on the most popular posts</div>
            <div>Convert website visitors to customers</div>
            <div>Manage reviews and interactions from one dashboard</div>
          </div>
        ) : (
          <div className="featurePoints">
            <div>Get reviews on the most popular posts</div>
            <div>Send unlimited review invites</div>
            <div>Communicate via text and Facebook Messenger</div>
          </div>
        )}
        <div className="packageDivider" />
        <div className="locationDiscount">
          Price/month:{" "}
          {this.getLocationDiscount(discounts) && SHOW_DISCOUNTS ? (
            <span className="price">
              $
              {optimizerEnabled
                ? totalAfterDiscounts + OPTIMIZER_FEE.price
                : totalAfterDiscounts}
            </span>
          ) : (
            <span className="price">
              $
              {optimizerEnabled
                ? totalBeforeDiscounts + OPTIMIZER_FEE.price
                : totalBeforeDiscounts}
            </span>
          )}
        </div>
        {SHOW_DISCOUNTS && (
          <div>
            <div
              className="up-front"
              onClick={() => onUpfrontPayment()}
              role="presentation">
              {isUpfrontPaid ? "Remove " : "Add "} Annual Upfront Payment
            </div>
            <div className="totalAfterDiscount">
              Price/month: <span className="price">${totalAfterDiscounts}</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const {
      priceItems,
      discounts,
      totalBeforeDiscounts,
      totalAfterDiscounts,
      onUpfrontPayment,
      isUpfrontPaid,
      showCreatePackage,
      optimizerEnabled
    } = this.props;
    const sortedPriceItems = _.sortBy(
      priceItems,
      item => item.type === "platform"
    ).reverse();

    return (
      <Summary>
        <div className="price-items">
          {_.map(sortedPriceItems, (value, index) => (
            <div className={`single-item ${value.type}`} key={index}>
              {value.title}{" "}
              <span>
                {_.isNumber(value.price) && value.price > 0
                  ? `$ ${value.price}`
                  : "included"}
              </span>
            </div>
          ))}
          {optimizerEnabled ? (
            <div className="single-item">
              {OPTIMIZER_FEE.title}
              <span>${OPTIMIZER_FEE.price}</span>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="single-item total_before_single">
          Total{" "}
          <span className="total-before">
            $
            {optimizerEnabled
              ? OPTIMIZER_FEE.price + totalBeforeDiscounts
              : totalBeforeDiscounts}
            /month
          </span>
        </div>
        <div className="discounts">
          {SHOW_DISCOUNTS &&
            _.map(discounts, (disc, index) => (
              <div className="single-item" key={index}>
                {disc.title} ({disc.percentage * 100}%){" "}
                <span>-${disc.amount}</span>
              </div>
            ))}
        </div>
        {SHOW_DISCOUNTS && (
          <div
            className="up-front"
            onClick={() => onUpfrontPayment()}
            role="presentation">
            {isUpfrontPaid ? "- " : "+ "} Annual Upfront Payment
          </div>
        )}
        <div className="single-item setup">
          {SETUP_FEE.title}
          <span>${SETUP_FEE.price}</span>
        </div>
        {discounts.length > 0 && SHOW_DISCOUNTS && (
          <div className="single-item totals">
            Total (after discounts){" "}
            <span className="total">
              $
              {optimizerEnabled
                ? OPTIMIZER_FEE.price + totalAfterDiscounts
                : totalAfterDiscounts}
              /month
            </span>
          </div>
        )}
        {showCreatePackage && (
          <div
            className="createPackage"
            onClick={() => this.toggleOpen()}
            role="presentation">
            CREATE PACKAGE
          </div>
        )}
        <div className="modalContainer">
          <Modal
            disableClose
            noPadding
            tall
            isOpen={this.state.open}
            onRequestClose={this.toggleOpen}>
            <DisplayPackages>
              <div
                className="close"
                role="presentation"
                onClick={() => this.toggleOpen()}>
                <IconX color="#ffffff" />
                <span>close</span>
              </div>
              <div>{this.showCreatedPackages()}</div>
            </DisplayPackages>
          </Modal>
        </div>
      </Summary>
    );
  }
}

export default SummarySection;
