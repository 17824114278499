import React from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import { toolTips } from './optionData';
import analytics from '../../lib/analytics';
import { getProspectorChannel, getProspectorAdminChannel } from '../../lib/socket';
import { startTimer, stopTimer } from '../../lib/performanceMetrics';
import {
  bulkUpdateProspects,
  createBatch,
  createSalesforceLead,
  getDropDowns,
  // getProspectSummaries,
  updateProspect,
} from '../../lib/client';
import { getCurrentUser, getSessionUID } from '../../lib/auth';
import {
  ApiError,
  FilterRow,
  MainBody,
  ProspectingButton,
  ProspectingContainer,
  RightColumn,
} from './styles';
import ContactModal from './ContactModal/ContactModal';
import ProspectingDropdowns from './ProspectingDropdowns/ProspectingDropdowns';
import ProspectorList from './ProspectorList/ProspectorList';
import ProspectTracker from './ProspectTracker/ProspectTracker';
import StatusScreen from './StatusScreen/StatusScreen';
// import ProspectSummaries from './ProspectSummaries/ProspectSummaries';

let currentTimerRequestUID = null;
let searchTimer = null;
let loadingPagePauseTimer = null;
let loadingPagePauseTimeout = null;
let userTimeOnResultsPageTimer = null;
let submitLeadsTimer = null;

const DEFAULT_VERTICAL = { label: 'Retail', value: 'retail', id: 6 };
const DEFAULT_SUBVERTICAL = { label: 'Appliance', value: 'Appliance' };
const DEFAULT_STRIKEZONE = {
  label: 'Appliance Store',
  value: 'Appliance Store',
};
const DEFAULT_STATE = { label: 'California', value: 'California', id: 5 };
const VERTICAL_ENDPOINT = '/fetch-verticals';
const SUBVERTICAL_ENDPOINT = '/fetch-sub-verticals';
const STRIKEZONE_ENDPOINT = '/fetch-strikezones';
const STATES_ENDPOINT = '/fetch-states';
const CITIES_BY_STATE_ENDPOINT = '/new-cities-by-state-name?state=';
const POSTAL_CODES_BY_CITY_AND_STATE_ENDPOINT =
  '/postal-codes-by-city-and-state-name?';
const POSTAL_CODES_BY_STATE_ENDPOINT = '/postal-code-by-state-name?name=';

const SET_DEFAULT_OPTIONS = {
  retail: [
    {
      label: 'Retail',
      value: 'retail',
    },
  ],
  state: [
    {
      label: 'California',
      value: 'California',
    },
  ],
};

const DEFAULT_SUB_OPTIONS = {
  retail: [
    {
      label: 'Appliance',
      value: 'Appliance',
    },
  ],
};

const DEFAULT_STRIKE_OPTIONS = {
  retail: [
    {
      label: 'Appliance Store',
      value: 'Appliance Store',
    },
  ],
};

export default class Prospector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allProspectsRejected: false,
      cityOptions: [],
      error: false,
      fetchProspectsError: null,
      filteredLeads: [],
      inputError: false,
      isContactModalShowing: false,
      lastSession: JSON.parse(localStorage.getItem('currentSession')) || '',
      loading: false,
      midSearchLeadCount: 0,
      modalContacts: [],
      modalProspectId: null,
      noLeadsFound: false,
      postalCodeOptions: [],
      prospectingData: [],
      pullCount: JSON.parse(localStorage.getItem('pullCount')) >= 5 ? 5 : JSON.parse(localStorage.getItem('pullCount')) || '',
      searchTerminated: false,
      selectedCity: JSON.parse(localStorage.getItem('chosenCity')) || '',
      selectedPostalCode:
        JSON.parse(localStorage.getItem('chosenPostalCode')) || '',
      selectedState:
        JSON.parse(localStorage.getItem('chosenState')) || DEFAULT_STATE,
      selectedVertical:
        JSON.parse(localStorage.getItem('chosenVertical')) || DEFAULT_VERTICAL,
      selectedVerticalId:
        parseInt(localStorage.getItem('chosenVerticalId'), 10) ||
        DEFAULT_VERTICAL.id,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        DEFAULT_SUBVERTICAL,
      selectedStrikezone:
        JSON.parse(localStorage.getItem('chosenStrikezone')) ||
        DEFAULT_STRIKEZONE,
      selectedSubverticalOptions: DEFAULT_SUB_OPTIONS.retail,
      selectedStrikezoneOptions: DEFAULT_STRIKE_OPTIONS.retail,
      selectedStateOptions: SET_DEFAULT_OPTIONS.state,
      selectedVerticalOptions: SET_DEFAULT_OPTIONS.retail,
      statusScreenMode: 'sitTight',
      showMultiLocs: false,
      starRatingMin: 0.0,
      starRatingMax: 5.0,
      // summaries: {},
      // totalPostalCodesForSearchArea: 0,
      sentToSalesforce: false,
      uploadingToSalesforce: false,
    };
  }


  /* eslint-disable no-console, camelcase, no-param-reassign */
  componentDidMount() {
    // socket.connect();
    const { email } = getCurrentUser();
    const prospectorChannel = getProspectorChannel();
    const prospectorAdminChannel = getProspectorAdminChannel();

    prospectorChannel.onError(() => {
      startTimer("Prospector - Error Returned in Leads Search", currentTimerRequestUID)

      if (this.state.prospectingData.length === 0) {
        this.setState({
          error: true,
          loading: false,
          noLeadsFound: false,
          sentToSalesforce: false,
        });
      };
    });

    prospectorChannel.on('fetch-leads', (params) => {
      if (params.prospects.length === 0) {
        startTimer("Prospector - No Results Dolphin was Shown", currentTimerRequestUID)
        this.setState({ noLeadsFound: true });
        setTimeout(() => {
          window.location.reload();
        }, 60000);
      }
      const prospects = params.prospects.map((prospect) => {
        prospect.first_name = '';
        prospect.last_name = '';
        prospect.title = '';
        prospect.phone = prospect.phone_number ? prospect.phone_number : '';
        prospect.mobile_phone = '';
        prospect.alt_phone = '';
        prospect.email = '';
        prospect.locations = '';
        prospect.description = prospect.google_rating
          ? `${prospect.google_review_count} reviews | ${prospect.google_rating}/5 Stars`
          : '';
        prospect.in_strikezone = '';
        prospect.rejection_reason = '';
        prospect.additional_rejection_data = [];
        prospect.owner_email = email;

        return prospect;
      });
      this.setState({
        prospectingData: prospects,
        loading: false,
        filteredLeads: [],
      });

      if ( searchTimer ) {
        // Resolve Timers
        stopTimer(searchTimer);
        searchTimer = null;
        this.timeLoadingPagePauses(true);

        // Set new Request UID, to handle the review and everything pre-submit
        currentTimerRequestUID = uuidv4();
        userTimeOnResultsPageTimer = startTimer("Prospector - User time navigating the results page", currentTimerRequestUID);
      }
    });

    this.timeLoadingPagePauses = (stopNow) => {
      if ( loadingPagePauseTimer !== null ) {
        stopTimer(loadingPagePauseTimer);
        loadingPagePauseTimer = null;
      }
      if ( loadingPagePauseTimeout ) { clearTimeout(loadingPagePauseTimeout); }
      if (! stopNow ) {
        loadingPagePauseTimeout = setTimeout(() => {
          loadingPagePauseTimer = startTimer("Prospector - Loading page pause > 1 sec", currentTimerRequestUID);
        }, 1000)
      }
    }

    prospectorChannel.on('lead-count', (params) => {
      this.timeLoadingPagePauses()
      this.setState({
        midSearchLeadCount: params.lead_count,
      });
      
      if(params.lead_count >= 1 && params.lead_count < this.state.pullCount) {
        this.setState({ statusScreenMode: 'hasSomeLeads' });
      };
      
      if(params.lead_count >= this.state.pullCount || this.state.searchTerminated) {
        this.setState({ statusScreenMode: 'preparingResults' });
      };
    });

    prospectorChannel.on('lead-filtered', (params) => {
      this.timeLoadingPagePauses()
      this.setState({
        filteredLeads: [params.filtered, ...this.state.filteredLeads],
      });
    });

    prospectorChannel.on('search-message', (params) => {
      this.timeLoadingPagePauses()
      this.setState({
        filteredLeads: [params.message, ...this.state.filteredLeads],
      });
    });

    this.prospectorChannel = prospectorChannel;
    this.prospectorAdminChannel = prospectorAdminChannel;

    getDropDowns(VERTICAL_ENDPOINT).then((res) => {
      return this.processVerticals(res);
    });

    getDropDowns(STATES_ENDPOINT).then((res) => {
      return this.processStates(res);
    });

    getDropDowns(SUBVERTICAL_ENDPOINT)
      .then((res) => {
        return res;
      })
      .then((subVerts) => {
        getDropDowns(STRIKEZONE_ENDPOINT).then((res) => {
          return this.processStrikeZonesAndSubverticals(res, subVerts);
        });
      });

    if (this.state.selectedState) {
      getDropDowns(
        `${CITIES_BY_STATE_ENDPOINT}${this.state.selectedState.value}`
      ).then((res) => {
        return this.processCitiesForState(res);
      });

      // getProspectSummaries(
      //   this.state.selectedStrikezone.id,
      //   this.state.selectedState.id
      // ).then(res => {
      //   return this.processProspectSummaries(res);
      // });

      if (this.state.selectedCity && this.state.selectedCity.value) {
        getDropDowns(
          `${POSTAL_CODES_BY_CITY_AND_STATE_ENDPOINT}city=${this.state.selectedCity.value}&state=${this.state.selectedState.value}`
        ).then((res) => {
          return this.processPostalCodesForCityAndState(res);
        });
      } else {
        getDropDowns(
          `${POSTAL_CODES_BY_STATE_ENDPOINT}${this.state.selectedState.value}`
        ).then((res) => {
          return this.processPostalCodesForState(res);
        });
      }
    };
  }

  /* eslint-enable no-console, camelcase, no-param-reassign */
  processStates = (res) => {
    const selectedStateOptions = res.map((v) => {
      return { id: v.id, label: v.name, value: v.name };
    });

    this.setState({ selectedStateOptions });
  };

  processCitiesForState = (res) => {
    const cities = res.map((v) => {
      return {
        label: `${v.name} (${v.zip_count})`,
        value: v.name,
        id: v.id,
        zip_count: v.zip_count,
      };
    });

    cities
      .sort((a, b) => (a.zip_count > b.zip_count ? -1 : 1))
      .unshift({ label: `All Cities (${cities.length})`, value: '' });

    this.setState({
      cityOptions: cities,
      selectedCity: JSON.parse(localStorage.getItem('chosenCity')) || cities[0],
    });
  };

  processPostalCodesForState = (res) => {
    const postalCodes = res.map((v) => {
      return {
        label: v.postal_code,
        value: v.postal_code,
        city_id: v.new_city_id,
      };
    });

    postalCodes
      .sort((a, b) => (a.label > b.label ? 1 : -1))
      .unshift({
        label: `All Postal Codes (${postalCodes.length})`,
        value: '',
      });

    this.setState({
      postalCodeOptions: postalCodes,
      selectedPostalCode: postalCodes[0],
      // totalPostalCodesForSearchArea: postalCodes.length - 1
    });
  };

  processPostalCodesForCityAndState = (res) => {
    const postalCodes = res.map((v) => {
      return {
        label: v.postal_code,
        value: v.postal_code,
        city_id: v.new_city_id,
      };
    });

    postalCodes
      .sort((a, b) => (a.label > b.label ? 1 : -1))
      .unshift({
        label: `All Postal Codes (${postalCodes.length})`,
        value: '',
      });

    this.setState({
      postalCodeOptions: postalCodes,
      selectedPostalCode: postalCodes[0],
      // totalPostalCodesForSearchArea: postalCodes.length - 1
    });
  };

  processCityForPostalCode = (cityId) => {
    const newChosenCity = _.find(this.state.cityOptions, (city) => {
      return city.id === cityId;
    });

    localStorage.setItem('chosenCity', JSON.stringify(newChosenCity));

    this.setState({
      selectedCity: newChosenCity,
    });
  };

  processVerticals = (res) => {
    let selectedVerticalOptions = res.map((v) => {
      return { label: v.name, value: v.name, id: v.id };
    });

    selectedVerticalOptions = selectedVerticalOptions.sort((a, b) =>
      a.value > b.value ? 1 : -1
    );

    this.setState({ selectedVerticalOptions });
  };

  processStrikeZonesAndSubverticals = (res, subVerticals) => {
    const formatedStrikeZone = {};
    const validSubVerticals = [];
    const { selectedVerticalId } = this.state;

    res.map((strikezone) => {
      const subVerticalWithStrikezone = _.find(subVerticals, (obj) => {
        return obj.id === strikezone.sub_vertical_id;
      });
      const formatedSubVertical = {
        label: subVerticalWithStrikezone.name,
        value: subVerticalWithStrikezone.name,
        vertical_id: subVerticalWithStrikezone.vertical_id,
      };

      if (!_.some(validSubVerticals, formatedSubVertical)) {
        validSubVerticals.push(formatedSubVertical);
      }

      if (formatedStrikeZone[subVerticalWithStrikezone.name]) {
        formatedStrikeZone[subVerticalWithStrikezone.name].push({
          value: strikezone.name,
          label: strikezone.name,
          ...strikezone,
        });
      } else {
        formatedStrikeZone[subVerticalWithStrikezone.name] = [
          {
            value: strikezone.name,
            label: strikezone.name,
            ...strikezone,
          },
        ];
      }

      return formatedStrikeZone;
    });

    let selectedSubverticalOptions = _.filter(
      validSubVerticals,
      (sub) => sub.vertical_id === selectedVerticalId
    );

    selectedSubverticalOptions = selectedSubverticalOptions.sort((a, b) =>
      a.value > b.value ? 1 : -1
    );

    const selectedStrikezone =
      JSON.parse(localStorage.getItem('chosenStrikezone')) ||
      formatedStrikeZone[selectedSubverticalOptions[0].value][0];

    this.setState({
      validSubVerticals,
      strikezoneOptions: formatedStrikeZone,
      selectedSubverticalOptions,
      selectedSubvertical:
        JSON.parse(localStorage.getItem('chosenSubvertical')) ||
        selectedSubverticalOptions[0],
      selectedStrikezoneOptions:
        formatedStrikeZone[this.state.selectedSubvertical.value],
      selectedStrikezone,
    });

    return formatedStrikeZone;
  };

  // processProspectSummaries = res => {
  //   this.setState({ summaries: res });
  //   return res;
  // };

  doBulkUpdateProspects = async (prospects) => {
    const response = await bulkUpdateProspects(prospects);
    return response;
  };

  showSettingStatusMark = (term) => {
    if ((typeof term === 'string' && term.length > 0) || term > 0) {
      return '✔';
    }
    return 'o ';
  };

  showStatusMark = (strikezone) => {
    switch (strikezone) {
      case 'yes':
        return '✔';
      case 'no':
        return '✕';
      default:
        return 'o ';
    }
  };

  trackAcceptedProspects = () => {
    const { prospectingData } = this.state;
    let count = 0;

    _.map(prospectingData, (prospect) => {
      if (prospect.in_strikezone === 'yes') {
        count += 1;
      }
    });
    return count;
  };

  // Handles
  handleVerticalChange = (option) => {
    localStorage.setItem('chosenVertical', JSON.stringify(option));
    const { validSubVerticals, selectedVerticalOptions } = this.state;

    const selectedOption = _.find(
      selectedVerticalOptions,
      (optn) => optn.value === option.value
    );
    localStorage.setItem('chosenVerticalId', selectedOption.id);

    const selectedSubverticalOptions = _.filter(
      validSubVerticals,
      (sub) => sub.vertical_id === selectedOption.id
    ).sort((a, b) => (a.value > b.value ? 1 : -1));

    const hasOneSubvertical = selectedSubverticalOptions.length > 0;

    this.setState({
      selectedVertical: option,
      selectedSubverticalOptions,
      selectedSubvertical: hasOneSubvertical
        ? selectedSubverticalOptions[0]
        : '',
    });
    if (hasOneSubvertical) {
      this.handleSubverticalChange(selectedSubverticalOptions[0]);
    }
  };

  handleSubverticalChange = (option) => {
    const { strikezoneOptions } = this.state;
    localStorage.setItem('chosenSubvertical', JSON.stringify(option));
    localStorage.setItem(
      'chosenStrikezone',
      JSON.stringify(strikezoneOptions[option.value][0])
    );

    this.setState({
      selectedSubvertical: option,
      selectedStrikezoneOptions: strikezoneOptions[option.value],
      selectedStrikezone: strikezoneOptions[option.value]
        ? strikezoneOptions[option.value][0]
        : '',
    });

    if (strikezoneOptions[option.value]) {
      this.handleStrikezoneChange(strikezoneOptions[option.value][0]);
    }
  };

  handleStrikezoneChange = (option) => {
    localStorage.setItem('chosenStrikezone', JSON.stringify(option));
    this.setState({
      selectedStrikezone: option,
    });
    // const { selectedState } = this.state;
    // getProspectSummaries(option.id, selectedState.id).then(res => {
    //   return this.processProspectSummaries(res);
    // });
  };
  handleStateChange = (option) => {
    localStorage.setItem('chosenState', JSON.stringify(option));
    localStorage.removeItem('chosenCity');
    localStorage.removeItem('chosenPostalCode');
    this.setState({
      selectedState: option,
      selectedCity: this.state.cityOptions[0],
    });

    getDropDowns(`${CITIES_BY_STATE_ENDPOINT}${option.value}`).then((res) => {
      return this.processCitiesForState(res);
    });

    getDropDowns(`${POSTAL_CODES_BY_STATE_ENDPOINT}${option.value}`).then(
      (res) => {
        return this.processPostalCodesForState(res);
      }
    );

    // getProspectSummaries(this.state.selectedStrikezone.id, option.id).then(
    //   res => {
    //     return this.processProspectSummaries(res);
    //   }
    // );
  };

  /* eslint-disable consistent-return */
  /* eslint-disable no-else-return */
  /* eslint-disable no-plusplus */
  handleCityChange = (option) => {
    const { selectedState } = this.state;
    localStorage.setItem('chosenCity', JSON.stringify(option));
    localStorage.removeItem('chosenPostalCode');

    this.setState({
      selectedCity: option,
      selectedPostalCode: '',
    });

    if (option.value === '') {
      getDropDowns(
        `${POSTAL_CODES_BY_STATE_ENDPOINT}${selectedState.value}`
      ).then((res) => {
        return this.processPostalCodesForState(res);
      });
    } else {
      getDropDowns(
        `${POSTAL_CODES_BY_CITY_AND_STATE_ENDPOINT}city=${option.value}&state=${selectedState.value}`
      ).then((res) => {
        return this.processPostalCodesForCityAndState(res);
      });
    }
  };

  handlePostalCodeChange = (option) => {
    localStorage.setItem('chosenPostalCode', JSON.stringify(option));

    this.setState({
      selectedPostalCode: option,
      // totalPostalCodesForSearchArea: 1
    });

    this.processCityForPostalCode(option.city_id);
  };

  handlePullCountChange = (count) => {
    localStorage.setItem('pullCount', JSON.stringify(count));
    this.setState({
      pullCount: count,
    });
  };

  handleStarRatingChange = (sliderValues) => {
    const [minimum, maximum] = sliderValues;

    this.setState({
      starRatingMin: minimum,
      starRatingMax: maximum,
    });
  };

  handleFetchProspectsClickSocket = () => {
    const {
      pullCount,
      selectedStrikezone,
      selectedState,
      selectedPostalCode,
      selectedCity,
      showMultiLocs,
      starRatingMin,
      starRatingMax,
    } = this.state;
    const { email } = getCurrentUser();
    analytics.fireEvent({
      category: 'Moneyball',
      action: 'Click Fetch Prospects',
    });
    if (selectedStrikezone && selectedState && pullCount) {
      currentTimerRequestUID = uuidv4();
      searchTimer = startTimer("Prospector - Fetch Prospects", currentTimerRequestUID);
      this.timeLoadingPagePauses()

      this.setState({ loading: true });
      this.prospectorChannel
        .push('fetch-leads', {
          state: selectedState.value,
          city: selectedCity.value,
          postal_code: selectedPostalCode.value,
          strikezone: selectedStrikezone.value,
          // sub_vertical: selectedSubvertical,
          count: pullCount,
          show_multi_locs: showMultiLocs,
          star_rating_min: starRatingMin,
          star_rating_max: starRatingMax,
          user: email,
          session_uid: getSessionUID(),
          request_uid: currentTimerRequestUID,
        })
        .receive('error', (e) => console.log(e));
    }
  };

  handleToggleContactModal = (prospectId, contacts) => {
    this.setState({
      isContactModalShowing: !this.state.isContactModalShowing,
      modalProspectId: prospectId,
      modalContacts: contacts,
    });
  };

  handleLeadDecision = (id, decision) => {
    const newData = _.map(this.state.prospectingData, (prospect) => {
      if (prospect.id !== id) {
        return prospect;
      }

      if (prospect.in_strikezone === decision) {
        return { ...prospect, in_strikezone: '' };
      }

      return { ...prospect, in_strikezone: decision };
    });
    localStorage.setItem('currentSession', JSON.stringify(newData));
    this.setState({
      prospectingData: newData,
    });
  };

  handleTextFieldUpdate = (id, key, value) => {
    const newData = _.map(this.state.prospectingData, (prospect) => {
      if (prospect.id !== id) {
        return prospect;
      }

      return { ...prospect, [key]: value };
    });
    localStorage.setItem('currentSession', JSON.stringify(newData));
    this.setState({
      prospectingData: newData,
    });
  };

  handleInputError = (value) => {
    this.setState({ inputError: value });
  };

  handleRejectSelectUpdate = (id, reason) => {
    const newData = _.map(this.state.prospectingData, (prospect) => {
      if (prospect.id !== id) {
        return prospect;
      }

      return { ...prospect, rejection_reason: reason.value };
    });
    localStorage.setItem('currentSession', JSON.stringify(newData));
    this.setState({
      prospectingData: newData,
    });
  };

  handleAdditionalRejectionData = (id, arr) => {
    const flattenedArray = arr.map(x => x.status === true ? x.text : "" ).filter(e => String(e).trim());
    
    const newData = _.map(this.state.prospectingData, (prospect) => {
      if (prospect.id !== id) {
        return prospect;
      }

      return { ...prospect, additional_rejection_data: flattenedArray };
    });

    this.setState({
      prospectingData: newData
    })
  };

  handleRestoreSession = () => {
    const previousSession = localStorage.getItem('currentSession');

    this.setState({
      error: false,
      prospectingData: JSON.parse(previousSession),
    });
  };

  handleSubmitFinishedProspector = async () => {
    const {
      prospectingData,
      selectedVertical,
      selectedSubvertical,
      selectedStrikezone,
    } = this.state;
    let rejectsCount = 0;
    const noRejectionReason = _.find(
      prospectingData,
      (prospect) =>
        prospect.in_strikezone === 'no' && prospect.rejection_reason === ''
    );
    if (noRejectionReason) {
      alert('Please select a rejection reason for all rejected leads.');
      return null;
    }
    if (this.state.inputError) {
      alert('Please fix all errors in the input fields below');
      return null;
    }
    const filteredProspectingData = _.filter(
      prospectingData,
      (prospect) => prospect.in_strikezone !== ''
    );
    const enhancedProspectingData = filteredProspectingData.map((prospect) => {
      const newProspect = prospect;
      newProspect.strikezone_id = selectedStrikezone.id;
      return newProspect;
    });

    // End the timer for time user spent on results page
    stopTimer(userTimeOnResultsPageTimer)
    userTimeOnResultsPageTimer = null;

    // Set new Request UID, to track how long the submit takes
    currentTimerRequestUID = uuidv4();
    submitLeadsTimer = startTimer("Prospector - Submitting leads to SalesForce", currentTimerRequestUID);

    const { email } = getCurrentUser();
    const batch = await createBatch(enhancedProspectingData, email);
    if (!batch.uid) {
      startTimer("Prospector - Error Submitting leads to SalesForce", currentTimerRequestUID)
      this.setState({
        error: true,
      });
      stopTimer(submitLeadsTimer)
      submitLeadsTimer = null
      return null;
    }
    _.map(filteredProspectingData, async (prospect) => {
      this.setState({ uploadingToSalesforce: true });
      // eslint-disable-next-line no-param-reassign
      prospect.batch_id = batch.uid;
      console.log('just the prospect', prospect);
      const response = await updateProspect(prospect);
      console.log('response from update', response);

      if (prospect.in_strikezone === 'yes') {
        const finalData = {
          additionalNotes: prospect.description,
          address: prospect.street_address,
          city:
            prospect.city.length > 40
              ? prospect.city.substring(0, 39)
              : prospect.city,
          contact: {
            firstName: prospect.first_name,
            lastName: prospect.last_name,
            email: prospect.email,
            mobileNumber: prospect.mobile_phone,
            altPhoneNumber: prospect.alt_phone,
            title: prospect.title,
          },
          country: prospect.country,
          locations: prospect.locations,
          employeeCount: prospect.zoominfo_employee_count,
          formattedAddress: '',
          formattedPhoneNumber: prospect.phone,
          leadSource: 'Moneyball',
          name: prospect.company_name,
          ownerEmail: prospect.owner_email || email,
          placeId: prospect.google_place_id,
          postalCode: prospect.postal_code,
          recordType: 'Business',
          revenue: prospect.zoominfo_revenue,
          state: prospect.state,
          subvertical: selectedSubvertical.value,
          vertical: selectedVertical.value,
          website: prospect.website,
        };
        const result = await createSalesforceLead(finalData);
        console.log('result from salesforce upload', result);
      };
    });

    _.map(prospectingData, (prospect) => {
      if(prospect.in_strikezone !== 'yes') {
        rejectsCount++;
      } else {
        rejectsCount--;
      };
    });

    if(rejectsCount === this.state.pullCount) {
      this.setState({
        allProspectsRejected: true
      });
    } else {
      this.setState({
        sentToSalesforce: true,
        uploadingToSalesforce: false,
      });
    };

    stopTimer(submitLeadsTimer)
    submitLeadsTimer = null
    localStorage.removeItem('currentSession');
    analytics.fireEvent({
      category: 'Moneyball',
      action: 'Click Submit Prospects',
    });
    setTimeout(() => {
      window.location.reload();
    }, 60000);
    return null;
  };

  handleShowMultiLocs = () => {
    this.setState({
      showMultiLocs: !this.state.showMultiLocs,
    });
  };

  handleBackButtonRedirect = () => {
    localStorage.removeItem('currentSession');
    window.location.reload();
  };

  terminateSearch = () => {
    this.prospectorAdminChannel
        .push('terminate-search', {
          request_uid: currentTimerRequestUID
        })
        .receive('error', (e) => console.log(e));
    this.setState({
      searchTerminated: true,
      statusScreenMode: 'preparingResults'
    })
  };

  // eslint-disable-next-line arrow-body-style
  renderSomeFilters = (filter) => {
    return <FilterRow> {filter} </FilterRow>;
  };

  render() {
    const {
      additionalRejectionData,
      allProspectsRejected,
      cityOptions,
      error,
      fetchProspectsError,
      filteredLeads,
      isContactModalShowing,
      lastSession,
      loading,
      midSearchLeadCount,
      modalContacts,
      modalProspectId,
      noLeadsFound,
      postalCodeOptions,
      selectedPostalCode,
      selectedCity,
      prospectingData,
      pullCount,
      selectedState,
      selectedStateOptions,
      selectedStrikezone,
      selectedStrikezoneOptions,
      selectedSubverticalOptions,
      selectedSubvertical,
      selectedVertical,
      selectedVerticalOptions,
      showMultiLocs,
      starRatingMin,
      starRatingMax,
      statusScreenMode,
      // summaries,
      sentToSalesforce,
      uploadingToSalesforce,
    } = this.state;

    return (
      <ProspectingContainer>
        <StatusScreen
          allProspectsRejected={allProspectsRejected}
          error={error}
          lastSession={lastSession}
          leadCount={midSearchLeadCount}
          loading={loading}
          filteredLeads={filteredLeads}
          handleEarlyTerminate={this.terminateSearch}
          noLeadsFound={noLeadsFound}
          onBackButtonRedirect={this.handleBackButtonRedirect}
          onRenderSomeFilters={this.renderSomeFilters}
          onRestoreSession={this.handleRestoreSession}
          midSearchProspectCount={this.midSearchProspectCount}
          sentToSalesforce={sentToSalesforce}
          statusScreenMode={statusScreenMode}
          uploadingToSalesforce={uploadingToSalesforce}
        />
        {!loading &&
          !sentToSalesforce &&
          !uploadingToSalesforce &&
          !error &&
          !noLeadsFound && (
            <React.Fragment>
              <MainBody>
                {prospectingData.length === 0 && (
                  <React.Fragment>
                    <ProspectingDropdowns
                      cityOptions={cityOptions}
                      handleCityChange={this.handleCityChange}
                      handlePostalCodeChange={this.handlePostalCodeChange}
                      handlePullCountChange={this.handlePullCountChange}
                      handleStateChange={this.handleStateChange}
                      handleStrikezoneChange={this.handleStrikezoneChange}
                      handleSubverticalChange={this.handleSubverticalChange}
                      handleVerticalChange={this.handleVerticalChange}
                      handleStarRatingChange={this.handleStarRatingChange}
                      handleShowMultiLocs={this.handleShowMultiLocs}
                      postalCodeOptions={postalCodeOptions}
                      pullCount={pullCount}
                      selectedCity={selectedCity}
                      selectedPostalCode={selectedPostalCode}
                      selectedState={selectedState}
                      selectedStateOptions={selectedStateOptions}
                      selectedStrikezone={selectedStrikezone}
                      selectedStrikezoneOptions={selectedStrikezoneOptions}
                      selectedSubvertical={selectedSubvertical}
                      selectedSubverticalOptions={selectedSubverticalOptions}
                      selectedVertical={selectedVertical}
                      selectedVerticalOptions={selectedVerticalOptions}
                      showMultiLocs={showMultiLocs}
                      starRatingMin={starRatingMin}
                      starRatingMax={starRatingMax}
                    />
                    <React.Fragment>
                      <ProspectingButton
                        onClick={this.handleFetchProspectsClickSocket}
                        height={40}
                      >
                        <Tooltip
                          title={toolTips.fetchProspects}
                          position="right"
                          trigger="mouseenter"
                          size="big"
                          arrow
                          distance={30}
                          offset={-40}
                          delay={400}
                          hideDelay={0}
                        >
                          <span>Fetch Prospects</span>
                        </Tooltip>
                      </ProspectingButton>
                      <br />
                      {fetchProspectsError && (
                        <ApiError>{fetchProspectsError}</ApiError>
                      )}
                    </React.Fragment>
                  </React.Fragment>
                )}
                <ProspectorList
                  additionalRejectionData={additionalRejectionData}
                  prospectingData={prospectingData}
                  onInputError={this.handleInputError}
                  onDecisionButtonClick={this.handleLeadDecision}
                  onCardTextFieldUpdate={this.handleTextFieldUpdate}
                  onRejectSelectUpdate={this.handleRejectSelectUpdate}
                  onAdditionalRejectionData={this.handleAdditionalRejectionData}
                  keepButtonsActive={prospectingData.in_strikezone}
                  toggleContactModal={this.handleToggleContactModal}
                  goBack={this.handleBackButtonRedirect}
                />
                <ContactModal
                  isShowing={isContactModalShowing}
                  contacts={modalContacts}
                  prospectId={modalProspectId}
                  prospectingData={prospectingData}
                  toggleContactModal={this.handleToggleContactModal}
                  onTextFieldUpdate={this.handleTextFieldUpdate}
                />
              </MainBody>
              <RightColumn>
                <ProspectTracker
                  handleSubmit={this.handleSubmitFinishedProspector}
                  prospectingData={prospectingData}
                  pullCount={pullCount}
                  selectedCity={selectedCity}
                  selectedPostalCode={selectedPostalCode}
                  selectedState={selectedState}
                  selectedStrikezone={selectedStrikezone}
                  selectedSubvertical={selectedSubvertical}
                  selectedVertical={selectedVertical}
                  showStatusMark={this.showStatusMark}
                  showSettingStatusMark={this.showSettingStatusMark}
                  starRatingMin={starRatingMin}
                  starRatingMax={starRatingMax}
                  trackAccepted={this.trackAcceptedProspects}
                />
                {/* <ProspectSummaries
                  summaries={summaries}
                  selectedCity={selectedCity.value || ''}
                  selectedPostalCode={selectedPostalCode}
                  selectedState={selectedState.value}
                  totalPostalCodes={this.state.totalPostalCodesForSearchArea}
                /> */}
              </RightColumn>
            </React.Fragment>
          )}
      </ProspectingContainer>
    );
  }
}