import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input, Select } from '@podiumhq/podium-ui';
import {
  states,
} from '../../../lib/toolData';
import { ButtonSpinner } from '../styles';

import {
  CityStateZip,
  ScoreFormContainer,
  ScoreSelect,
  Section,
  TwoColumns,
  UploadButton,
  PositiveResultContainer,
  UploadSuccess
} from './styles';

const {
  string,
  func,
  bool
} = PropTypes;

export default class ScoreResult extends Component {
  static propTypes = {
    onPrepareUpload: func.isRequired,
    loading: bool.isRequired,
    createdLeadId: string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      data: {},
      additionalData: {
        recordType: 'Business',
        additionalNotes: '',
      },
      contactData: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: ''
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      data: {
        ...state.data,
        ...props.data
      }
    };
  }

  handleInputChange = (key, event) => {
    const { data: oldData } = this.state;
    this.setState({
      data: {
        ...oldData,
        [key]: event.target.value
      }
    });
  };

  handleContactInputChange = (key, event) => {
    const { contactData: oldContactData } = this.state;
    this.setState({
      contactData: {
        ...oldContactData,
        [key]: event.target.value
      }
    });
  };

  handleStateSelect = (state) => {
    const { data: oldData } = this.state;
    this.setState({
      data: {
        ...oldData,
        state
      }
    });
  };

  handleAdditionalDataInputChange = (key, event) => {
    const { additionalData: oldData } = this.state;
    this.setState({
      additionalData: {
        ...oldData,
        [key]: event.target.value
      }
    });
  };

  prepareUploadToSalesforce = () => {
    const { additionalData, contactData } = this.state;
    const { data } = this.state;
    const mergedData = {
      ...additionalData,
      ...data,
      contact: contactData
    };
    this.props.onPrepareUpload(mergedData);
  };

  render() {
    const { data, contactData, additionalData } = this.state;
    const { loading, createdLeadId } = this.props;

    const hasBusinessData = _.has(data, 'name');
    const businessName = _.get(data, 'name', 'No Location Selected');

    return (
      <PositiveResultContainer>
        <h1 className={!hasBusinessData ? 'disabled' : ''}>{businessName}</h1>
        {hasBusinessData && (
          <ScoreFormContainer>
            <Section>
              <h4>Location Details</h4>
              <Input onChange={_.partial(this.handleInputChange, 'address')} value={data.address} className="score-input" label="Address" />
              <CityStateZip>
                <Input onChange={_.partial(this.handleInputChange, 'city')} value={data.city} className="score-input" label="City" />
                <ScoreSelect>
                  <Select
                    theme="light"
                    label="State"
                    options={states}
                    onChange={option => this.handleStateSelect(option)}
                    value={data.state}
                  />
                </ScoreSelect>
                <Input onChange={_.partial(this.handleInputChange, 'postalCode')} value={data.postalCode} className="score-input" label="Zip Code" />
              </CityStateZip>
            </Section>
            <Section>
              <h4>Business Details</h4>
              <Input onChange={_.partial(this.handleInputChange, 'website')} value={data.website} className="score-input" label="Website" />
              <Input onChange={_.partial(this.handleInputChange, 'formattedPhoneNumber')} value={data.formattedPhoneNumber} className="score-input" label="Business Phone" />
            </Section>
            <Section>
              <h4>Contact Details</h4>
              <TwoColumns>
                <Input value={contactData.firstName} onChange={_.partial(this.handleContactInputChange, 'firstName')} className="score-input" label="First Name" />
                <Input value={contactData.lastName} onChange={_.partial(this.handleContactInputChange, 'lastName')} className="score-input" label="Last Name" />
                <Input value={contactData.email} onChange={_.partial(this.handleContactInputChange, 'email')} className="score-input" label="Email" />
                <Input value={contactData.phoneNumber} onChange={_.partial(this.handleContactInputChange, 'phoneNumber')} className="score-input" label="Phone Number" />
              </TwoColumns>
            </Section>
            <Section>
              <h4>Additional Notes</h4>
              <Input value={additionalData.additionalNotes} onChange={_.partial(this.handleAdditionalDataInputChange, 'additionalNotes')} textarea />
            </Section>
            <UploadButton onClick={this.prepareUploadToSalesforce} height={40} >
              Upload to Salesforce
              {loading && <ButtonSpinner width={20} height={20} color="white" />}
            </UploadButton>
            {createdLeadId &&
              <UploadSuccess>
                <p>Successfully Uploaded! You can see your new lead <a target="blank" href={`https://podium.my.salesforce.com/${createdLeadId}`}>Here</a></p>
              </UploadSuccess>
            }
          </ScoreFormContainer>
        )}
      </PositiveResultContainer>
    );
  }
}
