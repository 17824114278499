import styled from 'styled-components';

// const hunterButtonClicked = (props) => {
//   if (props.isClicked) {
//     return `background-image: ${icon.addContactClicked}`;
//   }
//   return `background-image: ${icon.addContact}`;
// };

export const contentStyles = `
    background-color: ghostwhite;
    margin: auto;
    max-height: 350px;
    overflow: auto;
    padding-top: 15px;
`;

export const ContactWrapper = styled.div`
  display: inline;
  margin: inherit;
  background-color: rgba(137, 150, 160, 1);

  h4 {
    margin: 10px 0 10px 0;
    text-align: initial;
  }

  p {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #778692;
    text-align: initial;
    font-size: 14px;
  }
`;

export const ContactBox = styled.div`
  color: black;
  padding: 10px 5px 10px 5px;
  background-color: white;
  margin-bottom: 10px;
  font-size: 14px;

  .addContact {
    cursor: pointer;

  }
`;

export const AddContactDiv = styled.div`
  color: black;
  float: right;
`;
