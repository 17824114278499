import styled from 'styled-components';
import { ScoringButton } from '../LeadScoring/styles';
import { NearbyCustomerDiv } from '../Prospector/ProspectorCards/styles';

const getButtonColor = (props) => {
  if (props.disabled) {
    return 'rgb(119, 134, 146)';
  }
  return '#4C76E0';
};

const getButtonHoverColor = (props) => {
  if (props.disabled) {
    return 'rgb(119, 134, 146)';
  }
  return '#2353cc';
};

export const CustomerDiv = styled(NearbyCustomerDiv)`
  width: 90%;
  margin: 10px auto;
  padding: 0 0;
  background-color: #F5F5F7;
`;

export const CustomerRow = styled.div`
  margin-bottom: 20px;
  background-color: ghostwhite;
  border-radius: 10px;

  .icon,
  .businessIcon {
    height: 30px;
    width: 30px;
  }

  .podium {
    height: 22px;
    width: 25px;
    margin-right: 5px;
  }

  #leftSide,
  #rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span {
    line-height: 2;
  }
`;

export const SuggestedText = styled.span`
  font-size: 14px;
  color: rgb(119, 134, 146);
`;

export const NameHeader = styled.div`
  background-color: rgb(119, 134, 146);
  border-radius: 10px 10px 0 0;
  height: 50px;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-weight: bolder;
`;

export const CustomerInfo = styled.div`
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
`;

export const CustomerButton = styled(ScoringButton)`
  margin-right: 15px;
  background-color: ${props => getButtonColor(props)};

  &:hover, &:focus {
    background-color: ${props => getButtonHoverColor(props)};
  }
`;

export const NearbyCustomersHeading = styled.p`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  font-family: 'Avenir', Helvetica, sans-serif;
`;

export const Divider = styled.div`
  background-color: #E4E9F0;
  height: 2px;
  margin: 5px 0;
  max-width: 1025px;
`;