import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button } from '@podiumhq/podium-ui';
import {
  SalesforceDuplicatesContainer,
  ScoreAnywayButton,
  Duplicate
} from './styles';
import { Section } from '../ScoreResult/styles';

const {
  string,
  shape,
  arrayOf,
  func
} = PropTypes;

const SalesforceDuplicates = ({ businessName, duplicates, onFetchAnyway }) => (
  <SalesforceDuplicatesContainer>
    <Section>
      <h1>{businessName}</h1>
      <h3>Looks like this location might already be in Salesforce</h3>
      <p>(Check Salesforce before scoring this location)</p>
    </Section>
    <Section>
      {_.map(duplicates, (duplicate, index) => (
        <Duplicate key={index} last={index + 1 === duplicates.length}>
          <span>{duplicate.business}</span>
          <span>({_.capitalize(duplicate.type)})</span>
          <Button onClick={() => window.open(duplicate.url, '_blank')} size="small">View in Salesforce</Button>
        </Duplicate>
      ))}
    </Section>
    <ScoreAnywayButton onClick={onFetchAnyway}>Fetch Anyway!</ScoreAnywayButton>
  </SalesforceDuplicatesContainer>
);

SalesforceDuplicates.propTypes = {
  businessName: string.isRequired,
  duplicates: arrayOf(shape({
    url: string,
    contact: string,
    business: string,
    type: string
  })).isRequired,
  onFetchAnyway: func.isRequired
};

export default SalesforceDuplicates;
