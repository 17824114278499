import styled from 'styled-components';

const HeaderContainer = styled.div`
  transition: 2s ease;

  .button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .status-icon {
    width: 200px;
    height: 200px;
  }

  .logo {
    display: flex;
    align-items: baseline;
    width: 100%;
    .svgLogo {
      height: 52px;
      width: 52px;
      padding: 12px;
      background-color: #2d2d2d;
    }
    .menu {
      a {
        transition: .5s;
        padding-left: 2.1em;
        font-size: 14px;
        color: #878787;
        opacity: 0.9;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
      }
      .selected {
        opacity: 1;
        color: #333333;
      }
    }

    .userInfo {
      display: flex;
      margin-left: auto;
      .fullName {
        font-size: 14px;
        color: #333333;
      }
      .userPhoto {
        margin-top: 7px;
        position: relative;
        img {
          width: 32px;
          height: auto;
          display: block;
          margin: auto 12px;
          border-radius: 50%;
        }
        &:hover .arrow {
          opacity: 1;
          transform: translate(0,17px);
        }
      }
      .arrow {
        opacity: 0;
        cursor: pointer;
        display: inline-block;
        height: 36px;
        width: 89px;
        position: relative;
        line-height: 2.5em;
        text-align: center;
        background: #333333;
        border-radius: 5px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        top: 25px;
        left: -15px;
        position: absolute;
        &:hover {
          background: #555555;
          &:before {
            background: #555555;
          }
        }
        &:before {
          position: absolute;
          top: -7px;
          width: 18px;
          height: 18px;
          background: #333;
          content: "";
          transform: rotate(45deg);
          left: 35.5px;
          right: auto;
          border-radius: 4px;
        }
      }
    }
  }
`;
export default HeaderContainer;
