import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as icon from '../../images/icon';
import { Row, PriceContainer } from './styles';
import { optimizer } from '../../lib/toolData';

const { func } = PropTypes;

class OptimizerSection extends Component {
  static propTypes = {
    onOptimizePriceSelected: func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      selected: false
    };
  }

  setOptimizer = () => {
    const selected = !this.state.selected;
    this.setState({ selected });

    this.props.onOptimizePriceSelected(selected);
  };

  render() {
    const { selected } = this.state;
    return (
      <div>
        <Row
          selected={selected}
          onClick={() => this.setOptimizer()}
        >
          <div className="optimizer-icon"><img src={icon.optimizer} alt="optimizer icon" /></div>
          <div className="content">
            <div className="titleSection">
              <div className="title">Premium Integration</div>
              <div>
                Integrate Podium with your system of record to automate select interactions
              </div>
            </div>
          </div>
          <PriceContainer>
            ${optimizer.price}<span className="duration">/month</span>
          </PriceContainer>
        </Row>
      </div>
    );
  }
}

export default OptimizerSection;
