/* global localStorage */
import React, { Component } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { Logo } from "@podiumhq/podium-ui";
import { NavLink } from "react-router-dom";
import { logout } from "../../lib/auth";
import HeaderContainer from "./styles";
import { toolTips } from "../Prospector/optionData";
import { getAdmins, getUsers } from "./../../lib/client";
import { getGreenUsers } from "./../../screens/EngRecruiting/greenClient";

const UPLOAD_TOOL_PATH = "/upload-tool";
const INTEGRATIONS_PATH = "/integrations";
const PROSPECTOR_PATH = "/prospector";
const PRIVATEGUI_PATH = "/privategui";
const NEARBY_CUSTOMERS_PATH = "/nearby-customers";
const ENGRECRUITING_PATH = "/eng-recruiting";

const { func, shape, string } = PropTypes;

class Header extends Component {
  static propTypes = {
    onLogout: func.isRequired,
    user: shape({
      displayName: string,
      photoURL: string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showEngRecruiting: false,
      showPrivateGui: false,
      showProspector: false,
    };
  }

  /* eslint-disable consistent-return */
  componentWillMount() {
    this.showEngRecruiting(this.state.user);
    this.showProspector(this.state.user);
    this.showPrivateGui(this.state.user);

    if (
      "chosenCity" in localStorage ||
      typeof JSON.parse(localStorage.getItem("chosenState")) === "object"
    ) {
      return null;
    }
    localStorage.removeItem("chosenVertical");
    localStorage.removeItem("chosenVerticalId");
    localStorage.removeItem("chosenSubvertical");
    localStorage.removeItem("chosenStrikezone");
    localStorage.removeItem("chosenState");
  }

  handleLogout = () => {
    logout();
    this.props.onLogout();
  };

  fetchUsers = async () => getUsers();
  
  fetchGreenUsers = async (email) => getGreenUsers(email);

  fetchAdmins = async () => getAdmins();

  showEngRecruiting = async () => {
    const isGreenUser = await this.fetchGreenUsers(this.props.user.email);

    this.setState({
      showEngRecruiting: isGreenUser === "Green User",
    });
  };

  showProspector = async () => {
    const validUsers = await this.fetchUsers();
    const validUserEmails = validUsers.map((u) => u.email);
    this.setState({
      showProspector: _.includes(validUserEmails, this.props.user.email),
    });
  };

  showPrivateGui = async () => {
    const admins = await this.fetchAdmins();
    const adminEmails = admins.map((a) => a.email);
    this.setState({
      showPrivateGui: _.includes(adminEmails, this.props.user.email),
    });
  };

  render() {
    const { user } = this.props;
    const { showProspector, showPrivateGui, showEngRecruiting } = this.state;

    return (
      <HeaderContainer>
        <div className="logo">
          <div className="svgLogo">
            <Logo hideText />
          </div>
          <div className="menu">
            <NavLink activeClassName="selected" to={UPLOAD_TOOL_PATH}>
              <Tooltip
                title={toolTips.moneyballTab}
                position="bottom"
                trigger="mouseenter"
                arrow
                distance={20}
              >
                Upload Tool
              </Tooltip>
            </NavLink>
            <NavLink activeClassName="selected" to={NEARBY_CUSTOMERS_PATH}>
              <Tooltip
                title={toolTips.nearbyTab}
                position="bottom"
                trigger="mouseenter"
                arrow
                distance={20}
              >
                Nearby Customers
              </Tooltip>
            </NavLink>
            <NavLink activeClassName="selected" to={INTEGRATIONS_PATH}>
              Integration Hub
            </NavLink>
            <a
              activeClassName="selected"
              href="http://help.podium.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Help Center
            </a>
            {showProspector && (
              <NavLink activeClassName="selected" to={PROSPECTOR_PATH}>
                {" "}
                <Tooltip
                  title={toolTips.prospector}
                  position="bottom"
                  trigger="mouseenter"
                  arrow
                >
                  Prospector
                </Tooltip>
              </NavLink>
            )}
            {showPrivateGui && (
              <NavLink activeClassName="selected" to={PRIVATEGUI_PATH}>
                {" "}
                Private Gui
              </NavLink>
            )}
            {showEngRecruiting && (
              <NavLink activeClassName="selected" to={ENGRECRUITING_PATH}>
                {" "}
                Eng. Recruiting
              </NavLink>
            )}
          </div>
          {user.displayName && user.photoURL && (
            <div className="userInfo">
              <p className="fullName">{user.displayName}</p>
              <div className="userPhoto">
                <img alt="user profile" src={user.photoURL} />
                <div
                  role="presentation"
                  onClick={() => this.handleLogout()}
                  className="arrow"
                >
                  Logout
                </div>
              </div>
            </div>
          )}
        </div>
      </HeaderContainer>
    );
  }
}

export default Header;
