import React from 'react';
import SpinnerContainer from './styles';

const Spinner = props => (
  <SpinnerContainer {...props} >
    <svg x="0px" y="0px" viewBox="0 0 150 150">
      <circle cx="75" cy="75" r="60" />
    </svg>
  </SpinnerContainer>
);

export default Spinner;
