import React from "react";
import _ from "lodash";
import {
  CompanyNameTracker,
  ProspectTrackerContainer,
  StatusMarker,
  SubmitButton
} from "../styles";

const ProspectTracker = ({
  handleSubmit,
  prospectingData,
  pullCount,
  selectedCity,
  selectedState,
  selectedPostalCode,
  selectedStrikezone,
  selectedSubvertical,
  selectedVertical,
  showStatusMark,
  showSettingStatusMark,
  starRatingMin,
  starRatingMax,
  trackAccepted
}) => (
  <ProspectTrackerContainer>
    {prospectingData.length === 0 && (
      <React.Fragment>
        <h4>Create Prospect List</h4>
        <p>Enter your prospect criteria to generate a prospect list.</p>
        <span>
          <StatusMarker status={selectedVertical.value}>
            {showSettingStatusMark(selectedVertical.value)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={selectedVertical.value}>
            Vertical
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={selectedSubvertical.value}>
            {showSettingStatusMark(selectedSubvertical.value)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={selectedSubvertical.value}>
            Subvertical
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={selectedStrikezone.value}>
            {showSettingStatusMark(selectedStrikezone.value)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={selectedStrikezone.value}>
            Strikezone
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={selectedState.value}>
            {showSettingStatusMark(selectedState.value)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={selectedState.value}>
            State
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={selectedCity.label}>
            {showSettingStatusMark(selectedCity.label)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={selectedCity.label}>
            City
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={selectedPostalCode.label}>
            {showSettingStatusMark(selectedPostalCode.label)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={selectedPostalCode.label}>
            Postal Code
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={starRatingMin || starRatingMax}>
            {showSettingStatusMark(starRatingMin || starRatingMax)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={starRatingMin || starRatingMax}>
            {`${starRatingMin} - ${starRatingMax} stars`}
          </CompanyNameTracker>
        </span>
        <span>
          <StatusMarker status={pullCount}>
            {showSettingStatusMark(pullCount)}
          </StatusMarker>
          {"\u00A0"}
          <CompanyNameTracker status={pullCount}>
            {pullCount ? `${pullCount} Leads` : "Number of Leads"}
          </CompanyNameTracker>
        </span>
      </React.Fragment>
    )}
    {prospectingData.length > 0 && (
      <React.Fragment>
        <h4>Accept and Submit</h4>
        <p>Accept or Reject each prospect profile, then submit.</p>
        {_.map(prospectingData, prospect => (
          <span>
            <StatusMarker status={prospect.in_strikezone}>
              {showStatusMark(prospect.in_strikezone)}
            </StatusMarker>
            {"\u00A0"}
            <CompanyNameTracker status={prospect.in_strikezone}>
              {prospect.company_name}
            </CompanyNameTracker>
          </span>
        ))}
        <h5>Total leads accepted: {trackAccepted()}</h5>
        <SubmitButton
          onClick={handleSubmit}
          height={40}
          disabled={prospectingData.length === 0}
        >
          <span>Submit</span>
        </SubmitButton>
      </React.Fragment>
    )}
  </ProspectTrackerContainer>
);

export default ProspectTracker;
