import React, { Component } from 'react';
import _ from 'lodash';
import { createGlobalStyle } from 'styled-components';
import { Modal } from '@podiumhq/podium-ui';
import { AddContactDiv, ContactWrapper, ContactBox, contentStyles } from './styles';
import * as icon from '../../../images/icon';


class ContactModal extends Component {
  componentWillMount() {
    return createGlobalStyle`
    .ReactModal__Content.ReactModal__Content--after-open.podium-ui-modal-content {
      ${contentStyles}
    }`;
  }

/* eslint-disable prefer-template */
setChosenContact = async (contact) => {
  console.log('this is the contact', contact);
  const { prospectId } = this.props;
  const currentProspect = this.props.prospectingData.find(prospect => prospect.id === prospectId);
  const addHunterContactHeading = (desc) =>
    desc.includes('Hunter Contact(s)') ? '' : 'Hunter Contact(s):';
  
  if(
    currentProspect.first_name === '' ||
    currentProspect.last_name === '' ||
    currentProspect.email === ''
    ) {
      this.props.onTextFieldUpdate(prospectId, 'first_name', contact.first_name || '');
      await this.sleep(100);
      this.props.onTextFieldUpdate(prospectId, 'last_name', contact.last_name || '');
      await this.sleep(100);
      this.props.onTextFieldUpdate(prospectId, 'title', contact.position || '');
      await this.sleep(100);
      this.props.onTextFieldUpdate(prospectId, 'email', contact.email || '');
      
      this.props.toggleContactModal();
  } else {
    this.props.onTextFieldUpdate(
      prospectId,
      'description',
      `${currentProspect.description}\n${addHunterContactHeading(
        currentProspect.description
        )}\n${contact.first_name ? (contact.first_name + ' ') : ''}${contact.last_name ? (contact.last_name + '| ') : ''}${contact.position ? (contact.position + '| ') : ''}${(contact.email + ' ') ? contact.email : ''}
      `)
  }
}

  sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

renderContactRows = (contact) => {
  const name = contact.first_name && contact.last_name ?
    `${contact.first_name} ${contact.last_name}` :
    null;
  return (
    <ContactBox>
      {name ? `${name} - ` : ''}{contact.position ? `${contact.position} - ` : ''}{contact.email}
      <AddContactDiv>
        {/* eslint-disable-next-line */}
        <img src={icon.addContact} className="addContact" alt="add contact" onClick={() => this.setChosenContact(contact)} />
      </AddContactDiv>
    </ContactBox>
  );
}

render() {
  const {
    contacts,
    isShowing,
    toggleContactModal
  } = this.props;

  return (
    <div>
      <Modal
        className="modal"
        isOpen={isShowing}
        onRequestClose={() => toggleContactModal()}
      >
        <ContactWrapper>
          <h4>{contacts ? contacts.length : '0'} contacts found for this prospect</h4>
          <p>
            Click on the + sign to auto-populate the form with the contact info.
            <br />
            Additional contacts can also be added to the description field by clicking the + sign.
          </p>
          {_.map(contacts, contact => this.renderContactRows(contact))}
        </ContactWrapper>
      </Modal>
    </div>
  );
}
}

export default ContactModal;
