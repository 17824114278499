import React from 'react';
import Select from 'react-select';
import { dropdownStyles, InputDiv, SubmitUserButton, UserInput } from '../styles';
import { Section, SectionContents, SectionTitle } from '../../Packaged/styles';

const AddNewUser = ({
  email,
  firstName,
  isAdminOptions,
  isAdminSelected,
  lastName,
  roleOptions,
  roleSelected,
  uploadedNewUser,
  handleFirstNameChange,
  handleLastNameChange,
  handleEmailChange,
  handleRoleChange,
  handleIsAdminChange,
  handleSubmitNewUser,
  handleSubmitAnotherUser
}) => {

  return (
    <Section>
      <SectionTitle>
        <div>USERS</div>
        <div>Add a new user to the tool</div>
      </SectionTitle>
      <SectionContents>
        <InputDiv>
          {!uploadedNewUser && (
            <React.Fragment>
              <UserInput
                placeholder="First Name"
                type="text"
                className={firstName}
                name="firstName"
                // value={firstName}
                onChange={e => handleFirstNameChange(e.target.value)}
              />
              <UserInput
                placeholder="Last Name"
                type="text"
                className={lastName}
                // value={lastName}
                onChange={e => handleLastNameChange(e.target.value)}
              />
              <UserInput
                placeholder="Email Address"
                type="email"
                className={email}
                // value={email}
                onChange={e => handleEmailChange(e.target.value)}
              />
              <p>Role</p>
              <Select
                isSearchable
                options={roleOptions}
                onChange={e => handleRoleChange(e)}
                value={roleSelected}
                className={roleSelected}
                styles={dropdownStyles}
              />
              <p>Is admin?</p>
              <Select
                isSearchable
                options={isAdminOptions}
                onChange={e => handleIsAdminChange(e)}
                value={isAdminSelected}
                className={isAdminSelected}
                styles={dropdownStyles}
              />
              <SubmitUserButton onClick={handleSubmitNewUser}>
                Add User
              </SubmitUserButton>
            </React.Fragment>
          )}
          {uploadedNewUser && (
            <div className="addAnotherUser">
              <h4>User successfully uploaded!</h4>
              <SubmitUserButton onClick={handleSubmitAnotherUser}>
                Add Another?
              </SubmitUserButton>
            </div>
          )}
        </InputDiv>
      </SectionContents>
    </Section>
  )
}

export default AddNewUser;
