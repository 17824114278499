import styled from 'styled-components';

export const dropdownStyles = {
  option: provided => ({
    ...provided,
    padding: 15
  }),
  placeholder: styles => ({ ...styles, color: "black", textAlign: "center" }),
  control: styles => ({
    ...styles,
    // none of react-select's styles are passed to <Control />
    borderStyle: "solid",
    borderColor: "#E4E9F0",
    borderWidth: 2,
    color: "black",
    cursor: "pointer",
    width: 200,
    height: 40
  }),
  indicatorSeparator: () => null,
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity };
  }
};

export const AboveTableDiv = styled.div`
  float: right;

  .select {
    padding: 10px 0;
  }
`;