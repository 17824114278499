export const columns = [
  {
    Header: 'Prospect ID',
    accessor: 'id',
    foldable: true
  },
  {
    Header: 'Company Name',
    accessor: 'company_name',
    foldable: true,
    minWidth: 200
  },
  {
    Header: 'Revenue',
    accessor: 'zoominfo_revenue',
    foldable: true,
    minWidth: 150
  },
  {
    Header: 'Employees',
    accessor: 'zoominfo_employee_count',
    foldable: true
  },
  {
    Header: 'Street',
    accessor: 'street_address',
    foldable: true,
    minWidth: 150
  },
  {
    Header: 'City',
    accessor: 'city',
    foldable: true,
    minWidth: 150
  },
  {
    Header: 'State',
    accessor: 'state',
    foldable: true
  },
  {
    Header: 'Zip Code',
    accessor: 'postal_code',
    foldable: true
  },
  {
    Header: 'Website',
    accessor: 'website',
    foldable: true,
    minWidth: 300
  }
];

export const userColumns = [
  {
    Header: 'First Name',
    accessor: 'first_name'
  },
  {
    Header: 'Last Name',
    accessor: 'last_name'
  },
  {
    Header: 'Email',
    accessor: 'email',
    minWidth: 250
  },
  {
    Header: 'Role',
    accessor: 'role'
  },
  {
    Header: 'Is Admin?',
    accessor: 'is_admin'
  }
];

export const isAdminOpts = [
  { value: true, label: 'True' },
  { value: false, label: 'False' }
];

export const roleDropdownOpts = [
  { value: 'ADM', label: 'ADM' },
  { value: 'AE', label: 'AE' },
  { value: 'Engineer', label: 'Engineer' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Manager', label: 'Manager' }
];

export const verticalOptions = [
  {
    value: 'auto',
    label: 'Auto'
  },
  {
    value: 'autoServices',
    label: 'Auto Services'
  },
  {
    value: 'homeServices',
    label: 'Home Services'
  },
  {
    value: 'healthcare',
    label: 'Healthcare'
  },
  {
    value: 'professionalServices',
    label: 'Professional Services'
  },
  {
    value: 'retail',
    label: 'Retail'
  },
  {
    value: 'hospitality',
    label: 'Hospitality'
  }
];

export const subverticalOptions = {
  auto: [
    {
      value: 'Powersports Dealer',
      label: 'Powersports Dealer'
    },
    {
      value: 'RV Dealer',
      label: 'RV Dealer'
    },
    {
      value: 'Used Car Dealer',
      label: 'Used Car Dealer'
    }
    // {
    //   value: 'Marine Dealer',
    //   label: 'Marine Dealer'
    // },
    // {
    //   value: 'New Car Dealer',
    //   label: 'New Car Dealer'
    // },
    // {
    //   value: 'OEM/New Car Dealer',
    //   label: 'OEM/New Car Dealer'
    // },
  ],
  autoServices: [
    {
      value: 'Collision Center',
      label: 'Collision Center'
    },
    {
      value: 'Tire Shop',
      label: 'Tire Shop'
    }
    // {
    //   value: 'Auto Repair',
    //   label: 'Auto Repair'
    // },
    // {
    //   value: 'Auto Services - Other',
    //   label: 'Auto Services - Other'
    // },
    // {
    //   value: 'Car Wash',
    //   label: 'Car Wash'
    // },
    // {
    //   value: 'Glass',
    //   label: 'Glass'
    // },
    // {
    //   value: 'Oil/Filter',
    //   label: 'Oil/Filter'
    // },
    // {
    //   value: 'Transmission Shop',
    //   label: 'Transmission Shop'
    // }
  ],
  professionalServices: [
    {
      label: 'Insurance Brokerage',
      value: 'Insurance Brokerage'
    },
    {
      label: 'Law Firm',
      value: 'Law Firm'
    },
    {
      label: 'Mortgage Brokerage',
      value: 'Mortgage Brokerage'
    }
    // {
    //   label: 'Cannabis Dispensary',
    //   value: 'Cannabis Dispensary'
    // },
    // {
    //   label: 'Diversified - Other',
    //   value: 'Diversified - Other'
    // },
    // {
    //   label: 'Education',
    //   value: 'Education'
    // },
    // {
    //   label: 'Events/Catering',
    //   value: 'Events/Catering'
    // },
    // {
    //   label: 'Financial Institution',
    //   value: 'Financial Institution'
    // },
    // {
    //   label: 'Hospitality/Travel',
    //   value: 'Hospitality/Travel'
    // },
    // {
    //   label: 'Mortuary',
    //   value: 'Mortuary'
    // },
    // {
    //   label: 'Online Domain',
    //   value: 'Online Domain'
    // },
    // {
    //   label: 'Property Management',
    //   value: 'Property Management'
    // },
    // {
    //   label: 'Real Estate',
    //   value: 'Real Estate'
    // },
    // {
    //   label: 'Tax Firm',
    //   value: 'Tax Firm'
    // },
    // {
    //   label: 'Tax Firms',
    //   value: 'Tax Firms'
    // }
  ],
  homeServices: [
    {
      label: 'Exteriors',
      value: 'Exteriors'
    },
    {
      label: 'Flooring',
      value: 'Flooring'
    },
    {
      label: 'HVAC/Plumbing',
      value: 'HVAC/Plumbing'
    },
    {
      label: 'Interiors',
      value: 'Interiors'
    },
    {
      label: 'Moving',
      value: 'Moving'
    },
    {
      label: 'Pest Control',
      value: 'Pest Control'
    },
    {
      label: 'Pools',
      value: 'Pools'
    },
    {
      label: 'Roofing',
      value: 'Roofing'
    },
    {
      label: 'Security',
      value: 'Security'
    },
    {
      label: 'Self Storage',
      value: 'Self Storage'
    },
    {
      label: 'Solar',
      value: 'Solar'
    }
    // {
    //   label: 'Electrical',
    //   value: 'Electrical'
    // },
    // {
    //   label: 'Floor Cleaning',
    //   value: 'Floor Cleaning'
    // },
    // {
    //   label: 'Lawn Care',
    //   value: 'Lawn Care'
    // },
    // {
    //   label: 'Locksmith',
    //   value: 'Locksmith'
    // },
    // {
    //   label: 'Maid Service',
    //   value: 'Maid Service'
    // },
    // {
    //   label: 'Pet Care',
    //   value: 'Pet Care'
    // },
    // {
    //   label: 'Windows/Doors',
    //   value: 'Windows/Doors'
    // },
    // {
    //   label: 'Wireless Internet Provider',
    //   value: 'Wireless Internet Provider'
    // }
  ],
  healthcare: [
    {
      label: 'Dental',
      value: 'Dental'
    },
    {
      label: 'Dermatology',
      value: 'Dermatology'
    },
    {
      label: 'Family Practice',
      value: 'Family Practice'
    },
    {
      label: 'Med Spas',
      value: 'Med Spas'
    },
    {
      label: 'Ophthalmology',
      value: 'Ophthalmology'
    },
    {
      label: 'Orthodontics',
      value: 'Orthodontics'
    },
    {
      label: 'Pain Center',
      value: 'Pain Center'
    },
    {
      label: 'Urgent Care',
      value: 'Urgent Care'
    }
    // {
    //   label: 'Addiction Recovery',
    //   value: 'Addiction Recovery'
    // },
    // {
    //   label: 'Assisted Living/Home Health/Hospice',
    //   value: 'Assisted Living/Home Health/Hospice'
    // },
    // {
    //   label: 'Chiropractic',
    //   value: 'Chiropractic'
    // },
    // {
    //   label: 'Dermatologist',
    //   value: 'Dermatologist'
    // },
    // {
    //   label: 'Fertility Center',
    //   value: 'Fertility Center'
    // },
    // {
    //   label: 'Hair Removal',
    //   value: 'Hair Removal'
    // },
    // {
    //   label: 'Hair Restoration',
    //   value: 'Hair Restoration'
    // },
    // {
    //   label: 'Hospital',
    //   value: 'Hospital'
    // },
    // {
    //   label: 'Large Spa/Salon',
    //   value: 'Large Spa/Salon'
    // },
    // {
    //   label: 'LASIK',
    //   value: 'LASIK'
    // },
    // {
    //   label: 'Medical - Other',
    //   value: 'Medical - Other'
    // },
    // {
    //   label: 'Non-Corporate Fitness Center',
    //   value: 'Non-Corporate Fitness Center'
    // },
    // {
    //   label: 'Non-Corporate Spa/Salon',
    //   value: 'Non-Corporate Spa/Salon'
    // },
    // {
    //   label: 'Ophthalmology',
    //   value: 'Ophthalmology'
    // },
    // {
    //   label: 'Orthopedic Surgery',
    //   value: 'Orthopedic Surgery'
    // },
    // {
    //   label: 'Pediatrics',
    //   value: 'Pediatrics'
    // },
    // {
    //   label: 'Plastic Surgery',
    //   value: 'Plastic Surgery'
    // },
    // {
    //   label: 'Radiology',
    //   value: 'Radiology'
    // },
    // {
    //   label: 'Veterinarian',
    //   value: 'Veterinarian'
    // },
    // {
    //   label: 'Vision',
    //   value: 'Vision'
    // },
    // {
    //   label: 'Women\'s Health',
    //   value: 'Women\'s Health'
    // }
  ],
  retail: [
    {
      label: 'Appliance',
      value: 'Appliance'
    },
    {
      label: 'Bridal Shop',
      value: 'Bridal Shop'
    },
    {
      label: 'Furniture',
      value: 'Furniture'
    },
    {
      label: 'Jewelry',
      value: 'Jewelry'
    },
    {
      label: 'Mattress',
      value: 'Mattress'
    },
    {
      label: 'Pawn Shop',
      value: 'Pawn Shop'
    }
    // {
    //   label: 'General Retail - Focused on Commercial Businesses',
    //   value: 'General Retail - Focused on Commercial Businesses'
    // },
    // {
    //   label: 'General Retail - Low Volume',
    //   value: 'General Retail - Low Volume'
    // },
    // {
    //   label: 'General Retail - Other',
    //   value: 'General Retail - Other'
    // },
    // {
    //   label: 'General Retail - Owner Operator',
    //   value: 'General Retail - Owner Operator'
    // },
    // {
    //   label: 'Legal',
    //   value: 'Legal'
    // },
  ],
  hospitality: [
    {
      label: 'Entertainment',
      value: 'Entertainment'
    },
    {
      label: 'Lodging',
      value: 'Lodging'
    }
  ]
};

/* eslint-disable quote-props */
export const strikezoneOptions = {
  'Collision Center': [
    {
      value: 'Collision Center',
      label: 'Collision Center'
    }
  ],
  'Tire Shop': [
    {
      value: 'Tire Shop',
      label: 'Tire Shop'
    }
  ],
  Exteriors: [
    {
      label: 'Doors',
      value: 'Doors'
    },
    {
      label: 'Fence',
      value: 'Fence'
    },
    {
      label: 'Windows',
      value: 'Windows'
    }
  ],
  Flooring: [
    {
      label: 'Restoration',
      value: 'Restoration'
    }
  ],
  'HVAC/Plumbing': [
    {
      label: 'HVAC',
      value: 'HVAC'
    },
    {
      label: 'Electrical',
      value: 'Electrical'
    },
    {
      label: 'Plumbing',
      value: 'Plumbing'
    }
  ],
  Interiors: [
    {
      label: 'Blinds',
      value: 'Blinds'
    }
  ],
  Moving: [
    {
      label: 'Moving',
      value: 'Moving'
    }
  ],
  'Pest Control': [
    {
      label: 'Pest Control',
      value: 'Pest Control'
    }
  ],
  Pools: [
    {
      label: 'Pools',
      value: 'Pools'
    }
  ],
  Roofing: [
    {
      label: 'Roofing',
      value: 'Roofing'
    }
  ],
  Security: [
    {
      label: 'Security',
      value: 'Security'
    }
  ],
  'Self Storage': [
    {
      label: 'Self Storage',
      value: 'Self Storage'
    }
  ],
  Solar: [
    {
      label: 'Solar',
      value: 'Solar'
    }
  ],
  Appliance: [
    {
      label: 'Appliance Store',
      value: 'Appliance Store'
    }
  ],
  'Bridal Shop': [
    {
      label: 'Bridal Shop',
      value: 'Bridal Shop'
    }
  ],
  Furniture: [
    {
      label: 'Furniture',
      value: 'Furniture'
    }
  ],
  Jewelry: [
    {
      label: 'Engagement Rings',
      value: 'Engagement Rings'
    },
    {
      label: 'Fine Jewelry',
      value: 'Fine Jewelry'
    }
  ],
  Mattress: [
    {
      label: 'Mattress',
      value: 'Mattress'
    }
  ],
  'Pawn Shop': [
    {
      label: 'Pawn Shop',
      value: 'Pawn Shop'
    }
  ],
  Entertainment: [
    {
      label: 'Adventures',
      value: 'Adventures'
    },
    {
      label: 'Tours',
      value: 'Tours'
    }
  ],
  Lodging: [
    {
      label: 'Lodging',
      value: 'Lodging'
    }
  ],
  Dental: [
    {
      label: 'Dentists',
      value: 'Dentists'
    }
  ],
  Dermatology: [
    {
      label: 'Dermatologists',
      value: 'Dermatologists'
    }
  ],
  'Med Spas': [
    {
      label: 'Med Spas',
      value: 'Med Spas'
    }
  ],
  'Family Practice': [
    {
      label: 'Family Practice',
      value: 'Family Practice'
    }
  ],
  Ophthalmology: [
    {
      label: 'Ophthalmology',
      value: 'Ophthalmology'
    }
  ],
  Orthodontics: [
    {
      label: 'Orthodontists',
      value: 'Orthodontists'
    }
  ],
  'Pain Center': [
    {
      label: 'Pain Management',
      value: 'Pain Management'
    }
  ],
  'Urgent Care': [
    {
      label: 'Urgent Care',
      value: 'Urgent Care'
    }
  ],
  'Powersports Dealer': [
    {
      label: 'Powersports Dealer',
      value: 'Powersports Dealer'
    }
  ],
  'RV Dealer': [
    {
      label: 'RV Dealer',
      value: 'RV Dealer'
    }
  ],
  'Used Car Dealer': [
    {
      label: 'Used Car Dealers',
      value: 'Used Car Dealers'
    }
  ],
  'Insurance Brokerage': [
    {
      label: 'Insurance Agents',
      value: 'Insurance Agents'
    }
  ],
  'Law Firm': [
    {
      label: 'Family Law',
      value: 'Family Law'
    },
    {
      label: 'Immigration Law',
      value: 'Immigration Law'
    },
    {
      label: 'Personal Injury Law',
      value: 'Personal Injury Law'
    }
  ],
  'Mortgage Brokerage': [
    {
      label: 'Mortgage Brokers',
      value: 'Mortgage Brokers'
    }
  ]
};

export const statesOptions = [
  {
    label: 'Alabama',
    value: 'Alabama'
  },
  {
    label: 'Alaska',
    value: 'Alaska'
  },
  {
    label: 'Arizona',
    value: 'Arizona'
  },
  {
    label: 'Arkansas',
    value: 'Arkansas'
  },
  {
    label: 'California',
    value: 'California'
  },
  {
    label: 'Colorado',
    value: 'Colorado'
  },
  {
    label: 'Connecticut',
    value: 'Connecticut'
  },
  {
    label: 'Delaware',
    value: 'Delaware'
  },
  {
    label: 'District of Columbia',
    value: 'District of Columbia'
  },
  {
    label: 'Florida',
    value: 'Florida'
  },
  {
    label: 'Georgia',
    value: 'Georgia'
  },
  {
    label: 'Hawaii',
    value: 'Hawaii'
  },
  {
    label: 'Idaho',
    value: 'Idaho'
  },
  {
    label: 'Illinois',
    value: 'Illinois'
  },
  {
    label: 'Indiana',
    value: 'Indiana'
  },
  {
    label: 'Iowa',
    value: 'Iowa'
  },
  {
    label: 'Kansas',
    value: 'Kansas'
  },
  {
    label: 'Kentucky',
    value: 'Kentucky'
  },
  {
    label: 'Louisiana',
    value: 'Louisiana'
  },
  {
    label: 'Maine',
    value: 'Maine'
  },
  {
    label: 'Maryland',
    value: 'Maryland'
  },
  {
    label: 'Massachusetts',
    value: 'Massachusetts'
  },
  {
    label: 'Michigan',
    value: 'Michigan'
  },
  {
    label: 'Minnesota',
    value: 'Minnesota'
  },
  {
    label: 'Mississippi',
    value: 'Mississippi'
  },
  {
    label: 'Missouri',
    value: 'Missouri'
  },
  {
    label: 'Montana',
    value: 'Montana'
  },
  {
    label: 'Nebraska',
    value: 'Nebraska'
  },
  {
    label: 'Nevada',
    value: 'Nevada'
  },
  {
    label: 'New Hampshire',
    value: 'New Hampshire'
  },
  {
    label: 'New Jersey',
    value: 'New Jersey'
  },
  {
    label: 'New Mexico',
    value: 'New Mexico'
  },
  {
    label: 'New York',
    value: 'New York'
  },
  {
    label: 'North Carolina',
    value: 'North Carolina'
  },
  {
    label: 'North Dakota',
    value: 'North Dakota'
  },
  {
    label: 'Ohio',
    value: 'Ohio'
  },
  {
    label: 'Oklahoma',
    value: 'Oklahoma'
  },
  {
    label: 'Oregon',
    value: 'Oregon'
  },
  {
    label: 'Pennsylvania',
    value: 'Pennsylvania'
  },
  {
    label: 'Rhode Island',
    value: 'Rhode Island'
  },
  {
    label: 'South Carolina',
    value: 'South Carolina'
  },
  {
    label: 'South Dakota',
    value: 'South Dakota'
  },
  {
    label: 'Tennessee',
    value: 'Tennessee'
  },
  {
    label: 'Texas',
    value: 'Texas'
  },
  {
    label: 'Utah',
    value: 'Utah'
  },
  {
    label: 'Vermont',
    value: 'Vermont'
  },
  {
    label: 'Virginia',
    value: 'Virginia'
  },
  {
    label: 'Washington',
    value: 'Washington'
  },
  {
    label: 'West Virginia',
    value: 'West Virginia'
  },
  {
    label: 'Wisconsin',
    value: 'Wisconsin'
  },
  {
    label: 'Wyoming',
    value: 'Wyoming'
  },
  {
    label: 'Ontario',
    value: 'Ontario'
  },
  {
    label: 'Quebec',
    value: 'Quebec'
  },
  {
    label: 'British Columbia',
    value: 'British Columbia'
  },
  {
    label: 'Alberta',
    value: 'Alberta'
  },
  {
    label: 'Nova Scotia',
    value: 'Nova Scotia'
  },
  {
    label: 'Manitoba',
    value: 'Manitoba'
  },
  {
    label: 'Saskatchewan',
    value: 'Saskatchewan'
  },
  {
    label: 'New Brunswick',
    value: 'New Brunswick'
  },
  {
    label: 'Newfoundland and Labrador',
    value: 'Newfoundland and Labrador'
  },
  {
    label: 'Prince Edward Island',
    value: 'Prince Edward Island'
  }
];

export const ToolTips = {
  falsePositiveCompanyNames:
    "If a company's name contains any of these words, it will be automatically filtered out. You should only add names to this that you absolutely want to avoid no matter what. Example: Walmart in Retail. Jiffy Lube in Auto Services. Allstate in Insurance Agents.",
  searchStrings:
    "These are the search terms the prospector uses on each postal code in the state that is being prospected out of. Example: home insurance agent near 84065. home insurance agent near 84094. The 'near 84065' piece is automatically added on by the prospector so there is no need for you to add that.",
  requiredPlaceTypes:
    "Google place types are assigned by Google to help identify a business. The required types are the ones that MUST show up in order for a business to be what you're looking for. Example: car_dealer is a required type for Used Car Dealers. jewelry_store is a required place type for Jewelry.",
  optionalPlaceTypes:
    'Optional types are Google place types that will show up in addition to the required place types. For example: jewerly_store is the required type for Jewelry but store, establishment, point_of_interest, finance and others will still show up as Google place types for jewelers.'
};
