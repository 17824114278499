import styled from 'styled-components';
import { Button } from '@podiumhq/podium-ui';

export const SalesforceDuplicatesContainer = styled.div`
  width: 90%
  margin: 0 auto;

  h1, h3, p {
    text-align: center;
    margin: 0;
  }

  h1 {
    padding-top: 34px;
  }

  h3 {
    padding-top: 16px;
    font-size: 20px;
    font-weight: 500;
    color: #878787;
  }

  p {
    padding-top: 8px;
    font-size: 16px;
    color: #878787;
    padding-bottom: 34px;
  }
`;

export const Duplicate = styled.div`
  padding-top: 25px;
  position: relative;

  > span {
    font-size: 16px;
  }

  > span:first-child {
    font-weight: 600;
    margin-right: 6px;
  }

  button {
    position: absolute;
    right: 0;
  }

  padding-bottom: ${props => (props.last ? '24px' : '0')};
`;

export const ScoreAnywayButton = styled(Button)`
  display: block;
  margin: 24px auto 0;
`;
